import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import moment from "moment";
import DataTable from "examples/Tables/DataTable";
import BootstrapSelect from "components/BootstrapSelect";
import MDSnackbar from "components/MDSnackbar";

import Services from "../../services";

function Dashboard() {
  const navigate = useNavigate();

  const [dashboardData, setDashboardData] = useState({});
  const [appUserStats, setAppUserStats] = useState({});
  const [fromDate, setFromDate] = useState(moment("6 november 2023").format("L"));
  const [toDate, setToDate] = useState(moment().format("L"));
  const [isLoading, setIsLoading] = useState(false);
  const [itemsList, setItemsList] = useState([]);
  const [search, setSearch] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [cronData, setCronData] = useState({});
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [isSuccess, setIsSuccess] = useState(true);
  const [successMessage, setSuccessMessage] = useState(true);
  const [isFailure, setIsFailure] = useState(false);
  const [failureMessage, setFailureMessage] = useState(false);
  const [isCalculatingAppUsage, setIsCalculatingAppUsage] = useState(true);

  const [appUsersColumn] = useState([
    { Header: "New Users", accessor: "newUsersCount", align: "center" },
    { Header: "Ios Users", accessor: "iosUsersCount", align: "center" },
    { Header: "Android Users", accessor: "androidUsersCount", align: "center" },
  ]);

  const [appUsageColumn] = useState([
    { Header: "Active Users", accessor: "activeUsers", width: "5%", align: "center" },
    { Header: "Usage", accessor: "usage", width: "5%", align: "center" },
    { Header: "Sessions", accessor: "sessions", width: "5%", align: "center" },
    {
      Header: "Average time per Session",
      accessor: "averageSessions",
      width: "5%",
      align: "center",
    },
  ]);

  const validateToken = () => {
    if (!localStorage.getItem("token")) {
      navigate("/authentication/sign-in", { replace: true });
    }
  };

  const getAllUserEmailAddresses = async () => {
    const { data } = await Services.getAllUserEmailAddresses({ fromDate, toDate });

    const mappedWanderers = data.map((item) => {
      return { _id: item._id, option: item.email };
    });

    setItemsList(mappedWanderers);
  };

  useEffect(() => {
    validateToken();
  });

  const getCalculationStatus = (startTime, endTime) => {
    if (!startTime || !endTime) {
      if (!endTime) {
        return "in progress";
      }

      return "calculating...";
    } else {
      return "completed";
    }
  };

  const getAppUsageCronDetails = async () => {
    try {
      const {
        data: { data },
      } = await Services.getAppUsageCronDetails({ cronName: "AUTO_CALCULATE_APP_USAGE_STATS" });

      const {
        lastRunAt,
        duration: { startTime, endTime },
      } = data;

      if (startTime && endTime) {
        setIsCalculatingAppUsage(false);
      } else if (startTime && !endTime) {
        setIsCalculatingAppUsage(true);
      }

      const differenceInSeconds = moment(endTime).diff(startTime, "seconds");

      const duration = moment.duration(differenceInSeconds, "seconds");

      setCronData({
        lastRunAt: moment(lastRunAt).format("LLL"),
        startTime: moment(startTime).format("LLL"),
        endTime: endTime ? moment(endTime).format("LLL") : "-",
        duration: `${
          endTime
            ? `${
                differenceInSeconds > 60
                  ? duration.asMinutes().toFixed(1)
                  : duration.asSeconds().toFixed(1)
              } ${differenceInSeconds > 60 ? "minutes" : "seconds"} `
            : "-"
        } `,
        calculationStatus: getCalculationStatus(startTime, endTime),
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getAppUserStats();
    getAppUsageStats();
    getAllUserEmailAddresses();
    getAppUsageCronDetails();
  }, []);

  useEffect(() => {
    if (cronData.endTime === "-") {
      setTimeout(() => {
        getAppUsageCronDetails();
      }, 5000);
    }
  }, [cronData]);

  const getAppUserStats = async () => {
    try {
      setIsLoading(true);

      const body = {};

      if (fromDate) {
        body.fromDate = fromDate;
      }

      if (toDate) {
        body.toDate = toDate;
      }

      let {
        data: { data },
      } = await Services.getAppUserStats(body);

      setAppUserStats(data);
    } catch (err) {
      console.log("Error occured while getting app user stats:", err);
    } finally {
      setIsLoading(false);
    }
  };

  const getAppUsageStats = async () => {
    try {
      setIsLoading(true);

      const body = {
        selectedUsers,
      };

      if (fromDate) {
        body.fromDate = fromDate;
      }

      if (toDate) {
        body.toDate = toDate;
      }

      let { data } = await Services.getAppUsageStats(body);

      data.usage = secondsToString(data.usage);
      data.averageSessions = secondsToString(parseInt(data.averageSessions));

      setDashboardData(data);

      console.log("🚀 ~ file: index.js:37 ~ get ~ res:", data);
    } catch (err) {
      console.log("Error occured while getting app usage stats:", err);
    } finally {
      setIsLoading(false);
    }
  };

  function secondsToString(seconds) {
    if (!seconds) {
      return seconds;
    }

    var numyears = Math.floor(seconds / 31536000);
    var numdays = Math.floor((seconds % 31536000) / 86400);
    var numhours = Math.floor(((seconds % 31536000) % 86400) / 3600);
    var numminutes = Math.floor((((seconds % 31536000) % 86400) % 3600) / 60);
    var numseconds = (((seconds % 31536000) % 86400) % 3600) % 60;

    let result = "";

    if (numyears) {
      result += `${numyears} years`;
    }

    if (numdays) {
      result += ` ${numdays} days`;
    }

    if (numhours) {
      result += ` ${numhours} hours`;
    }

    if (numminutes) {
      result += ` ${numminutes} minutes`;
    }

    if (numseconds) {
      result += ` ${numseconds} seconds`;
    }

    return result;
  }

  const calculateAppUsageStats = async () => {
    try {
      setIsCalculatingAppUsage(true);

      const {
        data: { message },
      } = await Services.calculateAppUsageStats();

      setIsFailure(false);
      setIsSuccess(true);
      setSuccessMessage(message);

      getAppUsageStats();
      getAppUsageCronDetails();
    } catch (err) {
      setIsSuccess(false);
      setIsFailure(true);
      setFailureMessage(err.message);
      setIsCalculatingAppUsage(false);

      console.log(err);
    } finally {
      setIsLoading(false);
      setIsShowPopup(true);
    }
  };

  const handleApplyFiltersOnUserStats = () => {
    getAppUserStats();
  };

  const handleApplyFiltersOnUsageStats = () => {
    getAppUsageStats();
    getAllUserEmailAddresses();
  };

  const handleClearAll = () => {
    setFromDate("");
    setToDate("");
  };

  const getNotificationColor = () => {
    let message = "";

    if (isSuccess) {
      message = "success";
    } else if (isFailure) {
      message = "error";
    }

    return message;
  };

  const getNotificationTitle = () => {
    let message = "";

    if (isSuccess) {
      message = "Success";
    } else if (isFailure) {
      message = "Error";
    }

    return message;
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDSnackbar
        color={getNotificationColor()}
        icon="check"
        title={getNotificationTitle()}
        content={isSuccess ? successMessage : failureMessage}
        dateTime="now"
        open={isShowPopup}
        onClose={() => setIsShowPopup(false)}
        close={() => setIsShowPopup(false)}
        bgWhite
      />
      <div style={{ marginBottom: 10 }}>
        <Accordion>
          <Accordion.Item style={{ borderRadius: 15 }} eventKey="wl-accordion">
            <Accordion.Header>App Users Stats</Accordion.Header>
            <Accordion.Body>
              <Grid container spacing={1} marginBottom={2}>
                <Grid item xs={12}>
                  <MDTypography fontWeight="regular" variant="h6" color="dark">
                    Date Range
                  </MDTypography>
                </Grid>
                <Grid item xs={2}>
                  <MDInput
                    onChange={(e) => {
                      setFromDate(e.target.value);
                    }}
                    value={fromDate}
                    label="From"
                    onFocus={(e) => (e.target.type = "date")}
                    onBlur={(e) => (e.target.type = "text")}
                  />
                </Grid>

                <Grid item xs={2}>
                  <MDInput
                    onChange={(e) => {
                      setToDate(e.target.value);
                    }}
                    value={toDate}
                    label="To"
                    onFocus={(e) => (e.target.type = "date")}
                    onBlur={(e) => (e.target.type = "text")}
                  />
                </Grid>
                <Grid item xs={8}></Grid>
                <Grid item xs={5}>
                  <MDTypography variant="button" fontWeight="light" color="text">
                    Note: Data recording started from 6th november 2023.
                  </MDTypography>
                </Grid>
              </Grid>

              <Grid container spacing={1}>
                <Grid className="ms-auto" item xs={2}>
                  <MDButton onClick={handleClearAll} variant="outlined" color="secondary" fullWidth>
                    Clear All
                  </MDButton>
                </Grid>
                <Grid item xs={2}>
                  <MDButton
                    onClick={handleApplyFiltersOnUserStats}
                    variant="gradient"
                    color="info"
                    fullWidth
                  >
                    Apply
                  </MDButton>
                </Grid>
              </Grid>
              <MDBox pt={6} pb={2}>
                <Grid container spacing={6}>
                  <Grid item xs={12}>
                    <Card>
                      <MDBox pt={3}>
                        <DataTable
                          table={{
                            columns: appUsersColumn,
                            rows: Object.values(appUserStats) ? [appUserStats] : null,
                          }}
                          isSorted={false}
                          entriesPerPage={false}
                          showTotalEntries={false}
                          noEndBorder
                        />
                      </MDBox>
                    </Card>
                  </Grid>
                </Grid>
              </MDBox>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>

      <div style={{ marginBottom: 10 }}>
        <Accordion>
          <Accordion.Item style={{ borderRadius: 15 }} eventKey="wl-accordion">
            <Accordion.Header>App Usage Stats</Accordion.Header>
            <Accordion.Body>
              <Grid container spacing={1} marginBottom={2}>
                <Grid item xs={12}>
                  <MDTypography fontWeight="regular" variant="h6" color="dark">
                    Date Range
                  </MDTypography>
                </Grid>
                <Grid item xs={2}>
                  <MDInput
                    onChange={(e) => {
                      setFromDate(e.target.value);
                    }}
                    value={fromDate}
                    label="From"
                    onFocus={(e) => (e.target.type = "date")}
                    onBlur={(e) => (e.target.type = "text")}
                  />
                </Grid>

                <Grid item xs={2}>
                  <MDInput
                    onChange={(e) => {
                      setToDate(e.target.value);
                    }}
                    value={toDate}
                    label="To"
                    onFocus={(e) => (e.target.type = "date")}
                    onBlur={(e) => (e.target.type = "text")}
                  />
                </Grid>
                <Grid item xs={8}></Grid>
                <Grid item xs={5}>
                  <MDTypography variant="button" fontWeight="light" color="text">
                    Note: Data recording started from 6th november 2023.
                  </MDTypography>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <MDTypography fontWeight="regular" variant="h6" color="dark">
                    Users
                  </MDTypography>
                </Grid>
                <Grid item xs={4}>
                  <BootstrapSelect
                    item="users"
                    itemsList={itemsList}
                    onReturn={(selectedItem) => {
                      setSelectedUsers(selectedItem.map((item) => item.value));
                    }}
                    isMultiInput={true}
                    isSearchable={true}
                    style={{ fontSize: 15 }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid className="ms-auto" item xs={2}>
                  <MDButton onClick={handleClearAll} variant="outlined" color="secondary" fullWidth>
                    Clear All
                  </MDButton>
                </Grid>
                <Grid item xs={2}>
                  <MDButton
                    onClick={handleApplyFiltersOnUsageStats}
                    variant="gradient"
                    color="info"
                    fullWidth
                  >
                    Apply
                  </MDButton>
                </Grid>
              </Grid>
              <MDBox pt={6} pb={2}>
                <Grid container spacing={6}>
                  <Grid item xs={12}>
                    <Card>
                      <MDBox
                        mx={2}
                        mt={-3}
                        py={3}
                        px={2}
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <MDTypography variant="h6" color="white">
                          App Usage Statistics
                          {isLoading ? (
                            <span
                              style={{ marginLeft: 5 }}
                              className="spinner-border spinner-border-sm ml-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : null}
                        </MDTypography>
                      </MDBox>
                      <MDBox pt={3}>
                        <DataTable
                          table={{
                            columns: appUsageColumn,
                            rows: Object.values(dashboardData) ? [dashboardData] : null,
                          }}
                          isSorted={false}
                          entriesPerPage={false}
                          showTotalEntries={false}
                          noEndBorder
                        />
                      </MDBox>
                    </Card>
                  </Grid>
                </Grid>
              </MDBox>

              <Card className="mb-2">
                <Grid
                  style={{ paddingLeft: 15, paddingTop: 8, paddingBottom: 8 }}
                  container
                  spacing={1}
                >
                  <Grid style={{ fontWeight: 600 }} item xs={6}>
                    <MDTypography variant="h5">Calculation Details: </MDTypography>
                  </Grid>
                  <Grid style={{ textAlign: "right", paddingRight: 15 }} item xs={6}>
                    <MDButton
                      onClick={calculateAppUsageStats}
                      style={{ marginLeft: 10, color: "white", width: "200px" }}
                      variant="gradient"
                      color="secondary"
                      fullWidth
                      disabled={isCalculatingAppUsage}
                    >
                      Refresh{" "}
                      {isCalculatingAppUsage ? (
                        <span
                          style={{ marginLeft: 5 }}
                          className="spinner-border spinner-border-sm ml-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : null}
                    </MDButton>
                  </Grid>
                  <Grid style={{ fontSize: 14 }} item xs={12}>
                    Last Calculated At:{" "}
                    <span
                      className="px-2"
                      style={{
                        backgroundColor: "#EEF2FB",
                        borderRadius: 5,
                        fontWeight: 450,
                        fontSize: 14,
                      }}
                    >
                      {Object.keys(cronData).length ? cronData?.lastRunAt : "-"}
                    </span>
                  </Grid>
                  <Grid style={{ fontSize: 14 }} item xs={6}>
                    Calculation Started At:{" "}
                    <span
                      className="px-2"
                      style={{
                        backgroundColor: "#EEF2FB",
                        borderRadius: 5,
                        fontWeight: 450,
                        fontSize: 14,
                      }}
                    >
                      {Object.keys(cronData).length ? cronData?.startTime : "-"}
                    </span>
                  </Grid>
                  <Grid style={{ fontSize: 14 }} item xs={12}>
                    Calculation Completed At:{" "}
                    <span
                      className="px-2"
                      style={{
                        backgroundColor: "#EEF2FB",
                        borderRadius: 5,
                        fontWeight: 450,
                        fontSize: 14,
                      }}
                    >
                      {Object.keys(cronData).length ? `${cronData?.endTime}` : "-"}
                    </span>
                  </Grid>
                  <Grid style={{ fontSize: 14 }} item xs={12}>
                    Calculation Duration:{" "}
                    <span
                      className="px-2"
                      style={{
                        backgroundColor: "#EEF2FB",
                        borderRadius: 5,
                        fontWeight: 450,
                        fontSize: 14,
                      }}
                    >
                      {Object.keys(cronData).length ? `${cronData?.duration}` : "-"}
                    </span>
                  </Grid>
                  <Grid style={{ fontSize: 14 }} item xs={12}>
                    Calculation Status:{" "}
                    <span
                      className="px-2"
                      style={{
                        backgroundColor: "#EEF2FB",
                        borderRadius: 5,
                        fontWeight: 450,
                        fontSize: 14,
                      }}
                    >
                      {Object.keys(cronData).length ? `${cronData?.calculationStatus}` : "-"}
                    </span>
                  </Grid>
                </Grid>
              </Card>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>

      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
