import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import Accordion from "react-bootstrap/Accordion";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import MuiDialog from "components/MuiDialog";

import Services from "../../services";

function Tables() {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [wanderers, setWanderers] = useState([]);
  const [page, setPage] = useState(1);
  const [searchItem, setSearchItem] = useState("");
  const [noOfMinReferredUsers, setNoOfMinReferredUsers] = useState();
  const [noOfMaxReferredUsers, setNoOfMaxReferredUsers] = useState();
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [testUsersOnly, setTestUsersOnly] = useState(false);
  const [realUsersOnly, setRealUsersOnly] = useState(false);

  const [userFilterValue, setUserFilterValue] = useState("all");

  const [columns, setColumns] = useState([
    { Header: "#", accessor: "serialNo", width: "5%", align: "left" },
    { Header: "Picture", accessor: "profilePicture", width: "5%", align: "center" },
    { Header: "Name", accessor: "name", width: "5%", align: "center" },
    { Header: "Location", accessor: "homeLocation", width: "5%", align: "center" },
    { Header: "Email", accessor: "email", width: "5%", align: "center" },
    { Header: "Last Login Source", accessor: "lastLoginSource", width: "5%", align: "center" },
    { Header: "Status", accessor: "userStatus", width: "5%", align: "center" },
    { Header: "Action", accessor: "action", width: "5%", align: "center" },
  ]);

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [selectedUserIds, setSelectedUserIds] = useState([]);

  const [isShowDialog, setIsShowDialog] = useState(false);

  const validateToken = () => {
    if (!localStorage.getItem("token")) {
      navigate("/authentication/sign-in", { replace: true });
    }
  };

  const saveCurrentState = () => {
    const previousState = {};

    setFromDate((prevState) => (previousState.fromDate = prevState));
    setToDate((prevState) => (previousState.toDate = prevState));
    setSelectedUserIds((prevState) => (previousState.selectedUserIds = prevState));
    setNoOfMinReferredUsers((prevState) => (previousState.noOfMinReferredUsers = prevState));
    setNoOfMaxReferredUsers((prevState) => (previousState.noOfMaxReferredUsers = prevState));
    setRealUsersOnly((prevState) => (previousState.realUsersOnly = prevState));
    setTestUsersOnly((prevState) => (previousState.testUsersOnly = prevState));
    setSearchItem((prevState) => (previousState.searchItem = prevState));
    setPage((prevState) => (previousState.page = prevState));

    sessionStorage.setItem("previousState", JSON.stringify(previousState));
  };

  const getWanderersList = async () => {
    setIsLoading(true);

    let body = fillFilters();

    try {
      const { data } = await Services.getAllWanderers(body);
      const mappedWanderers = data.map((wanderer) => ({
        serialNo: wanderer.serialNo,
        profilePicture: wanderer.profilePicture ? (
          <img alt="dp" width={50} src={wanderer.profilePicture} />
        ) : (
          "(Not provided)"
        ),
        name: `${wanderer.preferredName}`,
        homeLocation: wanderer.homeLocation && wanderer.homeLocation.name,
        email: wanderer.email,
        referredUsers: wanderer.noOfReferredUsers,
        lastLoginSource: wanderer.lastLoginSource,
        userStatus: wanderer.userStatus,
        action: (
          <MDButton
            onClick={() => {
              saveCurrentState();
              navigate(`/profile/${wanderer._id}`, { state: { userId: wanderer._id } });
            }}
            variant="outlined"
            color="info"
            fullWidth
          >
            View
          </MDButton>
        ),
      }));

      setWanderers(() => mappedWanderers);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    validateToken();
  });

  useEffect(() => {
    if (page > 1) {
      handleApply();
    }
  }, [page]);

  useEffect(() => {
    getWanderersList();
  }, []);

  useEffect(() => {
    if (searchItem) {
      handleApply();
    }
  }, [searchItem]);

  const handleClearAll = () => {
    setFromDate("");
    setToDate("");
    setNoOfMinReferredUsers(undefined);
    setNoOfMaxReferredUsers(undefined);
    setRealUsersOnly(false);
    setTestUsersOnly(false);
    setUserFilterValue("all");
    setSearchItem("");
    setPage(1);
  };

  const fillFilters = () => {
    const body = {};

    let previousState = sessionStorage.getItem("previousState");

    if (previousState) {
      previousState = JSON.parse(previousState);

      if (previousState.fromDate) {
        setFromDate(previousState.fromDate);

        body.filters = { ...body.filters, referredUsers: {} };

        body.filters.referredUsers.fromDate = previousState.fromDate;
      }

      if (previousState.toDate) {
        setToDate(previousState.toDate);

        body.filters = { ...body.filters };

        if (!body.filters.referredUsers) {
          body.filters.referredUsers = {};
        }

        body.filters.referredUsers.toDate = previousState.toDate;
      }

      if (previousState.noOfMinReferredUsers) {
        setNoOfMinReferredUsers(previousState.noOfMinReferredUsers);

        body.filters = { ...body.filters };

        if (!body.filters.referredUsers) {
          body.filters.referredUsers = {};
        }

        body.filters.referredUsers.noOfMinReferredUsers = previousState.noOfMinReferredUsers;
      }

      if (previousState.noOfMaxReferredUsers) {
        setNoOfMaxReferredUsers(previousState.noOfMaxReferredUsers);

        body.filters = { ...body.filters };

        if (!body.filters.referredUsers) {
          body.filters.referredUsers = {};
        }

        body.filters.referredUsers.noOfMaxReferredUsers = previousState.noOfMaxReferredUsers;
      }

      if (previousState.testUsersOnly) {
        setUserFilterValue("testOnly");
        setTestUsersOnly(true);

        body.filters = { ...body.filters };

        body.filters.testUsersOnly = previousState.testUsersOnly;
      }

      if (previousState.realUsersOnly) {
        setUserFilterValue("realOnly");
        setRealUsersOnly(true);

        body.filters = { ...body.filters };

        body.filters.realUsersOnly = previousState.realUsersOnly;
      }

      if (previousState.searchItem) {
        setSearchItem(previousState.searchItem);

        body.search = previousState.searchItem;
      }

      setPage(() => previousState.page);

      body.page = previousState.page;

      sessionStorage.removeItem("previousState");
    } else {
      if (fromDate) {
        body.filters = { ...body.filters, referredUsers: {} };

        body.filters.referredUsers.fromDate = fromDate;
      }

      if (toDate) {
        body.filters = { ...body.filters };

        if (!body.filters.referredUsers) {
          body.filters.referredUsers = {};
        }

        body.filters.referredUsers.toDate = toDate;
      }

      if (noOfMinReferredUsers) {
        body.filters = { ...body.filters };

        if (!body.filters.referredUsers) {
          body.filters.referredUsers = {};
        }

        body.filters.referredUsers.noOfMinReferredUsers = noOfMinReferredUsers;
      }

      if (noOfMaxReferredUsers) {
        body.filters = { ...body.filters };

        if (!body.filters.referredUsers) {
          body.filters.referredUsers = {};
        }

        body.filters.referredUsers.noOfMaxReferredUsers = noOfMaxReferredUsers;
      }

      if (testUsersOnly) {
        body.filters = { ...body.filters };

        body.filters.testUsersOnly = testUsersOnly;
      }

      if (realUsersOnly) {
        body.filters = { ...body.filters };

        body.filters.realUsersOnly = realUsersOnly;
      }

      if (searchItem) {
        body.search = searchItem;
      }

      body.page = page;
    }

    return body;
  };

  const handleApply = async () => {
    setIsLoading(true);

    if (noOfMinReferredUsers || noOfMaxReferredUsers) {
      setColumns(() => {
        return [
          { Header: "#", accessor: "serialNo", width: "5%", align: "left" },
          { Header: "Picture", accessor: "profilePicture", width: "5%", align: "center" },
          { Header: "Name", accessor: "name", width: "5%", align: "center" },
          { Header: "Location", accessor: "homeLocation", width: "5%", align: "center" },
          { Header: "Email", accessor: "email", width: "5%", align: "center" },
          { Header: "Referred Users", accessor: "referredUsers", width: "5%", align: "center" },
          {
            Header: "Last Login Source",
            accessor: "lastLoginSource",
            width: "5%",
            align: "center",
          },
          { Header: "Status", accessor: "userStatus", width: "5%", align: "center" },
          { Header: "Action", accessor: "action", width: "5%", align: "center" },
        ];
      });
    } else {
      setColumns(() => {
        return [
          { Header: "#", accessor: "serialNo", width: "5%", align: "left" },
          { Header: "Picture", accessor: "profilePicture", width: "5%", align: "center" },
          { Header: "Name", accessor: "name", width: "5%", align: "center" },
          { Header: "Location", accessor: "homeLocation", width: "5%", align: "center" },
          { Header: "Email", accessor: "email", width: "5%", align: "center" },
          {
            Header: "Last Login Source",
            accessor: "lastLoginSource",
            width: "5%",
            align: "center",
          },
          { Header: "Status", accessor: "userStatus", width: "5%", align: "center" },
          { Header: "Action", accessor: "action", width: "5%", align: "center" },
        ];
      });
    }

    let body = fillFilters();

    try {
      const { data } = await Services.getAllWanderers(body);

      const mappedWanderers = data.map((wanderer) => ({
        serialNo: wanderer.serialNo,
        profilePicture: wanderer.profilePicture ? (
          <img alt="dp" width={50} src={wanderer.profilePicture} />
        ) : (
          "(Not provided)"
        ),
        name: `${wanderer.preferredName}`,
        homeLocation: wanderer.homeLocation && wanderer.homeLocation.name,
        email: wanderer.email,
        lastLoginSource: wanderer.lastLoginSource,
        referredUsers: wanderer.noOfReferredUsers,
        userStatus: wanderer.userStatus,
        action: (
          <MDButton
            onClick={() => {
              saveCurrentState();
              navigate(`/profile/${wanderer._id}`, { state: { userId: wanderer._id } });
            }}
            variant="outlined"
            color="info"
            fullWidth
          >
            View
          </MDButton>
        ),
      }));

      setWanderers(() => mappedWanderers);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDisableUsers = async () => {
    setIsLoading(true);
    try {
      await Services.updateUsersStatus({
        status: "SUSPENDED",
        userIds: selectedUserIds,
      });

      await getWanderersList();
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
      setSelectedUserIds(() => []);
    }
  };

  const getTitle = () => {
    return <div>{`Suspend Users`}</div>;
  };

  const getContent = () => {
    return <div>{`Are you sure, you want to suspend these users?`}</div>;
  };

  return (
    <DashboardLayout>
      <MuiDialog
        isShowDialog={isShowDialog}
        dialogTitle={getTitle()}
        dialogContent={getContent()}
        cancelTitle={"No"}
        onCancel={() => {
          setIsShowDialog(false);
        }}
        confirmTitle={"Yes"}
        onConfirm={() => {
          setIsShowDialog(false);
          handleDisableUsers();
        }}
      />
      <DashboardNavbar
        value={searchItem}
        getSearchItem={(value) => {
          setSearchItem(value);
          setPage(1);
        }}
      />

      <div>
        <Accordion>
          <Accordion.Item style={{ borderRadius: 15 }} eventKey="wl-accordion">
            <Accordion.Header>Filters</Accordion.Header>
            <Accordion.Body>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <MDTypography fontWeight="regular" variant="h6" color="dark">
                    Referrers
                  </MDTypography>
                </Grid>
                <Grid item xs={2}>
                  <MDInput
                    onChange={(e) => {
                      setFromDate(e.target.value);
                      setPage(1);
                    }}
                    value={fromDate}
                    label="From"
                    onFocus={(e) => (e.target.type = "date")}
                    onBlur={(e) => (e.target.type = "text")}
                  />
                </Grid>

                <Grid item xs={2}>
                  <MDInput
                    onChange={(e) => {
                      setToDate(e.target.value);
                      setPage(1);
                    }}
                    value={toDate}
                    label="To"
                    onFocus={(e) => (e.target.type = "date")}
                    onBlur={(e) => (e.target.type = "text")}
                  />
                </Grid>

                <Grid item xs={2}>
                  <MDInput
                    type="number"
                    onChange={(e) => {
                      setNoOfMinReferredUsers(e.target.value);
                      setPage(1);
                    }}
                    value={noOfMinReferredUsers}
                    label="Min Referred Users"
                  />
                </Grid>

                <Grid item xs={2}>
                  <MDInput
                    type="number"
                    onChange={(e) => {
                      setNoOfMaxReferredUsers(e.target.value);
                      setPage(1);
                    }}
                    value={noOfMaxReferredUsers}
                    label="Max Referred Users"
                  />
                </Grid>

                <Grid item xs={10}></Grid>
                <Grid item xs={10}></Grid>
                <Grid item xs={12}>
                  <FormControl>
                    <FormLabel variant="h6" id="demo-row-radio-buttons-group-label">
                      All Users vs Test Users
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={userFilterValue}
                      onChange={(e) => {
                        setPage(1);
                        setUserFilterValue(e.target.value);
                        if (e.target.value == "realOnly") {
                          setRealUsersOnly(!realUsersOnly);
                          if (!realUsersOnly) {
                            setTestUsersOnly(false);
                          }
                        }
                        if (e.target.value == "testOnly") {
                          setTestUsersOnly(!testUsersOnly);
                          if (!testUsersOnly) {
                            setRealUsersOnly(false);
                          }
                        }
                        if (e.target.value == "all") {
                          setRealUsersOnly(false);
                          setTestUsersOnly(false);
                        }
                      }}
                    >
                      <FormControlLabel value="all" control={<Radio />} label="All User" />
                      <FormControlLabel
                        value="realOnly"
                        control={<Radio />}
                        label="Real Users Only"
                      />
                      <FormControlLabel
                        value="testOnly"
                        control={<Radio />}
                        label="Test Users Only"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid className="ms-auto" item xs={2}>
                  <MDButton onClick={handleClearAll} variant="outlined" color="secondary" fullWidth>
                    Clear All
                  </MDButton>
                </Grid>
                <Grid item xs={2}>
                  <MDButton
                    onClick={() => {
                      handleApply();
                    }}
                    variant="gradient"
                    color="info"
                    fullWidth
                  >
                    Apply
                  </MDButton>
                </Grid>
              </Grid>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
                >
                  <MDTypography variant="h6" color="white">
                    Wanderers List
                    {isLoading ? (
                      <span
                        style={{ marginLeft: 5 }}
                        className="spinner-border spinner-border-sm ml-1"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : null}
                  </MDTypography>
                </div>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{
                    columns,
                    rows: wanderers,
                  }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
              {wanderers.length <= 10 ? (
                <MDBox mx={2} py={3} px={2} variant="gradient" borderRadius="lg" align={"center"}>
                  {page === 1 ? null : (
                    <MDButton
                      onClick={() => {
                        setPage((prevState) => prevState - 1);
                      }}
                      variant="outlined"
                      color="secondary"
                    >
                      Previous Page
                    </MDButton>
                  )}
                  &nbsp; &nbsp;
                  <MDButton
                    onClick={() => {
                      setPage((prevState) => prevState + 1);
                    }}
                    variant="outlined"
                    color="secondary"
                  >
                    Next Page
                  </MDButton>
                </MDBox>
              ) : null}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Tables;
