import { Fragment, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

export default function MuiDialog({
  isShowDialog,
  dialogTitle,
  dialogContent,
  confirmTitle,
  cancelTitle,
  onCancel,
  onConfirm,
  isDoubleAction = true,
}) {
  const [isShow, setIsShow] = useState(isShowDialog);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    if (isShowDialog) {
      setIsShow(true);
    } else {
      setIsShow(false);
    }
  }, [isShowDialog]);

  return (
    <Fragment>
      <Dialog
        // fullWidth={true}
        maxWidth={"sm"}
        fullScreen={fullScreen}
        open={isShow}
        onClose={onCancel}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent>{dialogContent}</DialogContent>
        <DialogActions>
          {isDoubleAction ? <Button onClick={onCancel}>{cancelTitle}</Button> : null}
          <Button onClick={onConfirm}>{confirmTitle}</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
