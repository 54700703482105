import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const initialState = {
  testUsers: [],
  wanderers: [],
  selectedTestUser: undefined,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setTestUsers: (state, { payload }) => {
      state.testUsers = payload;
    },
    setWanderers: (state, { payload }) => {
      state.wanderers = payload;
    },
    setSelectedTestUser: (state, { payload }) => {
      state.selectedTestUser = payload;
    },
  },
});

export const { setTestUsers, setSelectedTestUser } = authSlice.actions;
export default authSlice.reducer;

export const getSelectedTestUser = (state) => state.auth.selectedTestUser;
export const getTestUsers = (state) => state.auth.testUsers;
export const getWanderers = (state) => state.auth.wanderers;
