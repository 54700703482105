import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import { useNavigate } from "react-router-dom";
import { Card, Typography } from "@mui/material";
import Accordion from "react-bootstrap/Accordion";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import moment from "moment";
import { ClipLoader } from "react-spinners";
import { toast, ToastContainer } from "react-toastify";

import Services from "../../services";

function Dashboard() {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [isAllLoading, setIsAllLoading] = useState(false);
  const [fromDate, setFromDate] = useState(
    `${moment().subtract(1, "month").startOf("month").format().split("T")[0]}`
  );
  const [toDate, setToDate] = useState(
    `${moment().subtract(1, "month").endOf("month").format().split("T")[0]}`
  );
  const [activeUsersData, setActiveUsersData] = useState({
    averageDAU: 0,
    averageMAU: 0,
    dauMauRatio: 0,
  });
  const [cronData, setCronData] = useState({});
  const [calculationData, setCalculationData] = useState([]);
  const [activeKey, setActiveKey] = useState("dau/mau-accordion");

  const validateToken = () => {
    if (!localStorage.getItem("token")) {
      navigate("/authentication/sign-in", { replace: true });
    }
  };

  useEffect(() => {
    validateToken();
  });

  const handleClearAll = () => {
    setFromDate("");
    setToDate("");
  };

  const getActiveUsersCronDetails = async () => {
    setIsAllLoading(true);

    try {
      const {
        data: { data },
      } = await Services.getActiveUsersCronDetails({ cronName: "CALCULATE_UNQIUE_USERS" });

      const {
        lastRunAt,
        duration: { startTime, endTime },
      } = data;

      const differenceInSeconds = moment(endTime).diff(startTime, "seconds");

      const duration = moment.duration(differenceInSeconds, "seconds");

      setCronData({
        lastRunAt: moment(lastRunAt).format("LLL"),
        duration: `${
          differenceInSeconds > 60
            ? duration.asMinutes().toFixed(1)
            : duration.asSeconds().toFixed(1)
        } ${differenceInSeconds > 60 ? "minutes" : "seconds"}`,
      });
    } catch (err) {
      console.log(err);
    } finally {
      setIsAllLoading(false);
    }
  };

  const getDauMau = async () => {
    if (!fromDate || !toDate) {
      toast.error("Invalid date range");
    }

    setIsLoading(true);

    try {
      const {
        data: { data },
      } = await Services.getActiveUsers({
        fromDate,
        toDate,
      });

      const { averageDAU, averageMAU, "DAU/MAU": dauMauRatio, metaData } = data;

      setActiveUsersData({
        averageDAU,
        averageMAU,
        dauMauRatio,
      });

      setCalculationData(metaData);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getDauMau();
    getActiveUsersCronDetails();
  }, []);

  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "blue",
  };

  const handleAccordionToggle = (eventKey) => {
    setActiveKey(activeKey === eventKey ? null : eventKey);
  };

  return (
    <DashboardLayout>
      <ToastContainer />
      <DashboardNavbar />
      <MDBox py={3}>
        {isAllLoading ? (
          <div style={{ marginTop: "25vh" }}>
            <ClipLoader
              color={"#574599"}
              loading={isAllLoading}
              cssOverride={override}
              size={100}
              aria-label="Loading Spinner"
              data-testid="loader"
            ></ClipLoader>
          </div>
        ) : (
          <div>
            <Typography className="mb-4 fw-bold fs-2">{"DAU/MAU"}</Typography>
            <Accordion activeKey={activeKey} onSelect={handleAccordionToggle}>
              <Accordion.Item
                style={{ borderRadius: 15, marginBottom: 30 }}
                eventKey="dau/mau-accordion"
              >
                <Accordion.Header>Filters</Accordion.Header>
                <Accordion.Body>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <MDTypography fontWeight="regular" variant="h6" color="dark">
                        DAU/MAU
                      </MDTypography>
                    </Grid>
                    <Grid item xs={2}>
                      <MDInput
                        onChange={(e) => {
                          setFromDate(e.target.value);
                        }}
                        value={fromDate}
                        label="From"
                        onFocus={(e) => (e.target.type = "date")}
                        onBlur={(e) => (e.target.type = "text")}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <MDInput
                        onChange={(e) => {
                          setToDate(e.target.value);
                        }}
                        value={toDate}
                        label="To"
                        onFocus={(e) => (e.target.type = "date")}
                        onBlur={(e) => (e.target.type = "text")}
                      />
                    </Grid>
                    <Grid item xs={8}></Grid>
                    <Grid item xs={5}>
                      <MDTypography variant="button" fontWeight="light" color="text">
                        Note: Data recording started from 6th november 2023.
                      </MDTypography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid className="ms-auto" item xs={2}>
                      <MDButton
                        onClick={handleClearAll}
                        variant="outlined"
                        color="secondary"
                        fullWidth
                      >
                        Clear All
                      </MDButton>
                    </Grid>
                    <Grid item xs={2}>
                      <MDButton onClick={getDauMau} variant="gradient" color="info" fullWidth>
                        {isLoading ? (
                          <span
                            style={{ marginLeft: 5, marginBottom: 5, padding: 0 }}
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          "Apply"
                        )}
                      </MDButton>
                    </Grid>
                  </Grid>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <ComplexStatisticsCard
                  color={"primary"}
                  icon={"leaderboard"}
                  title={"Average DAU"}
                  count={activeUsersData.averageDAU}
                  percentage={{}}
                />
              </Grid>
              <Grid item xs={6}>
                <ComplexStatisticsCard
                  color={"primary"}
                  icon={"leaderboard"}
                  title={"Average MAU"}
                  count={activeUsersData.averageMAU}
                  percentage={{}}
                />
              </Grid>
              <Grid item xs={6}>
                <Card>
                  <Grid
                    style={{ paddingLeft: 15, paddingTop: 8, paddingBottom: 8 }}
                    container
                    spacing={1}
                  >
                    <Grid style={{ fontWeight: 600 }} item xs={12}>
                      <MDTypography variant="h5">Calculation Details:</MDTypography>
                    </Grid>
                    <Grid style={{ fontSize: 16 }} item xs={12}>
                      Last Calculated At:{" "}
                      <span
                        className="px-2"
                        style={{
                          backgroundColor: "#EEF2FB",
                          borderRadius: 5,
                          fontWeight: 450,
                          fontSize: 16,
                        }}
                      >
                        {Object.keys(cronData).length
                          ? moment(cronData?.lastRunAt).format("LLL")
                          : "-"}
                      </span>
                    </Grid>
                    <Grid style={{ fontSize: 16 }} item xs={12}>
                      Calculation Duration:{" "}
                      <span
                        className="px-2"
                        style={{
                          backgroundColor: "#EEF2FB",
                          borderRadius: 5,
                          fontWeight: 450,
                          fontSize: 16,
                        }}
                      >
                        {Object.keys(cronData).length ? `${cronData?.duration}` : "-"}
                      </span>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <ComplexStatisticsCard
                  color={"primary"}
                  icon={"leaderboard"}
                  title={"DAU/MAU Ratio"}
                  count={activeUsersData.dauMauRatio}
                  percentage={{}}
                />
              </Grid>
              <Grid item xs={6}>
                <Card>
                  <Grid
                    style={{ paddingLeft: 15, paddingTop: 8, paddingBottom: 8 }}
                    container
                    spacing={1}
                  >
                    <Grid style={{ fontWeight: 600 }} item xs={12}>
                      <MDTypography variant="h5">DAU JSON:</MDTypography>
                    </Grid>
                    <Grid style={{ fontSize: 16 }} item xs={12}>
                      {calculationData.length ? JSON.stringify(calculationData[0]) : null}
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card>
                  <Grid
                    style={{ paddingLeft: 15, paddingTop: 8, paddingBottom: 8 }}
                    container
                    spacing={1}
                  >
                    <Grid style={{ fontWeight: 600 }} item xs={12}>
                      <MDTypography variant="h5">MAU JSON:</MDTypography>
                    </Grid>
                    <Grid style={{ fontSize: 16 }} item xs={12}>
                      {calculationData.length ? JSON.stringify(calculationData[1]) : null}
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
              {/* <Grid item xs={12}>
                <Card>
                  <Grid container spacing={1}>
                    <Grid style={{ textAlign: "center", fontWeight: 600 }} item xs={12}>
                      Calculation Details{" "}
                    </Grid>
                    <Grid style={{ textAlign: "center" }} item xs={6}>
                      Last Calculated At:{" "}
                      <span
                        className="px-2"
                        style={{ backgroundColor: "#EEF2FB", borderRadius: 5, fontWeight: 450 }}
                      >
                        {Object.keys(cronData).length
                          ? moment(cronData?.lastRunAt).format("LLL")
                          : "-"}
                      </span>
                    </Grid>
                    <Grid style={{ textAlign: "center" }} item xs={6}>
                      Calculation Duration:{" "}
                      <span
                        className="px-2"
                        style={{ backgroundColor: "#EEF2FB", borderRadius: 5, fontWeight: 450 }}
                      >
                        {Object.keys(cronData).length
                          ? `${moment(cronData?.duration?.endTime).diff(
                              cronData?.duration?.startTime,
                              "seconds"
                            )} seconds`
                          : "-"}
                      </span>
                    </Grid>
                    <Grid style={{ textAlign: "center" }} item xs={12}>
                      {calculationData.length ? "DAU JSON" : null}
                    </Grid>
                    <Grid style={{ textAlign: "center" }} item xs={12}>
                      {JSON.stringify(calculationData[0])}
                    </Grid>
                    <Grid style={{ textAlign: "center" }} item xs={12}>
                      {calculationData.length ? "MAU JSON" : null}
                    </Grid>
                    <Grid style={{ textAlign: "center" }} item xs={12}>
                      {JSON.stringify(calculationData[1])}
                    </Grid>
                  </Grid>
                </Card>
              </Grid> */}
            </Grid>
          </div>
        )}
      </MDBox>
      <div className="d-flex align-items-end" style={{ height: "60vh" }}>
        <Footer />
      </div>
    </DashboardLayout>
  );
}

export default Dashboard;
