import React, { useState } from "react";
import Services from "../../../services";

const SendMessage = ({ userId, chatId, refreshChat, scrollToBottom }) => {
  const [message, setMessage] = useState("");

  const bgLight = {
    backgroundColor: "#F3F4F6",
  };

  const sendMessage = async () => {
    setMessage("");

    const res = await Services.sendMessage(userId, chatId, message);
    if (res.status == 200) {
      await refreshChat();
      scrollToBottom();
    }
  };

  return (
    <div className="col-xl-10 col-12 me-auto">
      <form
        onSubmit={(e) => {
          sendMessage();
          e.preventDefault();
        }}
        className="d-flex align-items-center py-3 px-4 "
      >
        <input
          style={{ ...bgLight }}
          type="text"
          id="text"
          onChange={(e) => setMessage(e.target.value)}
          className="form-control py-2 border border-secondary rounded-start-5 px-3 no-focus shadow-lg"
          placeholder="Start typing"
          value={message}
        />
        <button style={{ ...bgLight }} type="submit" className="btn border-chat no-focus">
          <img height={28} src="/images/chat/snd.png" alt="icon" className="pointer" />
        </button>
      </form>
    </div>
  );
};

export default SendMessage;
