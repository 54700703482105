import React, { createContext, useEffect, useState } from "react";
import { io } from "socket.io-client";

export const SocketContext = createContext({});

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState();

  const connectToSupportSocket = async () => {
    const token = process.env.REACT_APP_SUPPORT_TOKEN;
    const socket = io(process.env.REACT_APP_API_URL, { query: { token } });
    socket.on("connect", () => console.log("connected to support socket"));
    setSocket(socket);
  };

  useEffect(() => {
    connectToSupportSocket();
  }, []);

  return <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>;
};
