import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MDInput from "components/MDInput";
import MDSnackbar from "components/MDSnackbar";
import { Card, Switch } from "@mui/material";
import MDButton from "components/MDButton";
import { ClipLoader } from "react-spinners";

import Services from "../../services";
import MDTextArea from "components/MDTextArea";

function Settings() {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [isSuccess, setIsSuccess] = useState(true);
  const [successMessage, setSuccessMessage] = useState(true);
  const [isFailure, setIsFailure] = useState(false);
  const [failureMessage, setFailureMessage] = useState(false);
  const [adsAccordionKey, setAdsAccordionKey] = useState("");
  const [serverSettings, setServerSettings] = useState({});
  const [serverSettingsArray, setServerSettingsArray] = useState([]);
  const [updateCounter, setUpdateCounter] = useState(0);

  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "blue",
  };

  const validateToken = () => {
    if (!localStorage.getItem("token")) {
      navigate("/authentication/sign-in", { replace: true });
    }
  };

  const getNotificationColor = () => {
    let message = "";

    if (isSuccess) {
      message = "success";
    } else if (isFailure) {
      message = "error";
    }

    return message;
  };

  const getNotificationTitle = () => {
    let message = "";

    if (isSuccess) {
      message = "Success";
    } else if (isFailure) {
      message = "Error";
    }

    return message;
  };

  const getContent = () => {
    let content = "";

    if (isSuccess) {
      content = <div>{successMessage}</div>;
    } else if (isFailure) {
      content = <div>{failureMessage}</div>;
    }

    return content;
  };

  const getServerSettings = async () => {
    setIsLoading(true);

    try {
      const {
        data: { data },
      } = await Services.getServerSettings();

      const filteredArray = [];

      for (const key in data) {
        if (!["_id", "__v", "createdAt", "updatedAt"].includes(key)) {
          filteredArray.push({
            key,
            value: data[key],
          });
        }
      }

      setServerSettings(() => {
        let updatedState = {};

        for (let index = 0; index < filteredArray.length; index++) {
          updatedState[filteredArray[index]["key"]] = filteredArray[index]["value"];
        }

        return updatedState;
      });

      setServerSettingsArray(filteredArray);

      setIsFailure(false);
      setIsSuccess(true);
    } catch (err) {
      setIsSuccess(false);
      setIsFailure(true);
      setFailureMessage(err.message);
      setIsShowPopup(true);
    } finally {
      setIsLoading(false);
    }
  };

  const updateServerSettings = async () => {
    if (updateCounter <= 2) {
      return;
    }

    setIsSaving(true);

    try {
      const {
        data: { message },
      } = await Services.updateServerSettings(serverSettings);

      setIsFailure(false);
      setIsSuccess(true);
      setSuccessMessage(message);

      getServerSettings();
    } catch (err) {
      setIsSuccess(false);
      setIsFailure(true);
      setFailureMessage(err.message);
    } finally {
      setIsShowPopup(true);

      setUpdateCounter(2);

      setIsSaving(false);
    }
  };

  useEffect(() => {
    validateToken();
  }, []);

  useEffect(() => {
    getServerSettings();
  }, []);

  useEffect(() => {
    setUpdateCounter((prevState) => prevState + 1);
  }, [serverSettings]);

  const getInputValue = (key) => {
    if (["object", "array"].includes(typeof serverSettings[key])) {
      return JSON.stringify(serverSettings[key]);
    }

    return serverSettings[key];
  };

  const handleDynamicChange = (e, key) => {
    setServerSettings((prevState) => {
      const updatedState = { ...prevState };

      if (["object", "array"].includes(typeof e.target.value)) {
        updatedState[key] = JSON.parse(e.target.value);
      } else {
        if (e.target.value.toLowerCase() === "true") {
          updatedState[key] = true;
        } else if (e.target.value.toLowerCase() === "false") {
          updatedState[key] = false;
        } else {
          updatedState[key] = e.target.value;
        }
      }

      return updatedState;
    });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox mb={2} />

      {isLoading ? (
        <div style={{ marginTop: "25vh" }}>
          <ClipLoader
            color={"#574599"}
            loading={true}
            cssOverride={override}
            size={100}
            aria-label="Loading Spinner"
            data-testid="loader"
          ></ClipLoader>
        </div>
      ) : (
        <Card style={{ marginBottom: 10 }}>
          <Grid container spacing={1}>
            <Grid item xs={8}>
              <div
                style={{
                  padding: "10px 20px 20px 20px",
                }}
              >
                {serverSettingsArray.map((item) => {
                  return (
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <MDTypography style={{ paddingTop: 8, fontSize: 16 }}>
                        {item.key}
                      </MDTypography>

                      <MDTextArea
                        style={{ marginTop: 8 }}
                        value={getInputValue(item.key)}
                        onChange={(e) => handleDynamicChange(e, item.key)}
                      />
                    </div>
                  );
                })}
              </div>
            </Grid>

            <Grid item xs={4}></Grid>
            <Grid item xs={6}></Grid>

            <Grid style={{ textAlign: "right" }} item xs={6}>
              {" "}
              <div className="p-3">
                <MDButton
                  style={{ width: 200 }}
                  disabled={updateCounter <= 2}
                  onClick={() => updateServerSettings()}
                  variant="contained"
                  color="info"
                >
                  {isSaving ? (
                    <span
                      style={{ marginLeft: 5 }}
                      className="spinner-border spinner-border-sm ml-1"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    "Save Settings"
                  )}
                </MDButton>
              </div>
            </Grid>
          </Grid>
        </Card>
      )}

      <MDSnackbar
        color={getNotificationColor()}
        icon="check"
        title={getNotificationTitle()}
        content={getContent()}
        dateTime=""
        open={isShowPopup}
        onClose={() => setIsShowPopup(false)}
        close={() => setIsShowPopup(false)}
        bgWhite
      />

      <Footer />
    </DashboardLayout>
  );
}

export default Settings;
