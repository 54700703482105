import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { useNavigate } from "react-router-dom";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import moment from "moment";
import { pascalCase } from "pascal-case";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import Services from "../../services";
import { FormControlLabel, Checkbox } from "@mui/material";
import { CHAT_STATUS } from "constants";

function Tables() {
  const navigate = useNavigate();

  const { REACT_APP_SUPPORT_ID: SUPPORT_ID } = process.env;

  const [isLoadingChats, setIsLoadingChats] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [isInitialRender, setIsInitialRender] = useState(0);
  const [chats, setChats] = useState([]);
  const [filteredChats, setFilteredChats] = useState([]);
  const [page, setPage] = useState(1);
  const [unreadOnly, setUnreadOnly] = useState(false);
  // const [searchItem, setSearchItem] = useState("");

  const [ChatsListColumns] = useState([
    { Header: "#", accessor: "serialNo", width: "2%", align: "left" },
    { Header: "Name", accessor: "name", width: "5%", align: "left" },
    { Header: "Message", accessor: "message", width: "10%", align: "center" },
    { Header: "Last Message At", accessor: "lastMessageAt", width: "30%", align: "center" },
    { Header: "Status", accessor: "status", width: "5%", align: "center" },
    { Header: "Action#1", accessor: "action1", width: "5%", align: "center" },
    { Header: "Action#2", accessor: "action2", width: "5%", align: "center" },
    { Header: "Action#3", accessor: "action3", width: "5%", align: "center" },
  ]);

  useEffect(() => {
    loadPreviousState();
  }, []);

  useEffect(() => {
    if (isInitialRender > 0) {
      getSupportChats();
    }
  }, [page]);

  useEffect(() => {
    getSupportChats();
  }, []);

  useEffect(() => {
    if (isInitialRender > 0) {
      filterChats();
    }
  }, [toggle, unreadOnly]);

  const saveCurrentState = () => {
    sessionStorage.setItem(
      "support-chat-list-previous-state",
      JSON.stringify({
        page,
      })
    );
  };

  const loadPreviousState = () => {
    let previousState = sessionStorage.getItem("support-chat-list-previous-state");

    if (previousState) {
      previousState = JSON.parse(previousState);

      sessionStorage.removeItem("support-chat-list-previous-state");
    } else {
      getSupportChats();
    }
  };

  const getSupportChats = async () => {
    setIsLoadingChats(true);

    setIsInitialRender((prevState) => prevState + 1);

    try {
      let {
        data: { data: supportChats },
      } = await Services.getSupportChats({ page, unreadOnly });

      let { data: wanderSupportUser } = await Services.getUserProfile(SUPPORT_ID);

      const chatsList = supportChats.map((item) => ({
        serialNo: item.serialNo,
        _id: item._id,
        name: (
          <>
            <img
              alt="dp"
              width={50}
              height={50}
              style={{ borderRadius: 25 }}
              src={item.profilePictureToShow}
            />
            <span style={{ marginLeft: 10 }}>{item.chatNameToShow}</span>
          </>
        ),
        message: (
          <div
            style={{
              width: 245,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {item.lastMessage}
          </div>
        ),
        lastMessageAt: moment(item.lastMessageAt).format("lll"),

        status: item.isReadByMe ? (
          <span>Seen ({pascalCase(item.currentStatus)})</span>
        ) : (
          <span style={{ color: "maroon" }}>Unseen ({pascalCase(item.currentStatus)})</span>
        ),

        // Store plain string for filtering
        rawStatus: item.isReadByMe
          ? `Seen (${pascalCase(item.currentStatus)})`
          : `Unseen (${pascalCase(item.currentStatus)})`,

        action1: (
          <MDButton
            onClick={() => {
              saveCurrentState();
              navigate("/support-chats/chat-details", {
                state: { chatData: item, user: wanderSupportUser },
              });
            }}
            variant="gradient"
            color="success"
            fullWidth
          >
            Chat Now
          </MDButton>
        ),
        action2: (
          <MDButton
            onClick={() => markChatAsRead(item)}
            variant="gradient"
            color="warning"
            fullWidth
            disabled={item.isRead[SUPPORT_ID]}
          >
            Mark As Read
          </MDButton>
        ),
        action3: (
          <MDButton
            onClick={() => markChatAsDeleted(item)}
            variant="gradient"
            color="error"
            fullWidth
          >
            {item.status[SUPPORT_ID] === CHAT_STATUS.DELETED ? "Activate Chat" : "Delete Chat"}
          </MDButton>
        ),
      }));

      setChats(chatsList);
      filterChats(chatsList);
    } catch (error) {
      console.log("🚀 ~ file: index.js:90 ~ getUserChats ~ error:", error);
    } finally {
      setIsLoadingChats(false);
      setTimeout(() => {
        setToggle(!toggle);
      }, 5000);
    }
  };

  const filterChats = (chatsToFilter = chats) => {
    // Apply filtering based on the unreadOnly flag
    if (unreadOnly) {
      const filteredChats = chatsToFilter.filter((chat) => {
        const includesUnseen = chat.rawStatus.toLowerCase().includes("unseen");
        return includesUnseen;
      });
      setFilteredChats(filteredChats);
    } else {
      setFilteredChats(chatsToFilter);
    }
  };

  const markChatAsRead = async (item) => {
    setIsLoadingChats(true);

    try {
      await Services.updateSupportChat({
        params: { chatId: item._id },
        body: { isRead: true },
      });

      getSupportChats();
    } catch (err) {
    } finally {
      setTimeout(() => {
        setIsLoadingChats(false);
      }, 2000);
    }
  };

  const markChatAsDeleted = async (item) => {
    setIsLoadingChats(true);

    try {
      await Services.updateSupportChat({
        params: { chatId: item._id },
        body: {
          status:
            item.status[SUPPORT_ID] === CHAT_STATUS.ACTIVE
              ? CHAT_STATUS.DELETED
              : CHAT_STATUS.ACTIVE,
        },
      });

      getSupportChats();
    } catch (err) {
    } finally {
      setTimeout(() => {
        setIsLoadingChats(false);
      }, 2000);
    }
  };

  const validateToken = () => {
    if (!localStorage.getItem("token")) {
      navigate("/authentication/sign-in", { replace: true });
    }
  };

  useEffect(() => {
    validateToken();
  });

  return (
    <DashboardLayout>
      <DashboardNavbar
        getSearchItem={(value) => {
          // setSearchItem(value);
        }}
      />

      <div>
        <MDBox
          mx={0}
          mt={3}
          py={3}
          px={2}
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
        >
          <MDTypography variant="h3" color="white">
            Support Chats
            {isLoadingChats ? (
              <span
                style={{
                  marginLeft: 10,
                  marginBottom: 5,
                  width: "1.2rem",
                  height: "1.2rem",
                  borderWidth: "2px",
                }}
                className="spinner-border"
                role="status"
                aria-hidden="true"
              ></span>
            ) : null}
          </MDTypography>
          <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
            <FormControlLabel
              control={
                <Checkbox
                  id="unreadFilter"
                  checked={unreadOnly}
                  onChange={() => setUnreadOnly(!unreadOnly)} // Toggle the unread filter
                />
              }
              label="Unread"
            />
          </div>
        </MDBox>
      </div>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card style={{ padding: 0 }}>
              {filteredChats.length > 0 ? (
                <MDBox>
                  <DataTable
                    table={{
                      columns: ChatsListColumns,
                      rows: filteredChats,
                    }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                </MDBox>
              ) : isLoadingChats ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: 10,
                    paddingBottom: 10,
                  }}
                >
                  <span
                    style={{
                      alignItems: "center",
                      width: "2rem",
                      height: "2rem",
                      borderWidth: "2px",
                    }}
                    className="spinner-border"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: 10,
                    paddingBottom: 10,
                  }}
                >
                  No chats found!
                </div>
              )}

              <MDBox
                mx={page > 1 || chats.length === 10 ? 2 : 0}
                my={page > 1 || chats.length === 10 ? 0 : null}
                py={page > 1 || chats.length === 10 ? 3 : 0}
                px={page > 1 || chats.length === 10 ? 2 : 0}
                variant="gradient"
                borderRadius="lg"
                align={"center"}
              >
                {page === 1 ? null : (
                  <MDButton
                    onClick={() => {
                      setPage(page - 1);
                    }}
                    variant="outlined"
                    color="secondary"
                  >
                    Previous Page
                  </MDButton>
                )}{" "}
                &nbsp; &nbsp;
                {chats.length === 10 ? (
                  <MDButton
                    onClick={() => {
                      setPage(page + 1);
                    }}
                    variant="outlined"
                    color="secondary"
                  >
                    Next Page
                  </MDButton>
                ) : null}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Tables;
