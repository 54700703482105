import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import _ from "lodash";
import addIcon from "../../assets/images/add_icon.png";
import trash_icon from "../../assets/images/trash_icon.png";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { pascalCase } from "pascal-case";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { useNavigate } from "react-router-dom";
import Divider from "@mui/material/Divider";
import MuiDialog from "components/MuiDialog";
import MDSnackbar from "components/MDSnackbar";

import Services from "../../services";
import { Accordion } from "react-bootstrap";
import { Button, Switch } from "@mui/material";
import { LANGUAGE_SCREEN_DIALOG_MODES, LANGUAGE_STATUS } from "../../constants";

function Languages() {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [rowsBackup, setRowsBackup] = useState([]);
  const [searchItem, setSearchItem] = useState("");
  const [isShowDynamicDialog, setIsShowDynamicDialog] = useState(false);
  const [isShowStaticDialog, setIsShowStaticDialog] = useState(false);
  const [editDialogTitle, setEditDialogTitle] = useState("");
  const [keyword, setKeyword] = useState("");
  const [keywordId, setKeywordId] = useState("");
  const [keywordKeys, setKeywordKeys] = useState([]);
  const [keywordKeyValues, setKeywordKeyValues] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [dialogMode, setDialogMode] = useState("");
  const [isAddLanguage, setIsAddLanguage] = useState(false);
  const [language, setLanguage] = useState("");
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [isSuccess, setIsSuccess] = useState(true);
  const [successMessage, setSuccessMessage] = useState(true);
  const [isFailure, setIsFailure] = useState(false);
  const [failureMessage, setFailureMessage] = useState(false);
  const [activeKey, setActiveKey] = useState(null);
  const [languageToDelete, setLanguageToDelete] = useState(null);
  const [keywordToDelete, setKeywordToDelete] = useState(null);
  const [newKeywordData, setNewKeywordData] = useState({ key: "", mapping: {} });
  const [columns] = useState([
    { Header: "#", accessor: "serialNo", width: "5%", align: "left" },
    { Header: "Key", accessor: "key", width: "30%", align: "center" },
    { Header: "Value (English)", accessor: "engValue", width: "30%", align: "center" },
    { Header: "Actions", accessor: "actions", width: "15%", align: "center" },
  ]);

  const { LANGUAGE, DELETE_LANGUAGE, LANGUAGE_MAPPING, NEW_KEYWORD, DELETE_KEYWORD } =
    LANGUAGE_SCREEN_DIALOG_MODES;

  const getNotificationColor = () => {
    let message = "";

    if (isSuccess) {
      message = "success";
    } else if (isFailure) {
      message = "error";
    }

    return message;
  };

  const getNotificationTitle = () => {
    let message = "";

    if (isSuccess) {
      message = "Success";
    } else if (isFailure) {
      message = "Error";
    }

    return message;
  };

  const validateToken = () => {
    if (!localStorage.getItem("token")) {
      navigate("/authentication/sign-in", { replace: true });
    }
  };

  const populateDialog = (focusedItem) => {
    const keyword = Object.keys(focusedItem)[1];
    const keywordKeys = Object.keys(focusedItem[keyword]);
    const keywordKeyValues = Object.values(focusedItem[keyword]);

    setLanguages((previousState) => {
      previousState.forEach((language) => {
        if (!keywordKeys.includes(language.languageName)) {
          keywordKeys.push(language.languageName);
          keywordKeyValues.push("");
        }
      });

      return previousState;
    });

    setEditDialogTitle(() => keyword);
    setKeyword(() => keyword);
    setKeywordId(() => focusedItem._id);
    setKeywordKeys(() => keywordKeys);
    setKeywordKeyValues(() => keywordKeyValues);

    const values = [];

    keywordKeys.forEach((key) => {
      values.push(focusedItem[keyword][key]);
    });

    setKeywordKeyValues(values);
  };

  const getLanguageMappings = async () => {
    setIsLoading(true);

    try {
      const { data } = await Services.getLanguageMappingsList();

      const mappedData = data.map((item, index) => {
        const keysArray = Object.keys(item);
        const valuesArray = Object.values(item);

        return {
          serialNo: index + 1,
          key: keysArray[1],
          engValue: valuesArray[1]["ENGLISH"],
          value: valuesArray[1],
          actions: (
            <div
              style={{
                width: 250,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <MDButton
                onClick={() => {
                  setIsShowDynamicDialog(true);
                  setDialogMode(LANGUAGE_SCREEN_DIALOG_MODES.LANGUAGE_MAPPING);
                  populateDialog(item);
                }}
                variant="outlined"
                color="info"
              >
                View/Edit
              </MDButton>

              <MDButton
                onClick={() => {
                  setDialogMode(LANGUAGE_SCREEN_DIALOG_MODES.DELETE_KEYWORD);
                  setKeywordToDelete(item);
                  setIsShowDynamicDialog(true);
                }}
                variant="outlined"
                color="error"
              >
                Delete
              </MDButton>
            </div>
          ),
        };
      });

      setRows(
        mappedData.map((item) => {
          return {
            ...item,
            key: <div style={{ width: 300 }}>{item.key}</div>,
            engValue: <div style={{ width: 300 }}>{item.engValue}</div>,
          };
        })
      );
      setRowsBackup(mappedData);
    } catch (err) {
      setIsSuccess(false);
      setIsFailure(true);
      setFailureMessage(err.message);
      setIsShowPopup(true);
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  };

  const getSupportedLanguagesList = async () => {
    setIsLoading(true);

    try {
      const {
        data: { data: languages },
      } = await Services.getSupportedLanguages();

      setLanguages(languages);
    } catch (err) {
      setIsSuccess(false);
      setIsFailure(true);
      setSuccessMessage(err.message);
      setIsShowPopup(true);
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  };

  const getSearchedItems = (query) => {
    query = query.toLowerCase();

    const languageMappings = rowsBackup;

    setRows(
      languageMappings
        .filter((item) => {
          const valuesArray = Object.values(item.value);

          const getSearchedValue = (query) => {
            let result = false;

            valuesArray.forEach((item) => {
              if (item.toLowerCase().includes(query)) {
                result = true;
              }
            });

            return result;
          };

          return getSearchedValue(query) || item.key.toLowerCase().includes(query);
        })
        .map((item) => {
          return {
            ...item,
            key: <div style={{ width: 300 }}>{item.key}</div>,
            engValue: <div style={{ width: 300 }}>{item.engValue}</div>,
          };
        })
    );
  };

  useEffect(() => {
    validateToken();
  });

  useEffect(() => {
    getLanguageMappings();
    getSupportedLanguagesList();
  }, []);

  useEffect(() => {
    getSearchedItems(searchItem);
  }, [searchItem]);

  const handleCancel = () => {
    setIsShowDynamicDialog(false);
    setEditDialogTitle("");
  };

  const handleUpdate = async () => {
    setIsShowDynamicDialog(false);
    setEditDialogTitle("");
    setIsLoading(true);

    let mapping = {};

    keywordKeys.forEach((key, index) => {
      const temp = { ...mapping };

      temp[key] = keywordKeyValues[index];

      mapping = temp;
    });

    try {
      const {
        data: { message },
      } = await Services.updateLanguageMapping({ keywordId }, { key: keyword, mapping });

      setIsFailure(true);
      setIsSuccess(true);
      setSuccessMessage(message);

      getLanguageMappings();
    } catch (err) {
      setIsSuccess(false);
      setIsFailure(true);
      setFailureMessage(err.message);
    } finally {
      setIsLoading(false);

      setIsShowPopup(true);
    }
  };

  const handleAddKey = async () => {
    setIsShowDynamicDialog(false);
    setEditDialogTitle("");
    setIsLoading(true);

    try {
      const {
        data: { message },
      } = await Services.createLanguageMapping({
        key: newKeywordData.key,
        mapping: newKeywordData.mapping,
      });

      setIsFailure(true);
      setIsSuccess(true);
      setSuccessMessage(message);

      getLanguageMappings();
    } catch (err) {
      setIsSuccess(false);
      setIsFailure(true);
      setFailureMessage(err.message);
    } finally {
      setIsLoading(false);

      setIsShowPopup(true);
    }
  };

  const handleChange = (index, e) => {
    let temp = [...keywordKeyValues];

    temp[index] = e.target.value;

    setKeywordKeyValues(() => temp);
  };

  const handleAdd = () => {
    setLanguage("");
    setIsAddLanguage(true);
    setActiveKey("new-language-accordion");
  };

  const handleCross = () => {
    setLanguage("");
    setIsAddLanguage(false);
    setActiveKey(null);
  };

  const handleCheck = async () => {
    setIsAddLanguage(false);
    setIsLoading(true);
    try {
      const {
        data: { message },
      } = await Services.addNewLanguage({ languageName: language });

      setIsFailure(false);
      setIsSuccess(true);
      setSuccessMessage(message);

      getSupportedLanguagesList();
    } catch (err) {
      setIsSuccess(false);
      setIsFailure(true);
      setFailureMessage(err.message);
    } finally {
      setIsLoading(false);
      setLanguage("");
      setIsShowPopup(true);
    }
  };

  const handleTrash = async (languageName) => {
    setDialogMode(LANGUAGE_SCREEN_DIALOG_MODES.DELETE_LANGUAGE);
    setIsShowDynamicDialog(true);
    setLanguageToDelete(() => languageName);
  };

  const handleDeleteLanguage = async () => {
    setIsLoading(true);
    setIsShowDynamicDialog(false);

    try {
      const {
        data: { message },
      } = await Services.deleteLanguage({ languageName: languageToDelete });

      setIsFailure(false);
      setIsSuccess(true);
      setSuccessMessage(message);

      getSupportedLanguagesList();
    } catch (err) {
      setIsSuccess(false);
      setIsFailure(true);
      setSuccessMessage(err.message);
    } finally {
      setLanguageToDelete(null);
      setIsLoading(false);
      setIsShowPopup(true);
    }
  };

  const handleDeleteKeyword = async () => {
    setIsLoading(true);
    setIsShowDynamicDialog(false);

    try {
      const {
        data: { message },
      } = await Services.deleteLanguageMapping({ languageMappingId: keywordToDelete._id });

      setIsFailure(false);
      setIsSuccess(true);
      setSuccessMessage(message);

      getLanguageMappings();
    } catch (err) {
      setIsSuccess(false);
      setIsFailure(true);
      setFailureMessage(err.message);
    } finally {
      setLanguageToDelete(null);
      setIsLoading(false);
      setIsShowPopup(true);
    }
  };

  const getTitle = () => {
    switch (dialogMode) {
      case LANGUAGE:
        return (
          <div>
            <Grid style={{ padding: 10, alignItems: "center" }} container spacing={2}>
              <Grid item xs={12}>
                <div style={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
                  <h4 style={{ padding: 0, margin: 0 }}>Languages</h4>
                  {isAddLanguage ? null : (
                    <img
                      onClick={handleAdd}
                      style={{ padding: 0, margin: 0, marginLeft: 5, cursor: "pointer" }}
                      width={20}
                      height={20}
                      src={addIcon}
                      alt=""
                    />
                  )}
                </div>
              </Grid>
              <Grid item xs={12}>
                <Accordion style={{ alignItems: "center" }} activeKey={activeKey}>
                  <Accordion.Item
                    eventKey="new-language-accordion"
                    style={{ borderRadius: 15, marginBottom: 30 }}
                  >
                    <Accordion.Body style={{ alignItems: "center" }}>
                      <Grid style={{ padding: 0, alignItems: "center" }} container spacing={2}>
                        <Grid style={{ textAlign: "center" }} item xs={6}>
                          <h6 style={{ margin: 0 }}>New Language</h6>
                        </Grid>
                        <Grid style={{ textAlign: "center" }} item xs={6}>
                          <MDInput
                            placeholder={"enter here"}
                            onChange={(e) => setLanguage(e.target.value)}
                            value={language}
                            size="small"
                          ></MDInput>
                        </Grid>
                        <Grid style={{ textAlign: "right", paddingRight: 45 }} item xs={12}>
                          <Button style={{ padding: 0 }} onClick={handleCross}>
                            Cancel
                          </Button>
                          <Button
                            style={{ padding: 0 }}
                            onClick={() => {
                              if (language) {
                                handleCheck();
                              } else {
                                setIsSuccess(false);
                                setIsFailure(true);
                                setFailureMessage("Language name is required!");
                                setIsShowPopup(true);
                              }
                            }}
                          >
                            Add
                          </Button>
                        </Grid>
                      </Grid>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Grid>
            </Grid>
          </div>
        );

      case LANGUAGE_MAPPING:
        return (
          <div>
            <h4>{editDialogTitle}</h4>
          </div>
        );

      case DELETE_LANGUAGE:
      case DELETE_KEYWORD:
        return (
          <div>
            <h4>{"Alert!"}</h4>
          </div>
        );

      case NEW_KEYWORD:
        return (
          <div>
            <h4>{"New Keyword"}</h4>
          </div>
        );
    }
  };

  const updateLanguageStatus = async (index, status) => {
    try {
      const {
        data: { message },
      } = await Services.updateLanguageStatus({
        languageName: languages[index]["languageName"],
        status,
      });

      setIsFailure(false);
      setIsSuccess(true);
      setSuccessMessage(message);

      getSupportedLanguagesList();
    } catch (err) {
      setIsSuccess(false);
      setIsFailure(true);
      setSuccessMessage(err.message);
    } finally {
      setIsShowPopup(true);
    }
  };

  const handleSwitchChange = (e, index) => {
    const status = e.target.checked ? "ACTIVE" : "INACTIVE";

    setLanguages((previousState) => {
      const temp = [...previousState];

      temp[index]["status"] = status;

      return temp;
    });

    updateLanguageStatus(index, status);
  };

  const getContent = () => {
    switch (dialogMode) {
      case LANGUAGE:
        return (
          <div>
            <Grid
              style={{ padding: 10, alignItems: "center", textAlign: "center" }}
              container
              spacing={2}
            >
              {languages.map((item, index) => (
                <>
                  <Grid item xs={6}>
                    <h6 style={{ margin: 0 }}>{item.languageName}</h6>
                  </Grid>
                  <Grid item xs={3}>
                    <Switch
                      key={index}
                      color={"primary"}
                      checked={languages[index]["status"] === LANGUAGE_STATUS.ACTIVE ? true : false}
                      onChange={(e) => handleSwitchChange(e, index)}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <img
                      style={{ cursor: "pointer" }}
                      onClick={() => handleTrash(item.languageName)}
                      width={20}
                      height={20}
                      src={trash_icon}
                      alt=""
                    />{" "}
                  </Grid>
                </>
              ))}
            </Grid>
          </div>
        );

      case LANGUAGE_MAPPING:
        return (
          <div>
            <Grid style={{ padding: 0, alignItems: "center", textAlign: "center" }} container>
              <Grid item xs={5}>
                Keyword
              </Grid>
              <Grid item xs={7}>
                <MDInput
                  size="small"
                  onChange={(e) => {
                    setKeyword(() => e.target.value);
                  }}
                  value={keyword}
                />
              </Grid>
            </Grid>
            <Divider />
            {keywordKeys && keywordKeyValues
              ? keywordKeys.map((key, index) => {
                  return (
                    <Grid
                      key={index}
                      style={{
                        padding: 0,
                        paddingBottom: 5,
                        alignItems: "center",
                        textAlign: "center",
                      }}
                      container
                    >
                      <Grid style={{ padding: 0 }} item xs={5}>
                        {key}
                      </Grid>
                      <Grid style={{ padding: 0 }} item xs={7}>
                        <MDInput
                          size="small"
                          onChange={(e) => handleChange(index, e)}
                          value={keywordKeyValues[index]}
                        />
                      </Grid>
                    </Grid>
                  );
                })
              : null}
          </div>
        );

      case DELETE_LANGUAGE:
        return <div>Are you sure, You want to delete this language?</div>;

      case NEW_KEYWORD:
        return (
          <div>
            <Grid style={{ padding: 0, alignItems: "center", textAlign: "center" }} container>
              <Grid style={{ padding: 0 }} item xs={5}>
                Keyword
              </Grid>
              <Grid style={{ padding: 0 }} item xs={7}>
                <MDInput
                  size="small"
                  onChange={(e) => {
                    setNewKeywordData((previousState) => {
                      const updatedState = { ...previousState };

                      updatedState.key = e.target.value;

                      return updatedState;
                    });
                  }}
                />
              </Grid>
            </Grid>
            <Divider />
            {languages
              ? languages.map((item, index) => {
                  return (
                    <Grid
                      key={index}
                      style={{
                        padding: 0,
                        paddingBottom: 5,
                        alignItems: "center",
                        textAlign: "center",
                      }}
                      container
                    >
                      <Grid style={{ padding: 0 }} item xs={5}>
                        {pascalCase(item.languageName)}
                      </Grid>
                      <Grid style={{ padding: 0 }} item xs={7}>
                        <MDInput
                          size="small"
                          onChange={(e) => {
                            setNewKeywordData((previousState) => {
                              const updatedState = { ...previousState };

                              updatedState.mapping[item.languageName] = e.target.value;

                              return updatedState;
                            });
                          }}
                          // value={keywordKeyValues[index]}
                        />
                      </Grid>
                    </Grid>
                  );
                })
              : null}
          </div>
        );

      case DELETE_KEYWORD:
        return <div>Are you sure, You want to delete this keyword?</div>;
    }
  };

  const getConfirmTitle = () => {
    switch (dialogMode) {
      case LANGUAGE:
        return "Done";

      case LANGUAGE_MAPPING:
        return "Update";

      case DELETE_LANGUAGE:
      case DELETE_KEYWORD:
        return "Confirm";

      case NEW_KEYWORD:
        return "Add";
    }
  };

  const getCancelTitle = () => {
    return "Cancel";
  };

  const onConfirm = () => {
    setIsShowDynamicDialog(false);

    switch (dialogMode) {
      case LANGUAGE:
        return "Done";

      case LANGUAGE_MAPPING:
        handleUpdate();
        break;

      case DELETE_LANGUAGE:
        handleDeleteLanguage();
        break;

      case NEW_KEYWORD:
        handleAddKey();
        break;

      case DELETE_KEYWORD:
        handleDeleteKeyword();
        break;
    }
  };

  return (
    <DashboardLayout>
      {/* dynamic */}
      <MuiDialog
        isShowDialog={isShowDynamicDialog}
        dialogTitle={getTitle()}
        dialogContent={getContent()}
        confirmTitle={getConfirmTitle()}
        cancelTitle={getCancelTitle()}
        onCancel={handleCancel}
        onConfirm={onConfirm}
        isDoubleAction={
          [
            LANGUAGE_SCREEN_DIALOG_MODES.LANGUAGE,
            LANGUAGE_SCREEN_DIALOG_MODES.DELETE_LANGUAGE,
            LANGUAGE_SCREEN_DIALOG_MODES.LANGUAGE_MAPPING,
            LANGUAGE_SCREEN_DIALOG_MODES.NEW_KEYWORD,
            LANGUAGE_SCREEN_DIALOG_MODES.DELETE_KEYWORD,
          ].includes(dialogMode)
            ? true
            : false
        }
      />

      <MDSnackbar
        color={getNotificationColor()}
        icon="check"
        title={getNotificationTitle()}
        content={isSuccess ? successMessage : failureMessage}
        dateTime="now"
        open={isShowPopup}
        onClose={() => setIsShowPopup(false)}
        close={() => setIsShowPopup(false)}
        bgWhite
      />

      <DashboardNavbar
        value={searchItem}
        getSearchItem={(value) => {
          setSearchItem(value);
        }}
      />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <div className="d-flex flex-row justify-content-between">
                  <MDTypography variant="h6" color="white">
                    Language Mappings
                    {isLoading ? (
                      <span
                        style={{ marginLeft: 5 }}
                        className="spinner-border spinner-border-sm ml-1"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : null}
                  </MDTypography>
                  <div>
                    <MDButton
                      className="w-15 align-self-end"
                      onClick={() => {
                        setIsShowDynamicDialog(true);
                        setDialogMode(LANGUAGE_SCREEN_DIALOG_MODES.NEW_KEYWORD);
                        setIsAddLanguage(false);
                        setNewKeywordData({ key: "", mapping: {} });
                      }}
                      variant="gradient"
                      color="success"
                    >
                      New Key
                    </MDButton>
                    <MDButton
                      style={{ marginLeft: 5 }}
                      className="w-15 align-self-end ml-2"
                      onClick={() => {
                        setIsShowDynamicDialog(true);
                        setDialogMode(LANGUAGE_SCREEN_DIALOG_MODES.LANGUAGE);
                        setIsAddLanguage(false);
                        setActiveKey(null);
                      }}
                      variant="gradient"
                      color="secondary"
                    >
                      View Languages
                    </MDButton>
                  </div>
                </div>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{
                    columns,
                    rows,
                  }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Languages;
