import React, { useEffect, useMemo, useRef, useState } from "react";
import Receiver from "./components/Receiver";
import Sender from "./components/Sender";
import SendMessage from "./components/SendMessage";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Footer from "../../examples/Footer";
import MDBox from "components/MDBox";
import { useLocation } from "react-router-dom";
import Services from "../../services";

const TestUserChat = () => {
  const location = useLocation();

  const chatData = location.state.chatData;

  const user = location.state.user;

  const [chatList, setChatList] = useState([]);

  const [beforeTime, setBeforeTime] = useState();

  useEffect(() => {
    messagesList();
  }, []);

  useEffect(() => {
    const refreshChatInterval = setInterval(() => messagesList(beforeTime), 10000);
    return () => clearInterval(refreshChatInterval);
  }, [beforeTime]);

  const messagesList = async (before = "") => {
    if (before) {
      before = `?before=${before}`;
    }

    const res = await Services.getChatList(chatData.otherUser._id, chatData._id, before);
    
    if (res.status == 200) {
      let list = res.data.reverse();
      if (before) {
        list = [...list, ...chatList];
      }
      setChatList(list);
      if (!before) {
        scrollToBottom();
      }
    }
  };

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const childComponent = document.querySelector("#child-component");

  if (childComponent !== null) {
    childComponent.onscroll = () => {
      if (childComponent.scrollTop === 0) {
        console.log("User has scrolled to the top of the child div");
        messagesList(chatList[0].createdAtTimestamp);
        setBeforeTime(chatList[0].createdAtTimestamp);
      }
    };
  } else {
    console.log('Element with ID "child-component" not found');
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div className="d-flex align-items-center justify-content-between pt-2 pb-4 mx-3">
        <div className="d-flex">
          <img
            style={{ borderRadius: 70 }}
            height={55}
            width={55}
            className="me-2"
            src={chatData.profilePictureToShow}
            alt=""
          />
          <p className="mb-0 me-3 fs-2 fw-bold">{`${chatData.chatNameToShow}`}</p>
        </div>
        <div className="d-flex">
          <p className="mb-0 me-3 fs-2 fw-bold">{`${user.firstName} ${user.lastName}`}</p>
          <img
            style={{ borderRadius: 70 }}
            height={55}
            width={55}
            className="me-2"
            src={user.profilePicture}
            alt=""
          />
        </div>
      </div>
      <div style={{ height: "70vh", overflow: "auto" }} className="p-4" id="child-component">
        {chatList.map((i, e) => {
          if (chatData.otherUser._id == i.createdBy._id) {
            return <Receiver data={i} />;
          }
          return <Sender data={i} />;
        })}
        <div ref={messagesEndRef} />
      </div>
      <div style={{ position: "absolute", bottom: 0, width: "120%" }} className="row mt-auto">
        <SendMessage
          scrollToBottom={scrollToBottom}
          refreshChat={messagesList}
          chatId={chatData._id}
          userId={user._id}
        />
      </div>
    </DashboardLayout>
  );
};

export default TestUserChat;
