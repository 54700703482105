import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import reportsLineChartData from "layouts/app-usage-dashboard/data/reportsLineChartData";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import moment from "moment";
import DataTable from "examples/Tables/DataTable";
import RefreshIcon from "@mui/icons-material/Refresh";

import Services from "../../services";

function Dashboard() {
  const navigate = useNavigate();

  const [dashboardData, setDashboardData] = useState({});
  const [fromDate, setFromDate] = useState(moment("22 september 2022").format("L"));
  const [toDate, setToDate] = useState(moment().format("L"));
  const [isLoading, setIsLoading] = useState(false);

  const [columns] = useState([
    { Header: "Total Swipes", accessor: "totalSwipes", width: "5%", align: "center" },
    { Header: "Total Matches", accessor: "totalMatches", width: "5%", align: "center" },
    { Header: "Total Conversations", accessor: "totalConversations", width: "5%", align: "center" },
    { Header: "Total Messages", accessor: "totalMessages", width: "5%", align: "center" },
  ]);

  const calculateAppUsageStats = async () => {
    try {
      // await Services.calculateAppUsageStats();
      // console.log("🚀 ~ file: index.js:31 ~ calculateAppUsageStats");
      get();
    } catch (err) {
      console.log(err);
    }
  };

  const validateToken = () => {
    if (!localStorage.getItem("token")) {
      navigate("/authentication/sign-in", { replace: true });
    }
  };

  useEffect(() => {
    validateToken();
  });

  useEffect(() => {
    get();
  }, []);

  const get = async () => {
    try {
      setIsLoading(true);

      const body = {};

      if (fromDate) {
        body.fromDate = fromDate;
      }

      if (toDate) {
        body.toDate = toDate;
      }

      const res = await Services.getInteractionStats(body);

      setDashboardData(res.data);

      console.log("🚀 ~ file: index.js:37 ~ get ~ res:", res.data);
    } catch (err) {
      console.log("Error occured while getting interaction stats:", err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleApply = () => {
    get();
  };

  const handleClearAll = () => {
    setFromDate("");
    setToDate("");
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div>
        <Accordion>
          <Accordion.Item style={{ borderRadius: 15 }} eventKey="wl-accordion">
            <Accordion.Header>Filters</Accordion.Header>
            <Accordion.Body>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <MDTypography fontWeight="regular" variant="h6" color="dark">
                    Date Range
                  </MDTypography>
                </Grid>
                <Grid item xs={2}>
                  <MDInput
                    onChange={(e) => {
                      setFromDate(e.target.value);
                      setPage(1);
                    }}
                    value={fromDate}
                    label="From"
                    onFocus={(e) => (e.target.type = "date")}
                    onBlur={(e) => (e.target.type = "text")}
                  />
                </Grid>

                <Grid item xs={2}>
                  <MDInput
                    onChange={(e) => {
                      setToDate(e.target.value);
                      setPage(1);
                    }}
                    value={toDate}
                    label="To"
                    onFocus={(e) => (e.target.type = "date")}
                    onBlur={(e) => (e.target.type = "text")}
                  />
                </Grid>

                <Grid item xs={8}></Grid>
                <Grid item xs={5}>
                  <MDTypography variant="button" fontWeight="light" color="text">
                    Note: Data recording started from 22 september 2022.
                  </MDTypography>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid className="ms-auto" item xs={2}>
                  <MDButton onClick={handleClearAll} variant="outlined" color="secondary" fullWidth>
                    Clear All
                  </MDButton>
                </Grid>
                <Grid item xs={2}>
                  <MDButton
                    onClick={() => {
                      handleApply();
                    }}
                    variant="gradient"
                    color="info"
                    fullWidth
                  >
                    Apply
                  </MDButton>
                </Grid>
              </Grid>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
              >
                <MDTypography variant="h6" color="white">
                  User Interaction Statistics
                  {isLoading ? (
                    <span
                      style={{ marginLeft: 5 }}
                      className="spinner-border spinner-border-sm ml-1"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : null}
                </MDTypography>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <MDButton
                    onClick={calculateAppUsageStats}
                    style={{ marginLeft: 10, color: "white", width: "50px" }}
                    variant="gradient"
                    color="secondary"
                    fullWidth
                  >
                    <RefreshIcon />
                  </MDButton>
                </div>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{
                    columns,
                    rows: Object.values(dashboardData) ? [dashboardData] : null,
                  }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
