import moment from "moment";
import React from "react";

const Receiver = ({ data }) => {
  const { text, createdAt, createdBy } = data;

  const bgLight = {
    backgroundColor: "#F3F4F6",
  };
  const bgLightGrey = {
    backgroundColor: "#F3F4F6",
  };
  const fsFourteen = {
    fontSize: "14px",
  };
  const poppinsFont = {
    fontFamily: "Poppins, sans-serif",
  };
  const radiusEight = {
    borderRadius: "8px",
  };
  return (
    <div className="row">
      <div style={{ maxWidth: "405px" }} className="col-auto me-auto mb-3">
        <div className="d-flex align-items-center py-2">
          <img
            style={{ borderRadius: 70 }}
            height={35}
            width={35}
            className="me-2"
            src={createdBy?.profilePicture}
            alt=""
          />
          <p className="mb-0 me-3" style={{ fontSize: "13px", fontWeight: 600 }}>
            {`${createdBy?.preferredName}`}
          </p>
          <p className="mb-0" style={{ fontSize: "12px", fontWeight: 400 }}>
            {moment(createdAt).format("lll")}
          </p>
        </div>
        <p
          style={{ ...bgLightGrey, ...fsFourteen, ...poppinsFont, ...radiusEight, whiteSpace: "pre-wrap" }}
          className="mb-0 py-2 ps-2 pe-1 border border-secondary"
        >
          {text}
        </p>
      </div>
    </div>
  );
};

export default Receiver;
