import { client } from "./axios";

const Services = {
  getTestUsers() {
    return client.get(`/admin/test-users`);
  },
  getTestUserChat(id) {
    return client.get(`/admin/test-users/${id}/chats`);
  },
  getSupportChats(query) {
    const { page = 1, unreadOnly } = query;
    let url = `/admin/support-chats?page=${page}`;

    if (unreadOnly) {
      url += `&unreadOnly=true`; // Add the unreadOnly filter to the URL
    }

    return client.get(url).catch((error) => {
      console.error("Error fetching support chats:", error);
      throw error; // rethrow to handle it in the component if necessary
    });
  },
  getSupportChatMessages(params, query) {
    const { chatId } = params;
    const { before } = query;

    return client.get(`/admin/support-chats/${chatId}/messages?before=${before}`);
  },
  getUserProfile(id) {
    return client.get(`/users/${id}`);
  },
  getCurrentUserProfile() {
    return client.get(`/users/me`);
  },
  getReferredUsersById(userId) {
    return client.get(`/users/referred-users?userId=${userId}`);
  },
  getAllWanderers(body) {
    return client.post(`/admin/get-users/all`, body);
  },
  postProfileView(id, body) {
    return client.post(`/admin/test-users/${id}/profile-views`, body);
  },
  postProfileLikes(id, body) {
    return client.post(`/admin/test-users/${id}/priority-likes`, body);
  },
  postDirectMessage(id, body) {
    return client.post(`/admin/test-users/${id}/direct-message`, body);
  },
  getWanderersByUserId(id) {
    return client.get(`/admin/test-users/${id}/wanderers`);
  },
  getInteractionRequests(id, page) {
    return client.get(`/admin/test-users/${id}/interaction-requests?page=${page}`);
  },
  refreshWanderers(id) {
    return client.post(`/admin/test-users/${id}/wanderers/list/refresh`);
  },
  createTestUser(body) {
    return client.post(`/admin/test-users`, body);
  },
  acceptRejectChat(id, data) {
    return client.patch(`/admin/test-users/${id}/direct-message`, data);
  },
  getChatList(userId, chatId, before) {
    return client.get(`admin/test-users/${userId}/chats/${chatId}/messages${before}`);
  },
  sendMessage(userId, chatId, text) {
    return client.post(`/admin/test-users/${userId}/chats/${chatId}/messages`, {
      text,
    });
  },
  sendMessageFromSupport(body) {
    return client.post(`/admin/support-chats/messages`, body);
  },
  calculateAppUsageStats() {
    return client.post(`/admin/calculate-app-usage-stats`);
  },
  getDashboardStats() {
    return client.post(`/admin/get-dashboard-stats`);
  },
  getAppUserStats(body) {
    return client.post(`/admin/get-app-user-stats`, body);
  },
  getAppUsageStats(body) {
    return client.post(`/admin/get-app-usage-stats`, body);
  },
  getInteractionStats(body) {
    return client.post(`/admin/get-interaction-stats`, body);
  },
  awardTrail(data) {
    return client.post(`/trial-award`, data);
  },
  postCampaign(data) {
    return client.post(`/campaign`, data);
  },
  getCampaign(fromDate, toDate) {
    return client.post(`/campaign/get-campaigns`, {
      filters: {
        fromDate: fromDate,
        toDate: toDate,
      },
    });
  },
  editCampaignStatus(id, data) {
    return client.patch(`/campaign/${id}`, data);
  },
  deleteCampaignStatus(id) {
    return client.delete(`/campaign/${id}`);
  },
  refreshTestUsers() {
    return client.get(`/admin/test-users/refresh-test-users`);
  },
  createCustomReferralCode(body) {
    return client.post(`/admin/assign-custom-referral-code`, body);
  },
  getCustomReferralCode(id) {
    return client.get(`/admin/users/${id}/custom-referral-codes`);
  },
  getCustomReferralCodeUsers(id) {
    return client.get(`/admin/custom-referral-codes/${id}`);
  },
  getAllUserEmailAddresses(body) {
    return client.post(`/admin/get-users-emails`, body);
  },
  getActiveUsers(params) {
    const { fromDate, toDate } = params;

    return client.get(`/admin/active-users?fromDate=${fromDate}&toDate=${toDate}`);
  },
  getActiveUsersCronDetails(params) {
    const { cronName } = params;

    return client.get(`/cron-details/last/${cronName}`);
  },
  getAppUsageCronDetails(params) {
    const { cronName } = params;

    return client.get(`/cron-details/last/${cronName}`);
  },
  updateUsersStatus(body) {
    return client.patch(`/admin/users/update-status`, body);
  },
  createLanguageMapping(body) {
    return client.post(`/language-mapping`, body);
  },
  getLanguageMappingsList() {
    return client.get(`/language-mapping`);
  },
  updateLanguageMapping(params, body) {
    const { keywordId } = params;

    return client.patch(`/language-mapping/${keywordId}`, body);
  },
  getSupportedLanguages() {
    return client.get(`/language-mapping/languages/supported-languages`);
  },
  addNewLanguage(body) {
    return client.post(`/language-mapping/languages`, body);
  },
  deleteLanguage(params) {
    const { languageName } = params;

    return client.delete(`/language-mapping/languages/${languageName}`);
  },
  deleteLanguageMapping(params) {
    const { languageMappingId } = params;

    return client.delete(`/language-mapping/${languageMappingId}`);
  },
  updateLanguageStatus(params) {
    const { languageName, status } = params;

    return client.patch(`/language-mapping/languages/${languageName}/${status}`);
  },
  getServerSettings() {
    return client.get(`/admin/server-settings`);
  },
  getServerSettingsByKey(params) {
    const { key } = params;

    return client.get(`/server-settings/${key}`);
  },
  updateServerSettings(body) {
    return client.patch(`/admin/server-settings`, body);
  },
  getHotspots() {
    return client.get(`/admin/hotspots/all`);
  },
  createHotspot({ body }) {
    return client.post(`/admin/hotspots`, body);
  },
  updateHotspot({ params, body }) {
    const { hotspotId } = params;

    return client.patch(`/admin/hotspots/${hotspotId}`, body);
  },
  updateSupportChat({params,body}){
    const { chatId } = params;

    return client.patch(`/admin/support-chats/${chatId}`, body);

  }
};

export default Services;
