import React, { useContext, useEffect, useState } from "react";
import Services from "../../../services";
import { SocketContext } from "services/SocketProvider";

const SendMessage = ({ userId, chatId, scrollToBottom }) => {
  const [message, setMessage] = useState("");
  const socket = useContext(SocketContext);

  const bgLight = {
    backgroundColor: "#F3F4F6",
  };


  const validateMessage = (msg) => {
    const trimmedMessage = msg.trim();
    return trimmedMessage ? trimmedMessage : null;
  };


  const sendMessage = async () => {

    const trimmedMessage = validateMessage(message)

    if (!trimmedMessage) return


    setMessage("");

    if (socket) {
      socket.emit("support-message", {
        text: message,
        chatId: chatId,
      });
    } else {
      console.error("Socket is not defined, cannot send message");
    }

    // try {
    //   await Services.sendMessageFromSupport({ chatId, text: message });

    //   await refreshChat();

    //   scrollToBottom();
    // } catch (err) {
    //   console.log(err);
    // }
  };

  return (
    <div className="col-xl-10 col-12 me-auto">
      <form
        onSubmit={(e) => {
          sendMessage();
          e.preventDefault();
        }}
        className="d-flex align-items-center py-3 px-4 "
      >
        <input
          style={{ ...bgLight }}
          type="text"
          id="text"
          onChange={(e) => setMessage(e.target.value)}
          className="form-control py-2 border border-secondary rounded-start-5 px-3 no-focus shadow-lg"
          placeholder="Start typing"
          value={message}
        />
        <button style={{ ...bgLight }} type="submit" className="btn border-chat no-focus">
          <img height={28} src="/images/chat/snd.png" alt="icon" className="pointer" />
        </button>
      </form>
    </div>
  );
};

export default SendMessage;
