import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Header from "layouts/profile/components/Header";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DataTable from "examples/Tables/DataTable";
import moment from "moment";
import MDButton from "components/MDButton";
import Services from "../../services";
import MDInput from "components/MDInput";
import MDSnackbar from "components/MDSnackbar";
import { Col, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import MuiDialog from "components/MuiDialog";

function Overview() {
  const navigate = useNavigate();

  const { id } = useParams();

  const [userDetails, setUserDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [showInputAwardTrail, setShowInputAwardTrail] = useState(false);
  const [awardTrailDays, setAwardTrailDays] = useState(0);

  const [isShowPopup, setIsShowPopup] = useState(false);
  const [isSuccess, setIsSuccess] = useState(true);
  const [popupMessage, setPopupMessage] = useState(true);

  const [isShowDialog, setIsShowDialog] = useState(false);

  const [placeOrTripColumns] = useState([
    { Header: "#", accessor: "serialNo", width: "5%", align: "left" },
    { Header: "Name", accessor: "name", width: "5%", align: "center" },
    { Header: "Status", accessor: "status", width: "5%", align: "center" },
    { Header: "Duration", accessor: "duration", width: "5%", align: "center" },
    { Header: "Updated At", accessor: "updated_At", width: "5%", align: "center" },
  ]);
  const [friendsColumns] = useState([
    { Header: "#", accessor: "serialNo", width: "5%", align: "left" },
    { Header: "Profile Picture", accessor: "profilePicture", width: "15%", align: "center" },
    { Header: "Name", accessor: "preferredName", width: "15%", align: "center" },
    { Header: "Connected At", accessor: "connectedAt", width: "15%", align: "center" },
    { Header: "Action", accessor: "action", width: "5%", align: "center" },
  ]);
  const [referredUsersColumns] = useState([
    { Header: "#", accessor: "serialNo", width: "5%", align: "left" },
    { Header: "Profile Picture", accessor: "profilePicture", width: "27%", align: "center" },
    { Header: "Name", accessor: "preferredName", width: "17%", align: "center" },
    { Header: "Joined At", accessor: "createdAt", width: "37%", align: "center" },
    { Header: "Action", accessor: "action", align: "center" },
  ]);
  const [userTrips, setUserTrips] = useState([]);
  const [userPlaces, setUserPlaces] = useState([]);

  const getUserFriends = () => {
    let friends = [];

    if (userDetails.contacts && userDetails.contacts.length) {
      friends = userDetails.contacts.map((c, index) => ({
        serialNo: index + 1,
        profilePicture: <img alt="dp" width={50} height={50} src={c.user.profilePicture} />,
        preferredName: c.user.preferredName,
        connectedAt: c.connectedAt,
        action: (
          <MDButton
            onClick={() => {
              navigate(`/profile/${c.user._id}`, { state: { userId: c.user._id } });
              setIsLoading(true);
            }}
            variant="outlined"
            color="info"
            fullWidth
          >
            View{" "}
            {isLoading && id === c.user?._id ? (
              <span
                style={{ marginLeft: 5 }}
                className="spinner-border spinner-border-sm ml-1"
                role="status"
                aria-hidden="true"
              ></span>
            ) : null}
          </MDButton>
        ),
      }));
    }

    return friends;
  };

  const getUserReferredUsers = () => {
    let referredUsers = [];

    if (userDetails.referredUsers && userDetails.referredUsers.length) {
      referredUsers = userDetails.referredUsers.map((r, index) => ({
        serialNo: index + 1,
        profilePicture: <img alt="dp" width={50} height={50} src={r.profilePicture} />,
        preferredName: r.preferredName,
        createdAt: r.createdAt,
        action: (
          <MDButton
            onClick={() => {
              navigate(`/profile/${r._id}`, { state: { userId: r._id } });
              setIsLoading(true);
            }}
            variant="outlined"
            color="info"
            fullWidth
          >
            View
            {isLoading && id === r._id ? (
              <span
                style={{ marginLeft: 5 }}
                className="spinner-border spinner-border-sm ml-1"
                role="status"
                aria-hidden="true"
              ></span>
            ) : null}
          </MDButton>
        ),
      }));
    }

    return referredUsers;
  };

  const getUser = async () => {
    try {
      const { data: user } = await Services.getUserProfile(id);

      const { data: referredUsers } = await Services.getReferredUsersById(id);
      user.referredUsers = referredUsers;

      setUserDetails(user);

      const { userTrips: trips, userPlaces: places } = user;

      const mappedTrips = trips.map((trip, index) => ({
        serialNo: index + 1,
        name: trip.location.name,
        status: trip.status,
        duration: `From ${moment(trip.arrivalAt).format("ll")}
      To ${moment(trip.departureAt).format("ll")}`,
        updated_At: moment(trip.lastUpdatedAt).format("ll"),
      }));

      const mappedPlaces = places.map((trip, index) => ({
        serialNo: index + 1,
        name: trip.location.name,
        status: trip.status,
        duration: `From ${moment(trip.arrivalAt).format("ll")}
      To ${moment(trip.departureAt).format("ll")}`,
        updated_At: moment(trip.lastUpdatedAt).format("ll"),
      }));

      setUserTrips(mappedTrips);
      setUserPlaces(mappedPlaces);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
      window.scrollTo(0, 0);
    }
  };

  const getCurrentUser = async () => {
    try {
      const { data: currentUser } = await Services.getCurrentUserProfile();

      const { data: referredUsers } = Services.getReferredUsersById(currentUser._id);

      currentUser.referredUsers = referredUsers;

      setUserDetails(currentUser);

      const { userTrips: trips, userPlaces: places } = currentUser;

      const mappedTrips = trips.map((trip, index) => ({
        serialNo: index + 1,
        name: trip.location.name,
        status: trip.status,
        duration: `From ${moment(trip.arrivalAt).format("ll")}
      To ${moment(trip.departureAt).format("ll")}`,
        updated_At: moment(trip.lastUpdatedAt).format("ll"),
      }));

      const mappedPlaces = places.map((trip, index) => ({
        serialNo: index + 1,
        name: trip.location.name,
        status: trip.status,
        duration: `From ${moment(trip.arrivalAt).format("ll")}
      To ${moment(trip.departureAt).format("ll")}`,
        updated_At: moment(trip.lastUpdatedAt).format("ll"),
      }));

      setUserTrips(mappedTrips);
      setUserPlaces(mappedPlaces);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
      window.scrollTo(0, 0);
    }
  };

  const validateToken = () => {
    if (!localStorage.getItem("token")) {
      navigate("/authentication/sign-in", { replace: true });
    }
  };

  const awardTrail = async () => {
    try {
      const {
        data: { message },
      } = await Services.awardTrail({
        userId: userDetails._id,
        trialDuration: awardTrailDays,
      });

      setShowInputAwardTrail(false);
      setIsSuccess(true);
      setPopupMessage(message);

      getUser();
    } catch (err) {
      setIsSuccess(false);
      setPopupMessage(err.message);
    } finally {
      setIsShowPopup(true);
    }
  };

  useEffect(() => {
    validateToken();
  }, []);

  useEffect(() => {
    if (id) {
      getUser();
    } else {
      getCurrentUser();
    }
  }, [id]);

  const updateUserStatus = async () => {
    try {
      const {
        data: { message },
      } = await Services.updateUsersStatus({
        status: userDetails?.userStatus === "ACTIVE" ? "SUSPENDED" : "ACTIVE",
        userIds: [id],
      });

      setIsSuccess(true);
      setPopupMessage(message);

      getUser();
    } catch (err) {
      setIsSuccess(false);
      setPopupMessage(err.message);
    } finally {
      setIsShowPopup(true);
    }
  };

  const getTitle = () => {
    return <div>{`${userDetails?.userStatus === "ACTIVE" ? "Suspend" : "Activate"} User`}</div>;
  };

  const getContent = () => {
    return (
      <div>{`Are you sure, you want to ${
        userDetails?.userStatus === "ACTIVE" ? "Suspend" : "Activate"
      } this user?`}</div>
    );
  };

  const getNotificationColor = () => {
    let message = "";

    if (isSuccess) {
      message = "success";
    } else {
      message = "error";
    }

    return message;
  };

  const getNotificationTitle = () => {
    let message = "";

    if (isSuccess) {
      message = "Success";
    } else {
      message = "Error";
    }

    return message;
  };

  return (
    <DashboardLayout>
      <MuiDialog
        isShowDialog={isShowDialog}
        dialogTitle={getTitle()}
        dialogContent={getContent()}
        cancelTitle={"No"}
        onCancel={() => {
          setIsShowDialog(false);
        }}
        confirmTitle={"Yes"}
        onConfirm={() => {
          setIsShowDialog(false);
          updateUserStatus();
        }}
      />

      <DashboardNavbar />
      <MDBox mb={2} />
      <Header
        profilePicture={userDetails.profilePicture}
        preferredName={userDetails.preferredName}
        jobTitle={userDetails.jobTitle}
        onPressAwardTrail={() => setShowInputAwardTrail(!showInputAwardTrail)}
        onPressCustomReferralCodes={() => navigate(`/profile/${id}/custom-referral-codes`)}
        onPressDisableUser={() => {
          setIsShowDialog(true);
        }}
        isActiveUser={userDetails?.userStatus === "ACTIVE" ? true : false}
      >
        <MDBox mt={5} mb={3}>
          {/* <Grid item xs={12} md={6} xl={4}>
              <PlatformSettings />
            </Grid> */}
          {/* <Divider /> */}
          {/* <MDTypography
                onClick={() => {
                  setCollapisbles({ ...collapsibles, personalInfo: !collapsibles.personalInfo });
                }}
                style={{ cursor: "pointer" }}
                variant="h6"
                fontWeight="medium"
              >
                Personal Information :
              </MDTypography> */}
          {/* <Collapse in={collapsibles.personalInfo}> */}
          <h4> Personal Information</h4>
          <Row>
            <Col className="col-md-12">
              <Row className="row-cols-3">
                <Col>
                  <MDInput
                    className="w-100"
                    disabled
                    value={`${userDetails._id}`}
                    type="text"
                    label="User Id "
                    variant="outlined"
                  />
                </Col>
                <Col>
                  <MDInput
                    className="w-100"
                    disabled
                    value={`${userDetails.firstName || "(Not provided)"}`}
                    type="text"
                    label="First Name"
                    variant="outlined"
                  />
                </Col>
                <Col>
                  <MDInput
                    className="w-100"
                    disabled
                    value={`${userDetails.lastName || "(Not provided)"}`}
                    type="text"
                    label="Last Name"
                    variant="outlined"
                  />
                </Col>
                <Col>
                  <MDInput
                    className="w-100 mt-4"
                    disabled
                    value={`${userDetails.firstName} ${userDetails.lastName || "(Not provided)"}`}
                    type="text"
                    label="Full Name"
                    variant="outlined"
                  />
                </Col>
                <Col>
                  <MDInput
                    className="w-100 mt-4"
                    disabled
                    value={`${userDetails.gender || "(Not provided)"}`}
                    type="text"
                    label="Gender"
                    variant="outlined"
                  />
                </Col>
                <Col>
                  <MDInput
                    className="w-100 mt-4"
                    disabled
                    value={`${userDetails.phoneNumber || "(Not provided)"}`}
                    type="text"
                    label="Phone Number"
                    variant="outlined"
                  />
                </Col>
                <Col>
                  <MDInput
                    className="w-100 mt-4"
                    disabled
                    value={`${userDetails.email || "(Not provided)"}`}
                    type="text"
                    label="Email"
                    variant="outlined"
                  />
                </Col>
                <Col>
                  <MDInput
                    className="w-100 mt-4"
                    disabled
                    value={`${userDetails.age || "(Not provided)"}`}
                    type="text"
                    label="Age"
                    variant="outlined"
                  />
                </Col>
                <Col>
                  <MDInput
                    className="w-100 mt-4"
                    disabled
                    value={`${moment(userDetails.dateOfBirth).format("LL") || "(Not provided)"}`}
                    type="text"
                    label="Date of Birth"
                    variant="outlined"
                  />
                </Col>
                <Col>
                  <MDInput
                    className="w-100 mt-4"
                    disabled
                    value={`${
                      (userDetails.homeLocation && userDetails.homeLocation.name) ||
                      "(Not provided)"
                    }`}
                    type="text"
                    label="Home Location"
                    variant="outlined"
                  />
                </Col>
                {/* </Collapse> */}

                {/* <ProfileInfoCard
                title="profile information"
                description={userDetails.aboutMe}
                info={{
                }}
                social={
                  [
                    // {
                    //   link: "https://www.facebook.com/CreativeTim/",
                    //   icon: <FacebookIcon />,
                    //   color: "facebook",
                    // },
                    // {
                    //   link: "https://twitter.com/creativetim",
                    //   icon: <TwitterIcon />,
                    //   color: "twitter",
                    // },
                    // {
                    //   link: "https://www.instagram.com/creativetimofficial/",
                    //   icon: <InstagramIcon />,
                    //   color: "instagram",
                    // },
                  ]
                }
                action={{ route: "", tooltip: "Edit Profile" }}
                shadow={false}
              /> */}
                {/* <MDTypography
                onClick={() => {
                  setCollapisbles({ ...collapsibles, profileInfo: !collapsibles.profileInfo });
                }}
                style={{ cursor: "pointer" }}
                variant="h6"
                fontWeight="medium"
              >
                Profile Information :
              </MDTypography> */}
                {/* <Collapse in={collapsibles.profileInfo}> */}
                <Col>
                  <MDInput
                    className="w-100 mt-4"
                    disabled
                    value={`${userDetails.interests || "(Not provided)"}`}
                    type="text"
                    label="Interests"
                    variant="outlined"
                  />
                </Col>
                <Col>
                  <MDInput
                    className="w-100 mt-4"
                    disabled
                    value={`${userDetails.languages || "(Not provided)"}`}
                    type="text"
                    label="Languages"
                    variant="outlined"
                  />
                </Col>
                <Col>
                  <MDInput
                    className="w-100 mt-4"
                    disabled
                    value={`${userDetails.referralCode || "(Not provided)"}`}
                    type="text"
                    label="Referral Code"
                    variant="outlined"
                  />
                </Col>

                {/* </Collapse> */}
                {/* <MDTypography
                onClick={() => {
                  setCollapisbles({
                    ...collapsibles,
                    profileSettings: !collapsibles.profileSettings,
                  });
                }}
                style={{ cursor: "pointer" }}
                variant="h6"
                fontWeight="medium"
              >
                Profile Settings :
              </MDTypography> */}
                {/* <Collapse in={collapsibles.profileSettings}> */}

                <Col>
                  <MDInput
                    className="w-100 mt-4"
                    disabled
                    value={`${userDetails.isHosting === true ? "Yes" : "No"}`}
                    type="text"
                    label="Hosting"
                    variant="outlined"
                  />
                </Col>
                <Col>
                  <MDInput
                    className="w-100 mt-4"
                    disabled
                    value={`${userDetails.isMeetLocalHosts === true ? "Yes" : "No"}`}
                    type="text"
                    label="Meet Local Hosts"
                    variant="outlined"
                  />
                </Col>
                <Col>
                  <MDInput
                    className="w-100 mt-4"
                    disabled
                    value={`${userDetails.maxDistance || "(Not provided)"}`}
                    type="text"
                    label="Max Distance"
                    variant="outlined"
                  />
                </Col>
                <Col>
                  <MDInput
                    className="w-100 mt-4"
                    disabled
                    value={`${userDetails.distanceUnit || "(Not provided)"}`}
                    type="text"
                    label="Distance Unit"
                    variant="outlined"
                  />
                </Col>
              </Row>
              <br />
              <h4>Notification Preferrences</h4>
              <Row className="row-cols-3">
                <Col>
                  <MDInput
                    className="w-100 mt-4"
                    disabled
                    value={`${
                      userDetails.notificationSettings &&
                      userDetails.notificationSettings.newMessageNotification === true
                        ? "Yes"
                        : "No"
                    }`}
                    type="text"
                    label="New Message Notification"
                    variant="outlined"
                  />
                </Col>
                <Col>
                  <MDInput
                    className="w-100 mt-4"
                    disabled
                    value={`${
                      userDetails.notificationSettings &&
                      userDetails.notificationSettings.priorityLikeNotification === true
                        ? "Yes"
                        : "No"
                    }`}
                    type="text"
                    label="Priority Like Notification"
                    variant="outlined"
                  />
                </Col>
                <Col>
                  <MDInput
                    className="w-100 mt-4"
                    disabled
                    value={`${
                      userDetails.notificationSettings &&
                      userDetails.notificationSettings.newMatchNotification === true
                        ? "Yes"
                        : "No"
                    }`}
                    type="text"
                    label="New Match Notification"
                    variant="outlined"
                  />
                </Col>
                <Col>
                  <MDInput
                    className="w-100 mt-4"
                    disabled
                    value={`${
                      userDetails.notificationSettings &&
                      userDetails.notificationSettings.likeNotification === true
                        ? "Yes"
                        : "No"
                    }`}
                    type="text"
                    label="Like Notification"
                    variant="outlined"
                  />
                </Col>
                {/* </Collapse> */}
                {/* <MDTypography
                onClick={() => {
                  setCollapisbles({ ...collapsibles, technicalInfo: !collapsibles.technicalInfo });
                }}
                style={{ cursor: "pointer" }}
                variant="h6"
                fontWeight="medium"
              >
                Technical Information :
              </MDTypography> */}
                {/* <Collapse in={collapsibles.technicalInfo}> */}
                <Col>
                  <MDInput
                    className="w-100 mt-4"
                    disabled
                    value={`${userDetails.userStatus || "(Not provided)"}`}
                    type="text"
                    label="User Status"
                    variant="outlined"
                  />
                </Col>
                <Col>
                  <MDInput
                    className="w-100 mt-4"
                    disabled
                    value={`${userDetails.subscription || "(Not provided)"}`}
                    type="text"
                    label="Subscription"
                    variant="outlined"
                  />
                </Col>
                <Col>
                  <MDInput
                    className="w-100 mt-4"
                    disabled
                    value={`${userDetails.isTutorialDone === true ? "Yes" : "No"} `}
                    type="text"
                    label="Is Tutorial Done"
                    variant="outlined"
                  />
                </Col>
                <Col>
                  <MDInput
                    className="w-100 mt-4"
                    disabled
                    value={`${userDetails.isSubscriptionModalOff === true ? "Yes" : "No"}`}
                    type="text"
                    label=" Is Subscription Modal Off"
                    variant="outlined"
                  />
                </Col>
                <Col>
                  <MDInput
                    className="w-100 mt-4"
                    disabled
                    value={`${userDetails.referrerUserId || "(Not provided)"}`}
                    type="text"
                    label="Referrer User Id"
                    variant="outlined"
                  />
                </Col>
                <Col>
                  <MDInput
                    className="w-100 mt-4"
                    disabled
                    value={`${moment(userDetails.trialExpiresIn).format("ll") || "(Not provided)"}`}
                    type="text"
                    label="Trial Expires In"
                    variant="outlined"
                  />
                </Col>
                <Col>
                  <MDInput
                    className="w-100 mt-4"
                    disabled
                    value={`${userDetails.dynamicReferralLink || "(Not provided)"}`}
                    type="text"
                    label="Dynamic Referral Link"
                    variant="outlined"
                  />
                </Col>
                <Col>
                  <MDInput
                    className="w-100 mt-4"
                    disabled
                    value={`${userDetails.invalidLoginAttempts || "(Not provided)"}`}
                    type="text"
                    label="Invalid Login Attempts"
                    variant="outlined"
                  />
                </Col>
                <Col>
                  <MDInput
                    className="w-100 mt-4"
                    disabled
                    value={`${userDetails.isLoginWithEmailLocked === true ? "Yes" : "No"}`}
                    type="text"
                    label="Is Login With Email Locked"
                    variant="outlined"
                  />
                </Col>
                <Col>
                  <MDInput
                    className="w-100 mt-4"
                    disabled
                    value={`${
                      userDetails.isPhoneNumberVerificationRequired === true ? "Yes" : "No"
                    }`}
                    type="text"
                    label="Is Phone Number Verification Required"
                    variant="outlined"
                  />
                </Col>
                <Col>
                  <MDInput
                    className="w-100 mt-4"
                    disabled
                    value={`${userDetails.isPhoneNumberVerified === true ? "Yes" : "No"}`}
                    type="text"
                    label="Is Phone Number Verified"
                    variant="outlined"
                  />
                </Col>
                <Col>
                  <MDInput
                    className="w-100 mt-4"
                    disabled
                    value={`${userDetails.lastLoginSource || "(Not provided)"}`}
                    type="text"
                    label="Last Login Source "
                    variant="outlined"
                  />
                </Col>
                <Col>
                  <MDInput
                    className="w-100 mt-4"
                    disabled
                    value={`${
                      userDetails.isEmailVerified === true ? "Yes" : "No" || "(Not provided)"
                    }`}
                    type="text"
                    label="Is Email Verified"
                    variant="outlined"
                  />
                </Col>
                <Col>
                  <MDInput
                    className="w-100 mt-4"
                    disabled
                    value={`${moment(userDetails.lastLoginAt).format("ll") || "(Not provided)"}`}
                    type="text"
                    label="Last Login At"
                    variant="outlined"
                  />
                </Col>
              </Row>
              <br />
              <h4>Pending Alerts</h4>
              <Row className="row-cols-3">
                <Col>
                  <MDInput
                    className="w-100 mt-4"
                    disabled
                    value={`${
                      userDetails.pendingAlerts && userDetails.pendingAlerts.tripsDisabled === true
                        ? "Yes"
                        : "No"
                    }`}
                    type="text"
                    label="Trips Disabled"
                    variant="outlined"
                  />
                </Col>
                <Col>
                  <MDInput
                    className="w-100 mt-4"
                    disabled
                    value={`${
                      userDetails.pendingAlerts &&
                      userDetails.pendingAlerts.subscriptionExpired === true
                        ? "Yes"
                        : "No"
                    }`}
                    type="text"
                    label="Subscription Expired"
                    variant="outlined"
                  />
                </Col>
                <Col>
                  <MDInput
                    className="w-100   mt-4"
                    disabled
                    value={`${
                      userDetails.pendingAlerts &&
                      userDetails.pendingAlerts.subscriptionExpired === true
                        ? "Yes"
                        : "No"
                    }`}
                    type="text"
                    label="Subscription Expired"
                    variant="outlined"
                  />
                </Col>
                <Col>
                  <MDInput
                    className="w-100 mt-4"
                    disabled
                    value={`${
                      userDetails.pendingAlerts && userDetails.pendingAlerts.trialExpired === true
                        ? "Yes"
                        : "No"
                    }`}
                    type="text"
                    label="Trial Expired"
                    variant="outlined"
                  />
                </Col>
              </Row>
            </Col>
            {/* <Col className="col-md-3">
              {showInputAwardTrail ? (
                <>
                  <MDInput
                    className=""
                    onChange={(e) => {
                      setAwardTrailDays(e.target.value);
                    }}
                    type="number"
                    label="Enter Number of days"
                    variant="standard"
                  />
                  <MDButton
                    className="w-25 mt-4"
                    onClick={() => {
                      awardTrail(awardTrailDays);
                    }}
                    variant="gradient"
                    color="success"
                  >
                    Done
                  </MDButton>
                </>
              ) : (
                <MDButton
                  className=""
                  onClick={() => setShowInputAwardTrail(true)}
                  variant="gradient"
                  color="success"
                >
                  Award Trial
                </MDButton>
              )}
              <MDBox mb={2}>
                <MDInput type="text" label="Name" variant="outlined" />
              </MDBox>
              <MDButton className="" variant="gradient" color="success">
                Done
              </MDButton>
            </Col> */}
          </Row>
        </MDBox>
        <MDBox pt={2} px={2} lineHeight={1.25}>
          <MDTypography variant="h6" fontWeight="medium">
            Trips
          </MDTypography>
        </MDBox>
        <MDBox p={2}>
          <DataTable
            table={{
              columns: placeOrTripColumns,
              rows: userTrips,
            }}
            isSorted={false}
            entriesPerPage={false}
            showTotalEntries={false}
            noEndBorder
          />
        </MDBox>
        <MDBox pt={2} px={2} lineHeight={1.25}>
          <MDTypography variant="h6" fontWeight="medium">
            Places
          </MDTypography>
        </MDBox>
        <MDBox p={2}>
          <DataTable
            table={{
              columns: placeOrTripColumns,
              rows: userPlaces,
            }}
            isSorted={false}
            entriesPerPage={false}
            showTotalEntries={false}
            noEndBorder
          />
        </MDBox>
        <MDBox pt={2} px={2} lineHeight={1.25}>
          <MDTypography variant="h6" fontWeight="medium">
            Friends
          </MDTypography>
        </MDBox>
        <MDBox p={2}>
          <DataTable
            table={{
              columns: friendsColumns,
              rows: getUserFriends(),
            }}
            isSorted={false}
            entriesPerPage={false}
            showTotalEntries={false}
            noEndBorder
          />
        </MDBox>
        <MDBox pt={2} px={2} lineHeight={1.25}>
          <MDTypography variant="h6" fontWeight="medium">
            Referred Users
          </MDTypography>
        </MDBox>
        <MDBox p={2}>
          <DataTable
            table={{
              columns: referredUsersColumns,
              rows: getUserReferredUsers(),
            }}
            isSorted={false}
            entriesPerPage={false}
            showTotalEntries={false}
            noEndBorder
          />
        </MDBox>
      </Header>
      <Modal centered show={showInputAwardTrail} onHide={() => setShowInputAwardTrail(false)}>
        <Modal.Header>
          <Modal.Title>Award Trial</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MDInput
            className=""
            onChange={(e) => {
              setAwardTrailDays(e.target.value);
            }}
            type="number"
            label="Enter Number of days"
            variant="standard"
          />
        </Modal.Body>
        <Modal.Footer>
          <MDButton
            className="w-15 m-2"
            onClick={() => setShowInputAwardTrail(false)}
            variant="gradient"
            color="info"
          >
            Cancel
          </MDButton>
          <MDButton
            className="w-15 m-2"
            onClick={() => awardTrail(awardTrailDays)}
            variant="gradient"
            color="success"
          >
            Save
          </MDButton>
        </Modal.Footer>
      </Modal>
      <MDSnackbar
        color={getNotificationColor()}
        icon="check"
        title={getNotificationTitle()}
        content={popupMessage}
        dateTime=""
        open={isShowPopup}
        onClose={() => setIsShowPopup(false)}
        close={() => setIsShowPopup(false)}
        bgWhite
      />
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
