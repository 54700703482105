import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import MoreVertIcon from "@mui/icons-material/MoreVert";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import MDButton from "components/MDButton";
import { Grid, Link } from "@mui/material";

function ComplexStatisticsCard({
  color,
  title,
  count,
  percentage,
  icon,
  testCount,
  paidPremiumAndroidUsers = 0,
  paidPremiumIosUsers = 0,
}) {
  const [isShowModal, setIsShowModal] = useState(false);

  return (
    <Card>
      <Modal centered show={isShowModal} onHide={() => setIsShowModal(false)}>
        <Modal.Header>
          <Modal.Title>More Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <MDTypography className="mt-1" fontWeight="regular" variant="h6" color="dark">
                Android Users:
              </MDTypography>
            </Grid>
            <Grid style={{ textAlign: "right" }} item xs={6}>
              <MDTypography className="mt-1" fontWeight="regular" variant="h6" color="dark">
                {paidPremiumAndroidUsers}
              </MDTypography>
            </Grid>
            <Grid item xs={6}>
              <MDTypography className="mt-1" fontWeight="regular" variant="h6" color="dark">
                Ios Users:
              </MDTypography>
            </Grid>
            <Grid style={{ textAlign: "right" }} item xs={6}>
              <MDTypography className="mt-1" fontWeight="regular" variant="h6" color="dark">
                {paidPremiumIosUsers}
              </MDTypography>
            </Grid>

            <Grid item xs={6}>
              <MDTypography className="mt-1" fontWeight="regular" variant="h6" color="dark">
                Google Play:
              </MDTypography>
            </Grid>
            <Grid style={{ textAlign: "right" }} item xs={6}>
              <Link
                target="_blank"
                style={{ fontSize: 16, fontWeight: 500, color: "#0d6efd" }}
                href="https://play.google.com/console/u/0/developers/8669035438034843910/app/4976111544187869418/reporting/subscriptions/overview?from=2022-12-05&to=2023-12-04&product_id=ALL "
              >
                Link
              </Link>
            </Grid>

            <Grid item xs={6}>
              <MDTypography className="mt-1" fontWeight="regular" variant="h6" color="dark">
                Appstore Connect:
              </MDTypography>
            </Grid>
            <Grid style={{ textAlign: "right" }} item xs={6}>
              <Link
                target="_blank"
                style={{ fontSize: 16, fontWeight: 500, color: "#0d6efd" }}
                href="https://appstoreconnect.apple.com/trends/sales?measure=total_tax_usd_utc&period=day&interval=day&endDate=2023-12-03&startDate=2023-09-05 "
              >
                Link
              </Link>
            </Grid>
          </Grid>
        </Modal.Body>
        <Modal.Footer>
          <MDButton
            className="w-15 m-2"
            onClick={() => setIsShowModal(false)}
            variant="gradient"
            color="info"
          >
            Close
          </MDButton>
        </Modal.Footer>
      </Modal>
      <MDBox display="flex" justifyContent="space-between" pt={1} px={2}>
        <MDBox
          variant="gradient"
          bgColor={color}
          color={color === "light" ? "dark" : "white"}
          coloredShadow={color}
          borderRadius="xl"
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="4rem"
          height="4rem"
          mt={-3}
        >
          <Icon fontSize="medium" color="inherit">
            {icon}
          </Icon>
        </MDBox>
        <MDBox textAlign="right" lineHeight={1.25}>
          <MDTypography variant="button" fontWeight="light" color="text">
            {title}
          </MDTypography>
          <MDTypography style={{ marginBottom: !testCount ? 32 : 0 }} variant="h4">
            {count}
          </MDTypography>
          {testCount ? <MDTypography variant="h4">{testCount}</MDTypography> : null}
        </MDBox>
      </MDBox>
      <Divider style={{ marginBottom: title === "Paid Premium Users" ? 0 : 4 }} />
      {title === "Paid Premium Users" ? (
        <div style={{ textAlign: "right", color: "#0d6efd" }}>
          <Link px={2} style={{ cursor: "pointer" }} onClick={() => setIsShowModal(true)}>
            more
          </Link>
        </div>
      ) : (
        <MDBox pb={1} px={2}>
          <MDTypography component="p" variant="button" color="text" display="flex">
            <MDTypography
              component="span"
              variant="button"
              fontWeight="bold"
              color={percentage.color}
            >
              {percentage.amount}
            </MDTypography>
            &nbsp;{percentage.label}
          </MDTypography>
        </MDBox>
      )}
    </Card>
  );
}

// Setting default values for the props of ComplexStatisticsCard
ComplexStatisticsCard.defaultProps = {
  color: "info",
  percentage: {
    color: "success",
    text: "",
    label: "",
  },
};

// Typechecking props for the ComplexStatisticsCard
ComplexStatisticsCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  title: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  percentage: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "white",
    ]),
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
  }),
  icon: PropTypes.node.isRequired,
};

export default ComplexStatisticsCard;
