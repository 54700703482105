import React, { useState } from "react";
import { TextField, IconButton, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MDTextArea from "components/MDTextArea";

const MDTextAreaWithCloseButton = ({ value, onChange, onRemove, fieldIndex, style }) => {
  const [showField, setShowField] = useState(true);

  const handleRemove = () => {
    onRemove(fieldIndex);

    setShowField(false);
  };

  return (
    <div>
      {showField && (
        <Box position="relative" display="inline-block">
          <MDTextArea value={value} onChange={onChange} style={style} variant="outlined" />
          <IconButton
            aria-label="remove text field"
            onClick={handleRemove}
            size="small"
            style={{
              position: "absolute",
              top: -10,
              right: -10,
              color: "white",
              backgroundColor: "maroon",
              borderRadius: "50%",
              boxShadow: "0px 0px 5px rgba(0,0,0,0.3)",
              width: "12px",
              height: "12px",
              //   padding: "1px", // Adjust the padding to change the overall button size
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
      )}
    </div>
  );
};

export default MDTextAreaWithCloseButton;
