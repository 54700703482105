import Icon from "@mui/material/Icon";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import SwipeVerticalOutlinedIcon from "@mui/icons-material/SwipeVerticalOutlined";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";

import GeneralDashboard from "layouts/general-dashboard";
import Statistics from "layouts/app-usage-dashboard";
import InteractionsDashboard from "layouts/interactions-dashboard";
import Profile from "./layouts/profile";
import Settings from "./layouts/settings";
import SignIn from "layouts/authentication/sign-in";
import ActiveUsers from "layouts/active-users";
import WandererList from "layouts/tables";
import Interactions from "./layouts/test-user";
import CreateUser from "layouts/test-user/create";
import Chats from "layouts/test-user/chats";
import TestUserChat from "pages/TestUserChat";
import CreateCampaign from "pages/Campaigns/Create";
import Campaigns from "pages/Campaigns";
import SupportChats from "layouts/support-chats";
import CustomReferralCodes from "pages/CustomReferralCodes";
import Languages from "pages/Languages";
import SupportChat from "pages/support-user-chat";
import Hotspots from "./pages/hotspots";

const routes = [
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
  {
    type: "collapse",
    name: "General Dashboard",
    key: "general-dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/general-dashboard",
    component: <GeneralDashboard />,
  },
  {
    type: "collapse",
    name: "Statistics",
    key: "statistics",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/statistics",
    component: <Statistics />,
  },
  {
    type: "collapse",
    name: "Interactions Dashboard",
    key: "interactions-dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/interactions-dashboard",
    component: <InteractionsDashboard />,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile/:id",
    component: <Profile />,
  },
  {
    type: "collapse",
    name: "Settings",
    key: "settings",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/settings",
    component: <Settings />,
  },
  {
    type: "collapse",
    name: "Custom Referral Codes",
    key: "custom-referral-codes",
    icon: <Icon fontSize="small">Custom Referral Codes</Icon>,
    route: "/profile/:id/custom-referral-codes",
    component: <CustomReferralCodes />,
  },
  {
    type: "collapse",
    name: "DAU/MAU",
    key: "dau-mau",
    icon: <PeopleOutlineIcon />,
    route: "/dau-mau",
    component: <ActiveUsers />,
  },
  {
    type: "collapse",
    name: "Wanderers",
    key: "wanderers",
    icon: <PeopleOutlineIcon />,
    route: "/wanderers",
    component: <WandererList />,
  },
  {
    type: "collapse",
    name: "Create Test User",
    key: "create",
    icon: <PersonAddAltIcon />,
    route: "/create",
    component: <CreateUser />,
  },
  {
    type: "collapse",
    name: "Interactions",
    key: "interactions",
    icon: <SwipeVerticalOutlinedIcon />,
    route: "/interactions",
    component: <Interactions />,
  },
  {
    type: "collapse",
    name: "Chats",
    key: "chats",
    icon: <ChatOutlinedIcon />,
    route: "/chats",
    component: <Chats />,
  },
  {
    type: "collapse",
    name: "Chat",
    key: "chat",
    icon: <ChatOutlinedIcon />,
    route: "/chats/chat-details",
    component: <TestUserChat />,
  },
  {
    type: "collapse",
    name: "Campaign",
    key: "campaigns",
    icon: <ChatOutlinedIcon />,
    route: "/campaigns",
    component: <Campaigns />,
  },
  {
    type: "collapse",
    name: "Create Campaign",
    key: "campaigns-create",
    icon: <ChatOutlinedIcon />,
    route: "/campaigns/detail",
    component: <CreateCampaign />,
  },
  {
    type: "collapse",
    name: "Support Chats",
    key: "support-chats",
    icon: <ChatOutlinedIcon />,
    route: "/support-chats",
    component: <SupportChats />,
  },
  {
    type: "collapse",
    name: "Support Chat",
    key: "support-chat",
    icon: <ChatOutlinedIcon />,
    route: "/support-chats/chat-details",
    component: <SupportChat />,
  },
  {
    type: "collapse",
    name: "Language",
    key: "language-mappings",
    icon: <ChatOutlinedIcon />,
    route: "/language",
    component: <Languages />,
  },
  {
    type: "collapse",
    name: "Hotspots",
    key: "hotspots",
    icon: <ChatOutlinedIcon />,
    route: "/hotspots",
    component: <Hotspots />,
  },
];

export default routes;
