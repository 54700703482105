import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import "react-image-picker-editor/dist/index.css";
import Footer from "examples/Footer";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";

import Services from "../../../services";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "../../../components/MDInput";
import MDSnackbar from "components/MDSnackbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Card } from "@mui/material";
import BootstrapSelect from "components/BootstrapSelect";

function CreateCampaign() {
  const [isEdit, setIsEdit] = useState(false);

  const [id, setId] = useState("");

  const location = useLocation();

  useEffect(() => {
    const state = location?.state;

    if (state?.campaign) {
      const { campaign } = location?.state;

      setIsEdit(true);
      setId(campaign._id);
      setTitle(campaign.title);
      setKind(campaign.kind);
      setTrialDays(campaign.trialDays);
      setCampaignStartDate(moment(campaign.campaignStartDate).format("MM/DD/YYYY"));
      setCampaignEndDate(moment(campaign.campaignEndDate).format("MM/DD/YYYY"));
    }
  }, []);

  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState("");

  const [kind, setKind] = useState("FREE_TRIAL_AWARD");
  const [kindError, setKindError] = useState("");

  const [trialDays, setTrialDays] = useState("");
  const [trialDaysError, setTrialDaysError] = useState("");

  const [campaignStartDate, setCampaignStartDate] = useState("");
  const [startDateError, setStartDateError] = useState("");

  const [campaignEndDate, setCampaignEndDate] = useState("");
  const [endDateError, setEndDateError] = useState("");

  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");

  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const navigate = useNavigate();

  const submit = async () => {
    title ? setTitleError("") : setTitleError("Title is required");
    kind ? setKindError("") : setKindError("Kind is required");
    trialDays ? setTrialDaysError("") : setTrialDaysError("Trial days are required");
    campaignStartDate
      ? setStartDateError("")
      : setStartDateError("Campaign start date is required");
    campaignEndDate ? setEndDateError("") : setEndDateError("Campaign end date is required");

    if (!title || !kind || !trialDays || !campaignStartDate || !campaignEndDate) {
      return;
    }

    const data = {
      title,
      kind,
      trialDays,
      campaignStartDate,
      campaignEndDate,
    };
    let res;

    if (id) {
      try {
        res = await Services.editCampaignStatus(id, data);
      } catch (err) {
        setErrorMsg(err.response.data.message);
      }
    } else {
      try {
        res = await Services.postCampaign(data);
      } catch (err) {
        setErrorMsg(err.response.data.message);
      }
    }

    if (res?.status == 200) {
      setSuccessMsg(id ? "Updated Successfully" : "Created Successfully");
      setSuccess(true);
      setTimeout(() => {
        navigate("/campaigns");
      }, 2000);
    } else {
      setError(true);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <div>
        <MDSnackbar
          color={"success"}
          icon="check"
          title={"Campaign"}
          content={successMsg}
          dateTime="now"
          open={success}
          onClose={() => setSuccess(false)}
          close={() => setSuccess(false)}
          bgWhite
        />
        <MDSnackbar
          color="error"
          icon="warning"
          title={"Something went wrong!"}
          content={errorMsg}
          dateTime="now"
          open={error}
          onClose={() => setError(false)}
          close={() => setError(false)}
          bgWhite
        />
        <MDBox
          mx={0}
          mt={3}
          py={3}
          px={2}
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
        >
          <MDTypography variant="h3" color="white">
            {isEdit ? "Campaign - Edit" : "Campaign - Create"}
          </MDTypography>
        </MDBox>
      </div>

      <Card>
        <MDBox pt={6} pb={3}>
          <Grid container padding={2} spacing={8}>
            <Grid item xs={4}>
              <MDInput
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
                type="text"
                label="Title"
                variant="outlined"
                fullWidth
                error={titleError}
                value={title}
              />
            </Grid>
            <Grid item xs={4}>
              {/* <MDInput
                disabled
                type="text"
                label="Kind"
                variant="outlined"
                fullWidth
                error={kindError}
                value={kind}
              /> */}
              <BootstrapSelect
                item="Kind"
                itemsList={[{ _id: 1, option: "FREE_TRIAL_AWARD" }]}
                onReturn={(selectedItem) => {
                  setKind(selectedItem.option);
                }}
                style={{ fontSize: 15 }}
                value={1}
              />
            </Grid>
            <Grid item xs={4}>
              <MDInput
                onChange={(e) => {
                  setTrialDays(e.target.value);
                }}
                type="number"
                label="Trial Days"
                variant="outlined"
                fullWidth
                error={trialDaysError}
                value={trialDays}
              />
            </Grid>

            <Grid item xs={6}>
              <MDInput
                onChange={(e) => {
                  setCampaignStartDate(e.target.value);
                }}
                onFocus={(e) => (e.target.type = "date")}
                onBlur={(e) => (e.target.type = "text")}
                label="Start Date"
                type="text"
                variant="outlined"
                fullWidth
                error={startDateError}
                value={campaignStartDate}
              />
            </Grid>
            <Grid item xs={6}>
              <MDInput
                onChange={(e) => {
                  setCampaignEndDate(e.target.value);
                }}
                onFocus={(e) => (e.target.type = "date")}
                onBlur={(e) => (e.target.type = "text")}
                label="End Date"
                type="text"
                variant="outlined"
                fullWidth
                error={endDateError}
                value={campaignEndDate}
              />
            </Grid>

            <Grid item xs={12}>
              <MDButton onClick={submit} variant="gradient" color="info" fullWidth>
                {isEdit ? "Update" : "Create"}
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </Card>
    </DashboardLayout>
  );
}

export default CreateCampaign;
