import { useState } from "react";
import Select from "react-select";

function SelectBasicExample({
  isLoading = false,
  item = "",
  itemsList = [],
  style = {},
  onReturn,
  isMultiInput = false,
  value,
}) {
  itemsList = itemsList.map((e) => {
    return { value: e._id, label: e.option, data: e.data };
  });

  const [isInvalid, setIsInValid] = useState(false);

  const populateItems = () => {
    let items = itemsList;

    if (items[0]._id === 0) {
      return items.map((u, index) => <option key={index + 1}>{u.option}</option>);
    } else {
      items.unshift({ _id: 0, option: `Select ${item}` });

      return items.map((u, index) => <option key={index + 1}>{u.option}</option>);
    }
  };

  return (
    <div style={style}>
      <Select
        isLoading={isLoading}
        value={itemsList?.find((e) => {
          return e?.value === value;
        })}
        onChange={(e) => {
          if (isMultiInput === true) {
            return onReturn(e);
          }

          onReturn({ option: e.label, _id: e.value, data: e.data });
        }}
        placeholder={`Select ${item}`}
        options={itemsList}
        isMulti={isMultiInput}
      />
    </div>
  );
}

export default SelectBasicExample;
