import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import sha256 from "js-sha256";
import Axios from "axios";
import MDSnackbar from "components/MDSnackbar";

function Basic() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [isLoginSuccessfull, setIsLoginSuccessfull] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [isShowPopup, setIsShowPopup] = useState(false);
  const apiEndPoint = `${process.env.REACT_APP_API_URL}/auth/login`;

  const [loginData, setLoginData] = useState({ email: "", password: "" });

  const handleChange = (e) => {
    let newVal = e.target.value;

    if (e.target.name === "password") {
      newVal = sha256(`wander${newVal}app`);
    }

    setLoginData((prevState) => ({
      ...prevState,
      [e.target.name]: newVal,
    }));
  };

  const handleSignIn = async () => {
    setIsLoading(true);

    try {
      const {
        data: { user, token },
      } = await Axios.post(apiEndPoint, loginData);
      localStorage.setItem("token", token);
      localStorage.setItem("currentUser", JSON.stringify(user));

      if (user.isAdmin) {
        setIsLoginSuccessfull(true);
        setIsShowPopup(true);
        setTimeout(() => {
          navigate("/general-dashboard", { replace: true });
        }, 1000);
      } else {
        setErrorMessage("You are not authorized to access this platform!");
        setIsShowPopup(true);
      }
    } catch (err) {
      setIsLoginSuccessfull(false);

      if (err.response && err.response.data.message) {
        setErrorMessage(err.response.data.message);
      }

      setIsShowPopup(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const getNotificationColor = () => {
    let message = "";
    if (isLoginSuccessfull) {
      message = "success";
    } else if (errorMessage !== "") {
      message = "error";
    }
    return message;
  };

  const getNotificationTitle = () => {
    let message = "";
    if (isLoginSuccessfull) {
      message = "Success";
    } else if (errorMessage !== "") {
      message = "Error";
    }
    return message;
  };

  return (
    <BasicLayout image={bgImage}>
      <MDSnackbar
        color={getNotificationColor()}
        icon="check"
        title={getNotificationTitle()}
        content={isLoginSuccessfull ? "You have been logged in successfully" : `${errorMessage}`}
        dateTime="now"
        open={isShowPopup}
        onClose={() => setIsShowPopup(false)}
        close={() => setIsShowPopup(false)}
        bgWhite
      />
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign In
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput name="email" type="email" label="Email" fullWidth onChange={handleChange} />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                name="password"
                type="password"
                label="Password"
                fullWidth
                onChange={handleChange}
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton onClick={handleSignIn} variant="gradient" color="info" fullWidth>
                Sign In
                {isLoading ? (
                  <span
                    style={{ marginLeft: 5 }}
                    className="spinner-border spinner-border-sm ml-1"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : null}
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
