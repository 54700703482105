import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { useMaterialUIController } from "context";

function DisplayLocation({
  label = "",
  locationName = "",
  locationArrival = "",
  locationDeparture = "",
  noGutter,
  onDelete,
}) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  return (
    <MDBox
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      bgColor={darkMode ? "transparent" : "grey-100"}
      borderRadius="lg"
      p={3}
      mb={noGutter ? 0 : 1}
      mt={2}
    >
      <MDBox width="100%" display="flex" flexDirection="column">
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
          mb={2}
        >
          <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
            {label}
          </MDTypography>

          <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
            <MDBox mr={1}>
              <MDButton onClick={() => onDelete(locationName)} variant="text" color="error">
                <Icon>delete</Icon>
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
        <MDBox mb={1} lineHeight={0}>
          <MDTypography variant="caption" color="text">
            Location Name:&nbsp;&nbsp;&nbsp;
            <MDTypography variant="caption" fontWeight="medium" textTransform="capitalize">
              {locationName}
            </MDTypography>
          </MDTypography>
        </MDBox>
        <MDBox mb={1} lineHeight={0}>
          {locationArrival ? (
            <MDTypography variant="caption" color="text">
              Arrival:&nbsp;&nbsp;&nbsp;
              <MDTypography variant="caption" fontWeight="medium">
                {locationArrival}
              </MDTypography>
            </MDTypography>
          ) : null}
        </MDBox>
        {locationDeparture ? (
          <MDTypography variant="caption" color="text">
            Departure:&nbsp;&nbsp;&nbsp;
            <MDTypography variant="caption" fontWeight="medium">
              {locationDeparture}
            </MDTypography>
          </MDTypography>
        ) : null}
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of DisplayLocation
DisplayLocation.defaultProps = {
  noGutter: false,
};

// Typechecking props for the DisplayLocation
DisplayLocation.propTypes = {
  label: PropTypes.string.isRequired,
  locationName: PropTypes.string.isRequired,
  locationArrival: PropTypes.string,
  locationDeparture: PropTypes.string,
  noGutter: PropTypes.bool,
};

export default DisplayLocation;
