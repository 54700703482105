import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { useNavigate } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import moment from "moment";
import { pascalCase } from "pascal-case";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import BootstrapSelect from "components/BootstrapSelect";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import Services from "../../../services";
import { DoneOutlined, Clear } from "@mui/icons-material";
import { CHAT_STATUS } from "../../../../src/constants";

function Tables() {
  const navigate = useNavigate();
  const [selectedUserId, setSelectedUserId] = useState("");
  const [selectedUser, setSelectedUser] = useState();

  const [isLoadingNames, setIsLoadingNames] = useState(false);
  const [isLoadingChats, setIsLoadingChats] = useState(false);

  const [directMessages, setDirectMessages] = useState([]);
  const [chats, setChats] = useState([]);
  const [page, setPage] = useState(1);
  const [searchItem, setSearchItem] = useState("");
  const [directMessagesListColumns] = useState([
    { Header: "#", accessor: "serialNo", width: "2%", align: "left" },
    { Header: "Name", accessor: "name", width: "5%", align: "left" },
    { Header: "Message", accessor: "message", width: "10%", align: "center" },
    { Header: "Last Message At", accessor: "lastMessageAt", width: "30%", align: "center" },
    { Header: "Action 1", accessor: "action1", width: "5%", align: "center" },
    { Header: "Action 2", accessor: "action2", width: "5%", align: "center" },
  ]);
  const [ChatsListColumns] = useState([
    { Header: "#", accessor: "serialNo", width: "2%", align: "left" },
    { Header: "Name", accessor: "name", width: "5%", align: "left" },
    { Header: "Message", accessor: "message", width: "10%", align: "center" },
    { Header: "Last Message At", accessor: "lastMessageAt", width: "30%", align: "center" },
    { Header: "Status", accessor: "status", width: "5%", align: "center" },
    { Header: "Action", accessor: "action", width: "5%", align: "center" },
  ]);

  const [users, setUsers] = useState([]);

  useEffect(() => {
    loadPreviousState();
  }, []);

  useEffect(() => {
    if (selectedUserId && Object.keys(selectedUser)) {
      getUserChats(selectedUserId, selectedUser);
    }
  }, [selectedUserId, selectedUser]);

  const saveCurrentState = () => {
    sessionStorage.setItem(
      "chat-list-previous-state",
      JSON.stringify({
        usersList: users,
        selectedUser,
      })
    );
  };

  const loadPreviousState = () => {
    let previousState = sessionStorage.getItem("chat-list-previous-state");

    if (previousState) {
      previousState = JSON.parse(previousState);

      setUsers(previousState.usersList);
      setSelectedUser(previousState.selectedUser);
      setSelectedUserId(previousState.selectedUser._id);

      sessionStorage.removeItem("chat-list-previous-state");
    } else {
      getUsers();
    }
  };

  const acceptRejectChat = async (accept, toUserId) => {
    const msg = `Are you sure you want to ${accept ? "accept ?" : "reject ?"}`;

    const confirmed = window.confirm(msg);

    if (confirmed) {
      let body = {
        toUserId: toUserId,
        isAccepted: accept,
      };

      try {
        await Services.acceptRejectChat(selectedUserId, body);

        await getUserChats(selectedUserId, selectedUser);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const getUsers = async () => {
    setIsLoadingNames(true);

    try {
      const { data } = await Services.getTestUsers();

      const list = data.map((u) => {
        return {
          _id: u._id,
          option: `(${u.directMessagesReceivedCount}) (${u.unreadChatsCount}) - ${u.firstName} ${u.lastName} (${u.preferredName}) `,
          data: u,
        };
      });

      setUsers(list);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingNames(false);
    }
  };
  const getUserChats = async (id, { data: user }) => {
    setIsLoadingChats(true);

    try {
      let { data } = await Services.getTestUserChat(id);

      data = data.sort((a, b) => {
        const dateA = new Date(a.lastMessageAt);
        const dateB = new Date(b.lastMessageAt);

        return dateB - dateA;
      });

      const directMessagesList = data
        .filter((e) => e.status[id] === CHAT_STATUS.DIRECT_MESSAGE)
        .map((item, index) => ({
          serialNo: index + 1,
          _id: item._id,
          name: (
            <>
              <img
                alt="dp"
                width={50}
                height={50}
                style={{ borderRadius: 25 }}
                src={item.profilePictureToShow}
              />
              <span style={{ marginLeft: 10 }}>{item.chatNameToShow}</span>
            </>
          ),
          message: (
            <div
              style={{
                width: 290,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {item.lastMessage}
            </div>
          ),
          lastMessageAt: moment(item.lastMessageAt).format("lll"),
          action1: (
            <MDButton
              onClick={() => acceptRejectChat(true, item.otherUser._id)}
              variant="gradient"
              color="success"
              fullWidth
            >
              <DoneOutlined />
            </MDButton>
          ),
          action2: (
            <MDButton
              onClick={() => acceptRejectChat(false, item.otherUser._id)}
              variant="gradient"
              color="error"
              fullWidth
            >
              <Clear />
            </MDButton>
          ),
        }));

      setDirectMessages(directMessagesList);

      const chatsList = data
        .filter((e) => e.status[id] !== CHAT_STATUS.DIRECT_MESSAGE)
        .map((item, index) => ({
          serialNo: index + 1,
          _id: item._id,
          name: (
            <>
              <img
                alt="dp"
                width={50}
                height={50}
                style={{ borderRadius: 25 }}
                src={item.profilePictureToShow}
              />
              <span style={{ marginLeft: 10 }}>{item.chatNameToShow}</span>
            </>
          ),
          message: (
            <div
              style={{
                width: 245,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {item.lastMessage}
            </div>
          ),
          lastMessageAt: moment(item.lastMessageAt).format("lll"),
          status: item.isReadByMe ? (
            `Seen (${pascalCase(item.currentStatus)})`
          ) : (
            <span style={{ color: "maroon" }}>Unseen ({pascalCase(item.currentStatus)})</span>
          ),
          action: (
            <MDButton
              onClick={() => {
                saveCurrentState();
                navigate("/chats/chat-details", { state: { chatData: item, user } });
              }}
              variant="gradient"
              color="success"
              fullWidth
            >
              <ChatBubbleIcon />
            </MDButton>
          ),
        }));

      setChats(chatsList);
    } catch (error) {
      console.log("🚀 ~ file: index.js:90 ~ getUserChats ~ error:", error);
    } finally {
      setIsLoadingChats(false);
    }
  };

  const validateToken = () => {
    if (!localStorage.getItem("token")) {
      navigate("/authentication/sign-in", { replace: true });
    }
  };

  useEffect(() => {
    validateToken();
  });

  return (
    <DashboardLayout>
      <DashboardNavbar
        getSearchItem={(value) => {
          setSearchItem(value);
        }}
      />

      <div>
        <MDBox
          mx={0}
          mt={3}
          py={3}
          px={2}
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
        >
          <MDTypography variant="h3" color="white">
            Test User - Chats
          </MDTypography>

          <BootstrapSelect
            isLoading={isLoadingNames}
            itemsList={users}
            value={selectedUserId ? selectedUserId : null}
            item="User"
            style={{ width: "400px" }}
            onReturn={(selectedItem) => {
              setSelectedUserId(selectedItem._id);
              setSelectedUser(selectedItem);
              console.log("🚀 ~ file: index.js:236 ~ Tables ~ selectedItem:", selectedItem);
            }}
          />
        </MDBox>
      </div>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <Accordion>
                <Accordion.Item style={{ borderRadius: 15 }} eventKey="0">
                  <Accordion.Header>
                    Direct Messages Recieved{" "}
                    {isLoadingChats ? (
                      <span
                        style={{
                          marginLeft: 10,
                          marginBottom: 5,
                          width: "1rem",
                          height: "1rem",
                          borderWidth: "2px",
                        }}
                        className="spinner-border"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : null}
                  </Accordion.Header>
                  <Accordion.Body>
                    <MDBox pt={3}>
                      <DataTable
                        table={{
                          columns: directMessagesListColumns,
                          rows: directMessages,
                        }}
                        isSorted={false}
                        entriesPerPage={false}
                        showTotalEntries={false}
                        noEndBorder
                      />
                    </MDBox>
                    {directMessages.length <= 10 ? (
                      <MDBox
                        mx={2}
                        // mt={-3}
                        py={3}
                        px={2}
                        variant="gradient"
                        borderRadius="lg"
                        align={"center"}
                        // coloredShadow="info"
                      >
                        {/* {page === 1 ? null : (
                          <MDButton
                            onClick={() => {
                              setPage(page - 1);
                            }}
                            variant="outlined"
                            color="secondary"
                          >
                            Previous Page
                          </MDButton>
                        )} */}
                        {/* &nbsp; &nbsp; */}
                        {/* <MDButton
                          onClick={() => {
                            setPage(page + 1);
                          }}
                          variant="outlined"
                          color="secondary"
                        >
                          Next Page
                        </MDButton> */}
                      </MDBox>
                    ) : null}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <Accordion>
                <Accordion.Item style={{ borderRadius: 15 }} eventKey="0">
                  <Accordion.Header>
                    Chats List{" "}
                    {isLoadingChats ? (
                      <span
                        style={{
                          marginLeft: 10,
                          marginBottom: 5,
                          width: "1rem",
                          height: "1rem",
                          borderWidth: "2px",
                        }}
                        className="spinner-border"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : null}
                  </Accordion.Header>
                  <Accordion.Body>
                    <MDBox pt={3}>
                      <DataTable
                        table={{
                          columns: ChatsListColumns,
                          rows: chats,
                        }}
                        isSorted={false}
                        entriesPerPage={false}
                        showTotalEntries={false}
                        noEndBorder
                      />
                    </MDBox>
                    {chats.length <= 10 ? (
                      <MDBox
                        mx={2}
                        // mt={-3}
                        py={3}
                        px={2}
                        variant="gradient"
                        borderRadius="lg"
                        align={"center"}
                        // coloredShadow="info"
                      >
                        {/* {page === 1 ? null : (
                          <MDButton
                            onClick={() => {
                              setPage(page - 1);
                            }}
                            variant="outlined"
                            color="secondary"
                          >
                            Previous Page
                          </MDButton>
                        )} */}
                        {/* &nbsp; &nbsp; */}
                        {/* <MDButton
                          onClick={() => {
                            setPage(page + 1);
                          }}
                          variant="outlined"
                          color="secondary"
                        >
                          Next Page
                        </MDButton> */}
                      </MDBox>
                    ) : null}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Tables;
