import React from "react";
import moment from "moment";

const Sender = ({ data }) => {
  const { text, createdAt, createdBy } = data;

  const fsFourteen = {
    fontSize: "14px",
  };
  const poppinsFont = {
    fontFamily: "Poppins, sans-serif",
  };
  const radiusEight = {
    borderRadius: "8px",
  };

  return (
    <div className="row">
      <div style={{ maxWidth: "405px" }} className="col-auto ms-auto text-start mb-3">
        <div className="d-flex align-items-center py-2">
          <p className="mb-0 me-3" style={{ fontSize: "13px", fontWeight: 600 }}>
            {`${createdBy?.firstName} ${createdBy?.lastName}`}
          </p>
          <p className="mb-0" style={{ fontSize: "12px", fontWeight: 400 }}>
            {moment(createdAt).format("lll")}
          </p>
        </div>
        <p
          style={{ ...fsFourteen, ...poppinsFont, ...radiusEight, whiteSpace: "pre-wrap", wordWrap: "break-word", }}
          className="mb-0 py-2 ps-2 pe-1 bg-secondary text-white"
        >
          {text}
        </p>
      </div>
    </div>
  );
};

export default Sender;
