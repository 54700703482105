import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import { useNavigate, useParams } from "react-router-dom";
import Services from "../../services";

import { Col, Modal, Row } from "react-bootstrap";
import MDSnackbar from "components/MDSnackbar";
import moment from "moment";

function CustomReferralCodes() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [codes, setCodes] = useState([]);

  const [showForm, setShowForm] = useState(false);

  const [showList, setShowList] = useState(false);

  const [usersList, setUsersList] = useState([]);

  const [customReferralCode, setCustomReferralCode] = useState("");
  const [dynamicReferralLink, setDynamicReferralLink] = useState("");
  const [validFrom, setValidFrom] = useState("");
  const [validTill, setValidTill] = useState("");
  const [durationDays, setDurationDays] = useState("");

  const [showSuccess, setShowSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");

  const [columns] = useState([
    { Header: "#", accessor: "serialNo", width: "5%", align: "left" },
    { Header: "Referral Code", accessor: "referralCode", width: "10%", align: "center" },
    {
      Header: "Dynamic Referral Link",
      accessor: "dynamicReferralLink",
      width: "15%",
      align: "center",
    },
    { Header: "Duration Days", accessor: "durationDays", width: "5%", align: "center" },
    { Header: "Valid From", accessor: "validFrom", width: "5%", align: "center" },
    { Header: "Valid Till", accessor: "validTill", width: "5%", align: "center" },
    { Header: "Consumed Count", accessor: "consumersCount", width: "5%", align: "center" },
    { Header: "Action", accessor: "action", width: "5%", align: "center" },
  ]);

  const [userColumns] = useState([
    { Header: "#", accessor: "serialNo", width: "5%", align: "left" },
    { Header: "Picture", accessor: "profilePicture", width: "5%", align: "center" },
    { Header: "Email", accessor: "email", width: "5%", align: "center" },
    { Header: "Phone Number", accessor: "phoneNumber", width: "10%", align: "center" },
    { Header: "Last Login Source", accessor: "lastLoginSource", width: "5%", align: "center" },
    { Header: "Status", accessor: "userStatus", width: "5%", align: "center" },
    { Header: "Action", accessor: "action", width: "5%", align: "center" },
  ]);

  const validateToken = () => {
    if (!localStorage.getItem("token")) {
      navigate("/authentication/sign-in", { replace: true });
    }
  };

  const submit = async () => {
    const body = {
      customReferralCode,
      dynamicReferralLink,
      validFrom,
      validTill,
      durationDays,
      userId: id,
    };

    const res = await Services.createCustomReferralCode(body);
    if (res.status == 200) {
      setSuccessMsg("Custom referral code created");
      setShowSuccess(true);
      setShowForm(false);
      getCodes();
    }
  };

  const getUsersList = async (codeId, referralCode) => {
    const { data } = await Services.getCustomReferralCodeUsers(codeId);

    const list = data.map((item, index) => ({
      serialNo: index + 1,
      profilePicture: item.profilePicture ? (
        <img alt="dp" width={50} src={item.profilePicture} />
      ) : (
        "(Not provided)"
      ),
      email: item.email,
      phoneNumber: item.phoneNumber,
      lastLoginSource: item.lastLoginSource,
      userStatus: item.userStatus,
      action: (
        <MDButton
          onClick={() => navigate(`/profile/${item._id}`, { state: { userId: item._id } })}
          variant="outlined"
          color="info"
          fullWidth
        >
          View
        </MDButton>
      ),
    }));

    setUsersList(list);
    setShowList(true);
  };

  const getCodes = async () => {
    setIsLoading(true);

    try {
      const { data } = await Services.getCustomReferralCode(id);
      console.log("🚀 ~ file: index.jsx:129 ~ getCodes ~ data:", data);
      const list = data.map((item, index) => ({
        serialNo: index + 1,
        referralCode: item.referralCode,
        dynamicReferralLink: item.dynamicReferralLink,
        durationDays: item.durationDays,
        validFrom: moment(item.validFrom).format("DD-MM-YYYY"),
        validTill: moment(item.validTill).format("DD-MM-YYYY"),
        consumersCount: item.consumersCount,
        action: (
          <MDButton
            onClick={() => getUsersList(item._id, item.referralCode)}
            variant="outlined"
            color="info"
            fullWidth
          >
            View
          </MDButton>
        ),
      }));

      setCodes(list);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    validateToken();
  });

  useEffect(() => {
    getCodes();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <div className="d-flex flex-row justify-content-between">
                  <MDTypography variant="h6" color="white">
                    Custom Referral Code List
                    {isLoading ? (
                      <span
                        style={{ marginLeft: 5 }}
                        className="spinner-border spinner-border-sm ml-1"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : null}
                  </MDTypography>
                  <MDButton
                    className="w-15 align-self-end"
                    onClick={() => setShowForm(true)}
                    variant="gradient"
                    color="success"
                  >
                    Create Referral Code
                  </MDButton>
                </div>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{
                    columns,
                    rows: codes,
                  }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <MDSnackbar
        color="success"
        icon="check"
        title="Success"
        content={successMsg}
        dateTime=""
        open={showSuccess}
        onClose={() => {
          setShowSuccess(false);
        }}
        close={() => {
          setShowSuccess(false);
        }}
        bgWhite
      />
      <Modal centered show={showForm} onHide={() => setShowForm(false)}>
        <Modal.Header>
          <Modal.Title>Create Referral Code</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="row-cols-2">
            <Col>
              <MDInput
                className="w-100 mt-6 m-1"
                value={customReferralCode}
                type="text"
                label="Custom Referral Code"
                variant="outlined"
                onChange={(e) => {
                  setCustomReferralCode(e.target.value);
                }}
              />
            </Col>
            <Col>
              <MDInput
                className="w-100 mt-6 m-1"
                value={dynamicReferralLink}
                type="text"
                label="Dynamic Referral Link"
                variant="outlined"
                onChange={(e) => {
                  setDynamicReferralLink(e.target.value);
                }}
              />
            </Col>
            <Col>
              <MDInput
                className="w-100 mt-6 m-1"
                value={validFrom}
                type="text"
                label="Valid From"
                onFocus={(e) => (e.target.type = "date")}
                onBlur={(e) => (e.target.type = "text")}
                variant="outlined"
                onChange={(e) => {
                  setValidFrom(e.target.value);
                }}
              />
            </Col>
            <Col>
              <MDInput
                className="w-100 mt-6 m-1"
                value={validTill}
                type="text"
                label="Valid Till"
                variant="outlined"
                onFocus={(e) => (e.target.type = "date")}
                onBlur={(e) => (e.target.type = "text")}
                onChange={(e) => {
                  setValidTill(e.target.value);
                }}
              />
            </Col>
            <Col>
              <MDInput
                className="w-100 mt-6 m-1"
                value={durationDays}
                label="Duration Days"
                variant="outlined"
                type="number"
                onChange={(e) => {
                  setDurationDays(e.target.value);
                }}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <MDButton
            className="w-15 m-2"
            onClick={() => setShowForm(false)}
            variant="gradient"
            color="info"
          >
            Cancel
          </MDButton>
          <MDButton className="w-15 m-2" onClick={submit} variant="gradient" color="success">
            Save
          </MDButton>
        </Modal.Footer>
      </Modal>
      <Modal
        style={{ zIndex: 1200 }}
        size="xl"
        centered
        show={showList}
        onHide={() => setShowList(false)}
      >
        <Modal.Header>
          <Modal.Title>User List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DataTable
            table={{
              columns: userColumns,
              rows: usersList,
            }}
            isSorted={false}
            entriesPerPage={false}
            showTotalEntries={false}
            noEndBorder
          />
        </Modal.Body>
        <Modal.Footer>
          <MDButton
            className="w-15 m-2"
            onClick={() => setShowList(false)}
            variant="gradient"
            color="info"
          >
            Close
          </MDButton>
        </Modal.Footer>
      </Modal>
      <Footer />
    </DashboardLayout>
  );
}

export default CustomReferralCodes;
