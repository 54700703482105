import axios from "axios";

const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

client.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (err) => Promise.reject("Interceptior(Request) : " + err)
);

client.interceptors.response.use(null, (err) => {
  const expectedErr = err.response && err.response.status >= 400 && err.response.status < 500;

  if (!expectedErr) {
    console.log("Interceptior(Response) : " + err);
  }

  return Promise.reject(err.response.data);
});

export { client };
