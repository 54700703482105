import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";

import ReactImagePickerEditor from "react-image-picker-editor";
import "react-image-picker-editor/dist/index.css";
import Services from "../../../services";
import sha256 from "js-sha256";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import BootstrapSelect from "components/BootstrapSelect";
import { usePlacesWidget } from "react-google-autocomplete";
import Form from "react-bootstrap/Form";
import MDSnackbar from "components/MDSnackbar";
import DisplayLocation from "components/place-or-trip-display";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// import Axios from "axios";
import { useNavigate } from "react-router-dom";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { firebaseApp } from "App";

function Tables() {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const [toast, setToast] = useState({
    color: "",
    title: "",
    content: "",
  });

  const [userHeightOptions] = useState([
    54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77,
    78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
    101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119,
    120, 121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135, 136, 137, 138,
    139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150, 151, 152, 153, 154, 155, 156, 157,
    158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170, 171, 172, 173, 174, 175, 176,
    177, 178, 179, 180, 181, 182, 183, 184, 185, 186, 187, 188, 189, 190, 191, 192, 193, 194, 195,
    196, 197, 198, 199, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214,
    215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233,
    234, 235, 236, 237, 238, 239, 240, 241, 242, 243, 244, 245, 246, 247, 248, 249, 250,
  ]);

  const [allLanguages] = useState([
    "Afrikaans",
    "Albanian",
    "Amharic",
    "Arabic",
    "Aragonese",
    "Armenian",
    "Asturian",
    "Azerbaijani",
    "Basque",
    "Belarusian",
    "Bengali",
    "Bosnian",
    "Breton",
    "Bulgarian",
    "Catalan",
    "Central Kurdish",
    "Chinese",
    "Chinese (Hong Kong)",
    "Chinese (Simplified)",
    "Chinese (Traditional)",
    "Corsican",
    "Croatian",
    "Czech",
    "Danish",
    "Dutch",
    "English",
    "English (Australia)",
    "English (Canada)",
    "English (India)",
    "English (New Zealand)",
    "English (South Africa)",
    "English (United Kingdom)",
    "English (United States)",
    "Esperanto",
    "Estonian",
    "Faroese",
    "Filipino",
    "Finnish",
    "French",
    "French (Canada)",
    "French (France)",
    "French (Switzerland)",
    "Galician",
    "Georgian",
    "German",
    "German (Austria)",
    "German (Germany)",
    "German (Liechtenstein)",
    "German (Switzerland)",
    "Greek",
    "Guarani",
    "Gujarati",
    "Hausa",
    "Hawaiian",
    "Hebrew",
    "Hindi",
    "Hungarian",
    "Icelandic",
    "Indonesian",
    "Interlingua",
    "Irish",
    "Italian",
    "Italian (Italy)",
    "Italian (Switzerland)",
    "Japanese",
    "Kannada",
    "Kazakh",
    "Khmer",
    "Korean",
    "Kurdish",
    "Kyrgyz",
    "Lao",
    "Latin",
    "Latvian",
    "Lingala",
    "Lithuanian",
    "Macedonian",
    "Malay",
    "Malayalam",
    "Maltese",
    "Marathi",
    "Mongolian",
    "Nepali",
    "Norwegian",
    "Norwegian Bokmål",
    "Norwegian Nynorsk",
    "Occitan",
    "Oriya",
    "Oromo",
    "Pashto",
    "Persian",
    "Polish",
    "Portuguese",
    "Portuguese (Brazil)",
    "Portuguese (Portugal)",
    "Punjabi",
    "Quechua",
    "Romanian",
    "Romanian (Moldova)",
    "Romansh",
    "Russian",
    "Scottish Gaelic",
    "Serbian",
    "Serbo",
    "Shona",
    "Sindhi",
    "Sinhala",
    "Slovak",
    "Slovenian",
    "Somali",
    "Southern Sotho",
    "Spanish",
    "Spanish (Argentina)",
    "Spanish (Latin America)",
    "Spanish (Mexico)",
    "Spanish (Spain)",
    "Spanish (United States)",
    "Sundanese",
    "Swahili",
    "Swedish",
    "Tajik",
    "Tamil",
    "Tatar",
    "Telugu",
    "Thai",
    "Tigrinya",
    "Tongan",
    "Turkish",
    "Turkmen",
    "Twi",
    "Ukrainian",
    "Urdu",
    "Uyghur",
    "Uzbek",
    "Vietnamese",
    "Walloon",
    "Welsh",
    "Western Frisian",
    "Xhosa",
    "Yiddish",
    "Yoruba",
    "Zulu",
  ]);

  const [userData, setUserData] = useState({
    images: [],
    phoneNumber: "+11",
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    preferredName: "",
    gender: "",
    connectWith: [],
    dateOfBirth: "",
    homeLocation: {},
    aboutMe: "",
    jobTitle: "",
    jobCompany: "",
    userSchool: "",
    userHeightCm: 0,
    interests: [],
    userLanguages: [],
    userTrips: [],
    userPlaces: [],
  });

  const [error, setError] = useState({
    images: "",
    phoneNumber: "",
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    preferredName: "",
    gender: "",
    connectWith:"",
    dateOfBirth: "",
    homeLocation: "",
  });

  const [isShowPopup, setIsShowPopup] = useState(false);
  const [isUserCreated, setIsUserCreated] = useState(false);

  const addTrip = (trip) => {
    const existingLocations = userData.userTrips;

    existingLocations.push({
      location: {
        type: "Point",
        coordinates: [trip.geometry.location.lng(), trip.geometry.location.lat()],
        name: trip.formatted_address,
      },
      googlePlacesId: trip.place_id,
      kind: "TRIP",
    });

    setUserData((oldData) => {
      oldData.userTrips = existingLocations;

      return { ...oldData };
    });
  };

  const validateInputFields = (fields) => {
    const requiredFields = [
      "images",
      "phoneNumber",
      "email",
      "password",
      "firstName",
      "lastName",
      "preferredName",
      "gender",
      "connectWith",
      "dateOfBirth",
      "homeLocation",
    ];

    let isSuccess = true;

    for (let key in fields) {
      if (requiredFields.includes(key)) {
        if (["images", "connectWith"].includes(key) && !fields[key].length) {
          setError({ ...error, [key]: `${key} is required!` });

          setToast({ color: "error", title: "error", content: `${key} is required!` });

          setIsShowPopup(true);

          isSuccess = false;

          break;
        } else if (
          [
            "phoneNumber",
            "email",
            "password",
            "firstName",
            "lastName",
            "preferredName",
            "gender",
            "dateOfBirth",
          ].includes(key) &&
          !fields[key]
        ) {
          setError({ ...error, [key]: `${key} is required!` });

          setToast({ color: "error", title: "error", content: `${key} is required!` });

          setIsShowPopup(true);

          isSuccess = false;

          break;
        } else if (key === "homeLocation" && !Object.keys(fields[key]).length) {
          setError({ ...error, [key]: `${key} is required!` });

          setToast({ color: "error", title: "error", content: `${key} is required!` });

          setIsShowPopup(true);

          isSuccess = false;

          break;
        }
      }
    }

    return isSuccess;
  };

  const createUser = async () => {
    const isValidationSuccessfull = validateInputFields(userData);

    if (isValidationSuccessfull) {
      try {
        await Services.createTestUser(userData);
        setIsUserCreated(true);
        setToast({
          color: "success",
          Title: "success",
          content: "Test user created successfully.",
        });
        setTimeout(() => {
          navigate("/interactions");
        }, 3000);
      } catch (err) {
        setToast({ color: "error", title: "Error", content: err.data.message });
      } finally {
        setIsShowPopup(true);
      }
    }
  };

  const onDeletePlaceOrTrip = (placeOrTripName, source) => {
    let updatedObject = {};

    if (source === "TRIPS") {
      updatedObject = {
        ...userData,
        userTrips: userData.userTrips.filter((trip) => trip.location.name !== placeOrTripName),
      };
    } else {
      updatedObject = {
        ...userData,
        userPlaces: userData.userPlaces.filter((place) => place.location.name !== placeOrTripName),
      };
    }

    setUserData(updatedObject);
  };

  const addPlace = (place) => {
    const existingLocations = userData.userPlaces;

    existingLocations.push({
      location: {
        type: "Point",
        coordinates: [place.geometry.location.lng(), place.geometry.location.lat()],
        name: place.formatted_address,
      },
      googlePlacesId: place.place_id,
      kind: "PLACE",
    });

    setUserData((oldData) => {
      oldData.userPlaces = existingLocations;

      return { ...oldData };
    });
  };

  const addImages = (imgUrl) => {
    const existingImages = userData.images;
    existingImages.push(imgUrl);
    setUserData((existingData) => {
      existingData.images = existingImages;
      return { ...userData };
    });
  };

  const uploadPhoto = async (base64String) => {
    const path = `${
      process.env.REACT_APP_API_URL.split("//")[1] || "no-server"
    }/images/${Date.now()}.jpg`;

    const storage = getStorage(firebaseApp);
    const file = await fetch(base64String).then((r) => r.blob());
    const storageRef = ref(storage, path);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {},
      (error) => {
        // Handle errors
      },
      async () => {
        const url = await getDownloadURL(storageRef);
        addImages(url);
      }
    );
  };

  const config2 = {
    borderRadius: "8px",
    language: "en",
    width: "100px",
    height: "100px",
    objectFit: "contain",
    compressInitial: null,
  };

  const initialImage = "";

  const { ref: tripsRef } = usePlacesWidget({
    apiKey: "AIzaSyCz34MFfsd9qjQmy80MSLbNpUeI2AR2Itk",
    onPlaceSelected: (place) => {
      tripsRef.current.value = "";

      addTrip(place);
    },
  });

  const { ref: placesRef } = usePlacesWidget({
    apiKey: "AIzaSyCz34MFfsd9qjQmy80MSLbNpUeI2AR2Itk",
    onPlaceSelected: (place) => {
      placesRef.current.value = "";
      addPlace(place);
    },
  });

  const { ref: homeLocationRef } = usePlacesWidget({
    apiKey: "AIzaSyCz34MFfsd9qjQmy80MSLbNpUeI2AR2Itk",
    onPlaceSelected: (place) => {
      setError({ ...error, homeLocation: "" });
      setUserData((existingData) => {
        existingData.homeLocation = {
          type: "Point",
          coordinates: [place.geometry.location.lng(), place.geometry.location.lat()],
          name: place.formatted_address,
          googlePlacesId: place.place_id,
        };

        return { ...existingData };
      });
    },
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <div>
        <MDSnackbar
          color={toast.color}
          icon="check"
          title={toast.title}
          content={toast.content}
          dateTime="now"
          open={isShowPopup}
          onClose={() => setIsShowPopup(false)}
          close={() => setIsShowPopup(false)}
          bgWhite
        />

        <MDBox
          mx={0}
          mt={3}
          py={3}
          px={2}
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
        >
          <MDTypography variant="h3" color="white">
            Test User - Create
          </MDTypography>
        </MDBox>
      </div>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={1.5}></Grid>
          <Grid item xs={9.5}>
            <Grid container>
              <Grid item xs={2}>
                <ReactImagePickerEditor
                  config={config2}
                  imageSrcProp={initialImage}
                  imageChanged={uploadPhoto}
                />
              </Grid>
              <Grid item xs={2}>
                <ReactImagePickerEditor
                  config={config2}
                  imageSrcProp={initialImage}
                  imageChanged={uploadPhoto}
                />
              </Grid>
              <Grid item xs={2}>
                <div style={{ alignSelf: "center" }}>
                  <ReactImagePickerEditor
                    config={config2}
                    imageSrcProp={initialImage}
                    imageChanged={uploadPhoto}
                  />
                </div>
              </Grid>

              <Grid item xs={2}>
                <ReactImagePickerEditor
                  config={config2}
                  imageSrcProp={initialImage}
                  imageChanged={uploadPhoto}
                />
              </Grid>
              <Grid item xs={2}>
                <ReactImagePickerEditor
                  config={config2}
                  imageSrcProp={initialImage}
                  imageChanged={uploadPhoto}
                />
              </Grid>
              <Grid item xs={2}>
                <ReactImagePickerEditor
                  config={config2}
                  imageSrcProp={initialImage}
                  imageChanged={uploadPhoto}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <MDInput
              onChange={(e) => {
                if (!e.target.value) {
                  setError({ ...error, phoneNumber: "Phone Number is required!" });
                } else {
                  setError({ ...error, phoneNumber: "" });
                }

                setUserData((existingData) => {
                  existingData.phoneNumber = e.target.value;
                  return { ...existingData };
                });
              }}
              type="numeric"
              label="Phone Number"
              variant="standard"
              fullWidth
              error={error.phoneNumber}
              value={userData.phoneNumber}
            />
          </Grid>
          <Grid item xs={4}>
            <MDInput
              onChange={(e) => {
                if (!e.target.value) {
                  setError({ ...error, email: "Email is required!" });
                } else {
                  setError({ ...error, email: "" });
                }

                setUserData((existingData) => {
                  existingData.email = e.target.value;
                  return { ...existingData };
                });
              }}
              type="email"
              label="Email"
              variant="standard"
              fullWidth
              error={error.email}
            />
          </Grid>
          <Grid item xs={4}>
            <MDInput
              onChange={(e) => {
                if (!e.target.value) {
                  setError({ ...error, password: "Password is required!" });
                } else {
                  setError({ ...error, password: "" });
                }

                setUserData((existingData) => {
                  existingData.password = sha256(`wander${e.target.value}app`);
                  return { ...existingData };
                });
              }}
              type="password"
              label="Password"
              variant="standard"
              fullWidth
              error={error.password}
            />
          </Grid>

          <Grid item xs={4}>
            <MDInput
              onChange={(e) => {
                if (!e.target.value) {
                  setError({ ...error, firstName: "First Name is required!" });
                } else {
                  setError({ ...error, firstName: "" });
                }

                setUserData((existingData) => {
                  existingData.firstName = e.target.value;
                  return { ...existingData };
                });
              }}
              type="text"
              label="First Name"
              variant="standard"
              fullWidth
              error={error.firstName}
            />
          </Grid>
          <Grid item xs={4}>
            <MDInput
              onChange={(e) => {
                if (!e.target.value) {
                  setError({ ...error, lastName: "Last Name is required!" });
                } else {
                  setError({ ...error, lastName: "" });
                }

                setUserData((existingData) => {
                  existingData.lastName = e.target.value;
                  return { ...existingData };
                });
              }}
              type="text"
              label="Last Name"
              variant="standard"
              fullWidth
              error={error.lastName}
            />
          </Grid>
          <Grid item xs={4}>
            <MDInput
              onChange={(e) => {
                if (!e.target.value) {
                  setError({ ...error, preferredName: "Preferred Name is required!" });
                } else {
                  setError({ ...error, preferredName: "" });
                }

                setUserData((existingData) => {
                  existingData.preferredName = e.target.value;
                  return { ...existingData };
                });
              }}
              type="text"
              label="Preferred Name"
              variant="standard"
              fullWidth
              error={error.preferredName}
            />
          </Grid>
          <Grid item xs={6}>
            <BootstrapSelect
              item="gender"
              itemsList={[
                { _id: 1, option: "Male" },
                { _id: 2, option: "Female" },
              ]}
              onReturn={(selectedItem) => {
                if (!selectedItem) {
                  setError({ ...error, gender: "Gender is required!" });
                }

                setUserData((existingData) => {
                  existingData.gender = selectedItem.option;
                  return { ...existingData };
                });
              }}
              style={{ fontSize: 15 }}
            />
          </Grid>
          <Grid item xs={6}>
            <BootstrapSelect
              item="genders to connect with"
              itemsList={[
                { _id: 1, option: "Male" },
                { _id: 2, option: "Female" },
                { _id: 3, option: "Non-Binary" },
              ]}
              onReturn={(selectedItem) => {
                if (!selectedItem) {
                  setError({ ...error, connectWith: "Connect with is required!" });
                }

                setUserData((existingData) => {
                  existingData.connectWith = selectedItem.map((item) => item.label);
                  return { ...existingData };
                });
              }}
              isMultiInput={true}
              style={{ fontSize: 15 }}
            />
          </Grid>
          <Grid item xs={4}>
            <MDInput
              onChange={(e) => {
                setUserData((existingData) => {
                  existingData.jobTitle = e.target.value;
                  return { ...existingData };
                });
              }}
              type="text"
              label="Job Title"
              variant="standard"
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <MDInput
              onChange={(e) => {
                setUserData((existingData) => {
                  existingData.jobCompany = e.target.value;
                  return { ...existingData };
                });
              }}
              type="text"
              label="Company"
              variant="standard"
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <MDInput
              onChange={(e) => {
                setUserData((existingData) => {
                  existingData.userSchool = e.target.value;
                  return { ...existingData };
                });
              }}
              type="text"
              label="School"
              variant="standard"
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <MDInput
              onChange={(e) => {
                setUserData((existingData) => {
                  existingData.aboutMe = e.target.value;
                  return { ...existingData };
                });
              }}
              type="text"
              label="About Me"
              variant="standard"
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <MDInput
              onChange={(e) => {
                if (!e.target.value) {
                  setError({ ...error, dateOfBirth: "Date of birth is required!" });
                } else {
                  setError({ ...error, dateOfBirth: "" });
                }

                setUserData((existingData) => {
                  existingData.dateOfBirth = e.target.value;
                  return { ...existingData };
                });
              }}
              onFocus={(e) => (e.target.type = "date")}
              onBlur={(e) => (e.target.type = "text")}
              label="Date Of Birth"
              type="text"
              variant="standard"
              fullWidth
              error={error.dateOfBirth}
            />
          </Grid>
          <Grid item xs={4}>
            <BootstrapSelect
              item="interests"
              itemsList={[
                { _id: 1, option: "Cooking" },
                { _id: 2, option: "Movies" },
                { _id: 3, option: "Space" },
                { _id: 4, option: "Sports" },
                { _id: 5, option: "Music" },
                { _id: 6, option: "Fitness" },
                { _id: 7, option: "History" },
                { _id: 8, option: "Video Games" },
                { _id: 9, option: "Star Wars" },
                { _id: 10, option: "Reading" },
                { _id: 11, option: "Fishing" },
                { _id: 12, option: "Cars" },
                { _id: 10, option: "Gardening" },
                { _id: 10, option: "Arts" },
                { _id: 10, option: "Animals" },
              ]}
              onReturn={(selectedItem) => {
                setUserData((existingData) => {
                  existingData.interests = selectedItem.map((item) => item.label);
                  return { ...existingData };
                });
              }}
              isMultiInput={true}
              style={{ fontSize: 15 }}
            />
          </Grid>
          <Grid item xs={4}>
            <BootstrapSelect
              item="languages"
              itemsList={allLanguages.map((l, index) => {
                return {
                  _id: index + 1,
                  option: l,
                };
              })}
              style={{ fontSize: 15 }}
              isMultiInput={true}
              onReturn={(selectedItem) => {
                setUserData((existingData) => {
                  existingData.userLanguages = selectedItem.map((item) => item.label);
                  return { ...existingData };
                });
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <BootstrapSelect
              item="height"
              itemsList={userHeightOptions.map((option, index) => {
                return {
                  _id: index + 1,
                  option: `${option} cm`,
                };
              })}
              style={{ fontSize: 15 }}
              onReturn={(selectedItem) => {
                const [number, garbage] = selectedItem.option.split(" ");

                setUserData((existingData) => {
                  existingData.userHeightCm = number;
                  return { ...existingData };
                });
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <MDTypography style={{ marginBottom: 5 }} variant="h6" fontWeight="medium">
              Select your trips
            </MDTypography>

            <Form.Control
              ref={tripsRef}
              type="text"
              id="inputHomeLocation"
              placeholder="Add location"
            />

            <MDBox pt={1} pb={2} px={2}>
              <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                {userData.userTrips &&
                  userData.userTrips.map((trip, index) => (
                    <DisplayLocation
                      key={index}
                      label="Trip"
                      locationName={trip?.location?.name}
                      locationArrival={trip?.arrivalAt}
                      locationDeparture={trip?.departureAt}
                      onDelete={(placeOrTripName) => onDeletePlaceOrTrip(placeOrTripName, "TRIPS")}
                    />
                  ))}
              </MDBox>
            </MDBox>
          </Grid>
          <Grid item xs={6}>
            <MDTypography style={{ marginBottom: 5 }} variant="h6" fontWeight="medium">
              Select places you've been
            </MDTypography>

            <Form.Control
              ref={placesRef}
              type="text"
              id="inputHomeLocation"
              aria-describedby="passwordHelpBlock"
              placeholder="Add location"
            />

            <MDBox pt={1} pb={2} px={2}>
              <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                {userData.userPlaces &&
                  userData.userPlaces.map((place, index) => (
                    <DisplayLocation
                      key={index}
                      label="Place"
                      locationName={place?.location?.name}
                      locationArrival={place?.arrivalAt}
                      locationDeparture={place?.departureAt}
                      onDelete={(placeOrTripName) => onDeletePlaceOrTrip(placeOrTripName, "PLACES")}
                    />
                  ))}
              </MDBox>
            </MDBox>
          </Grid>

          <Grid item xs={12}>
            <Form.Control
              ref={homeLocationRef}
              type="text"
              id="inputHomeLocation"
              aria-describedby="passwordHelpBlock"
              placeholder="Select Home Location"
              isInvalid={error.homeLocation}
            />
          </Grid>

          <Grid item xs={12}>
            <MDButton onClick={createUser} variant="gradient" color="info" fullWidth>
              Create Test User
            </MDButton>
          </Grid>
        </Grid>
      </MDBox>

      <Footer />
    </DashboardLayout>
  );
}

export default Tables;
