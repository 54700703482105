import { useEffect } from "react";
import useState from "react-usestateref";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import Accordion from "react-bootstrap/Accordion";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import BootstrapSelect from "components/BootstrapSelect";
import MDSnackbar from "components/MDSnackbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import RefreshIcon from "@mui/icons-material/Refresh";

import Services from "../../services";
import MuiDialog from "components/MuiDialog";

function Tables() {
  const navigate = useNavigate();

  const [isRefreshingAlgo, setIsRefreshingAlgo] = useState(false);
  const [isLoadingWanderers, setIsLoadingWanderers] = useState(false);
  const [isLoadingInteractionRequests, setIsLoadingInteractionRequests] = useState(false);
  const [wanderers, setWanderers, wanderersRef] = useState([]);
  const [interactionRequests, setInteractionRequests, interactionRef] = useState([]);
  const [interactionRequestsCount, setInteractionRequestsCount] = useState([]);
  const [interactionRequestsTotalCount, setInteractionRequestsTotalCount] = useState(0);
  const [isOpenWanderersListAccordion, setIsOpenWanderersListAccordion] = useState("");
  const [testUserNames, setTestUserNames] = useState([]);
  const [selectedUserName, setSelectedUserName] = useState("");
  const [selectedUserId, setSelectedUserId] = useState("");
  const [page, setPage, pageRef] = useState(1);
  const [searchItem, setSearchItem] = useState("");
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [directMessage, setDirectMessage] = useState("");
  const [directMessageRecieverId, setDirectMessageRecieverId] = useState("");
  const [directMessageSource, setDirectMessageSource] = useState("");
  const [show, setShow] = useState(false);
  const [isLoadingNames, setIsLoadingNames] = useState(false);
  const [isShowDialog, setIsShowDialog] = useState(false);
  const [error, setError] = useState({
    priorityLike: "",
    directMessage: "",
  });
  const [wanderersListColumns] = useState([
    { Header: "#", accessor: "serialNo", width: "5%", align: "left" },
    { Header: "Picture", accessor: "profilePicture", width: "5%", align: "center" },
    { Header: "Name", accessor: "name", width: "5%", align: "center" },
    { Header: "Action 1", accessor: "action1", width: "5%", align: "center" },
    { Header: "Action 2", accessor: "action2", width: "5%", align: "center" },
    { Header: "Action 3", accessor: "action3", width: "5%", align: "center" },
    { Header: "Action 4", accessor: "action4", width: "5%", align: "center" },
    { Header: "Action 5", accessor: "action5", width: "5%", align: "center" },
  ]);
  const [InteractionRequestsColumns] = useState([
    { Header: "#", accessor: "serialNo", width: "5%", align: "left" },
    { Header: "Picture", accessor: "profilePicture", width: "5%", align: "center" },
    { Header: "Name", accessor: "name", width: "5%", align: "center" },
    { Header: "Given", accessor: "given", width: "5%", align: "center" },
    { Header: "Action 1", accessor: "action1", width: "5%", align: "center" },
    { Header: "Action 2", accessor: "action2", width: "5%", align: "center" },
    { Header: "Action 3", accessor: "action3", width: "5%", align: "center" },
    { Header: "Action 4", accessor: "action4", width: "5%", align: "center" },
    { Header: "Action 5", accessor: "action5", width: "5%", align: "center" },
  ]);

  useEffect(() => {
    if (selectedUserId) {
      console.log("🚀 ~ file: index.js:91 ~ useEffect ~ selectedUserId:", selectedUserId);
      getSelectedUserData("selectedUserId");
    }
  }, [selectedUserId]);

  useEffect(() => {
    validateToken();
  });

  useEffect(() => {
    loadPreviousState();
  }, []);

  const validateToken = () => {
    if (!localStorage.getItem("token")) {
      navigate("/authentication/sign-in", { replace: true });
    }
  };

  const saveCurrentState = () => {
    sessionStorage.setItem(
      "interaction-list-previous-state",
      JSON.stringify({
        usersList: testUserNames,
        selectedUserName,
        selectedUserId,
      })
    );
  };

  const loadPreviousState = () => {
    let previousState = sessionStorage.getItem("interaction-list-previous-state");

    if (previousState) {
      previousState = JSON.parse(previousState);

      setTestUserNames(previousState.usersList);
      setSelectedUserName(previousState.selectedUserName);
      setSelectedUserId(previousState.selectedUserId);

      sessionStorage.removeItem("interaction-list-previous-state");
    } else {
      getAllTestUserNames();
    }
  };

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  const dislikeUser = async (wandererId, source) => {
    const body = {
      person: wandererId,
      isLiked: false,
    };

    try {
      await Services.postProfileView(selectedUserId, body);

      if (source === "action") {
        setWanderers(wanderersRef.current.filter((w) => w._id !== wandererId));
      } else {
        setInteractionRequests(interactionRef.current.filter((w) => w._id !== wandererId));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const likeUser = async (wandererId, source) => {
    const body = {
      person: wandererId,
      isLiked: true,
      swipeType: "LIKE",
    };

    try {
      await Services.postProfileView(selectedUserId, body);

      if (source === "action") {
        setWanderers(wanderersRef.current.filter((w) => w._id !== wandererId));
      } else {
        setInteractionRequests(interactionRef.current.filter((w) => w._id !== wandererId));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const priorityLikeUser = async (wandererId, source) => {
    const body1 = {
      likedTo: wandererId,
    };

    const body2 = {
      person: wandererId,
      isLiked: true,
      swipeType: "PRIORITY_LIKE",
    };

    try {
      await Services.postProfileLikes(selectedUserId, body1);

      if (source === "action") {
        setWanderers(wanderersRef.current.filter((w) => w._id !== wandererId));
      } else {
        setInteractionRequests(interactionRef.current.filter((w) => w._id !== wandererId));
      }

      await Services.postProfileView(selectedUserId, body2);
    } catch (err) {
      console.log(err);

      if (err.response.status === 403 && err.response.data.message.includes("Daily limit")) {
        setError({ ...error, priorityLike: err.response.data.message });
      }

      setIsShowPopup(true);
    }
  };

  const directMessageUser = async () => {
    const body1 = {
      toUserId: directMessageRecieverId,
      message: directMessage,
    };

    const body2 = {
      person: directMessageRecieverId,
      isLiked: true,
      swipeType: "DIRECT_MESSAGE",
    };

    try {
      await Services.postDirectMessage(selectedUserId, body1);

      if (directMessageSource === "action") {
        setWanderers(wanderersRef.current.filter((w) => w._id !== directMessageRecieverId));
      } else {
        setInteractionRequests(
          interactionRef.current.filter((w) => w._id !== directMessageRecieverId)
        );
      }

      await Services.postProfileView(selectedUserId, body2);
    } catch (err) {
      console.log(err);

      if (err.response.status === 403 && err.response.data.message.includes("Daily limit")) {
        setError({ ...error, directMessage: err.response.data.message });
      }

      setIsShowPopup(true);
    }
  };

  const getNotificationTitle = () => {
    let message = "";

    if (error.priorityLike || error.directMessage) {
      message = "Error";
    } else {
      message = "";
    }

    return message;
  };

  const getNotificationColor = () => {
    let message = "";

    if (error.priorityLike || error.directMessage) {
      message = "error";
    } else {
      message = "light";
    }

    return message;
  };

  const getAllTestUserNames = async () => {
    setIsLoadingNames(true);

    try {
      const { data } = await Services.getTestUsers();
      console.log("🚀 ~ file: index.js:242 ~ getAllTestUserNames ~ data:", data);

      setTestUserNames(
        data.map((u) => {
          return {
            _id: u._id,
            option: `(${u.algorithmWandererCount})(${u.testUserInteractionRequestCount}) - ${u.firstName} ${u.lastName} (${u.preferredName})`,
          };
        })
      );
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoadingNames(false);
    }
  };

  const getWanderersList = async () => {
    setIsLoadingWanderers(true);

    try {
      const { data } = await Services.getWanderersByUserId(selectedUserId);

      const mappedWanderers = data.map((wanderer, index) => ({
        serialNo: index + 1,
        _id: wanderer._id,
        profilePicture: wanderer.profilePicture ? (
          <img alt="dp" width={50} src={wanderer.profilePicture} />
        ) : (
          "(Not provided)"
        ),
        name: `${wanderer.preferredName}`,
        action1: (
          <MDButton
            onClick={() => dislikeUser(wanderer._id, "action")}
            variant="gradient"
            color="error"
            fullWidth
          >
            <ThumbDownOffAltIcon />
          </MDButton>
        ),
        action2: (
          <MDButton
            onClick={() => likeUser(wanderer._id, "action")}
            variant="gradient"
            color="success"
            fullWidth
          >
            <ThumbUpOffAltIcon />
          </MDButton>
        ),
        action3: (
          <MDButton
            onClick={() => priorityLikeUser(wanderer._id, "action")}
            variant="gradient"
            color="warning"
            fullWidth
          >
            <KeyboardDoubleArrowUpIcon />
          </MDButton>
        ),
        action4: (
          <MDButton
            onClick={() => {
              setDirectMessageRecieverId(wanderer._id);
              setDirectMessageSource("action");
              handleShow();
            }}
            variant="gradient"
            color="secondary"
            fullWidth
          >
            <ForwardToInboxIcon />
          </MDButton>
        ),
        action5: (
          <MDButton
            onClick={() => {
              saveCurrentState();
              navigate(`/profile/${wanderer._id}`, { state: { userId: wanderer._id } });
            }}
            variant="outlined"
            color="info"
            fullWidth
          >
            View
          </MDButton>
        ),
      }));

      setIsOpenWanderersListAccordion("wl-accordion");
      setWanderers(mappedWanderers);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoadingWanderers(false);
    }
  };

  const getInteractionRequests = async () => {
    setIsLoadingInteractionRequests(true);

    try {
      const { data } = await Services.getInteractionRequests(selectedUserId, pageRef.current);

      const mappedInteractionRequests = data.interactionRequests.map((wanderer) => ({
        serialNo: wanderer.serialNo,
        _id: wanderer._id,
        profilePicture: wanderer.profilePicture ? (
          <img alt="dp" width={50} src={wanderer.profilePicture} />
        ) : (
          "(Not provided)"
        ),
        name: `${wanderer.preferredName}`,
        given: `${wanderer.interaction}`,
        action1: (
          <MDButton
            onClick={() => dislikeUser(wanderer._id, "reAction")}
            variant="gradient"
            color="error"
            fullWidth
          >
            <ThumbDownOffAltIcon />
          </MDButton>
        ),
        action2: (
          <MDButton
            onClick={() => likeUser(wanderer._id, "reAction")}
            variant="gradient"
            color="success"
            fullWidth
          >
            <ThumbUpOffAltIcon />
          </MDButton>
        ),
        action3: (
          <MDButton
            onClick={() => priorityLikeUser(wanderer._id, "reAction")}
            variant="gradient"
            color="warning"
            fullWidth
          >
            <KeyboardDoubleArrowUpIcon />
          </MDButton>
        ),
        action4: (
          <MDButton
            onClick={() => {
              setDirectMessageRecieverId(wanderer._id);
              setDirectMessageSource("reAction");
              handleShow();
            }}
            variant="gradient"
            color="secondary"
            fullWidth
          >
            <ForwardToInboxIcon />
          </MDButton>
        ),
        action5: (
          <MDButton
            onClick={() => {
              navigate(`/profile/${wanderer._id}`, { state: { userId: wanderer._id } });
            }}
            variant="outlined"
            color="info"
            fullWidth
          >
            View
          </MDButton>
        ),
      }));

      let initialValue = (parseInt(pageRef.current) - 1) * 10;

      for (let request of mappedInteractionRequests) {
        initialValue++;

        request.serialNo = initialValue;
      }

      setInteractionRequests(mappedInteractionRequests);

      setInteractionRequestsCount(mappedInteractionRequests.length);

      setInteractionRequestsTotalCount(data.totalCount);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoadingInteractionRequests(false);
    }
  };

  const getSelectedUserData = async (source) => {
    getWanderersList();

    if (source === "selectedUserId") {
      getInteractionRequests();
    }
  };

  const refreshUserData = async () => {
    if (selectedUserId) {
      setIsRefreshingAlgo(true);

      try {
        await Services.refreshWanderers(selectedUserId);

        getSelectedUserData("refreshUserData");
      } catch (err) {
        console.log(err);
      } finally {
        setIsRefreshingAlgo(false);
      }
    }
  };

  const refreshTestUsers = async () => {
    try {
      console.log("🚀 ~ file: index.js:459 ~ refreshTestUsers ~ refreshTestUsers:");

      const res = await Services.refreshTestUsers();
      console.log("🚀 ~ file: index.js:459 ~ refreshTestUsers ~ res:", res.data);
    } catch (error) {
      console.log("🚀 ~ file: index.js:465 ~ refreshTestUsers ~ error:", error);
    }
  };

  const getTitle = () => {
    return <div>Refresh Algorithm</div>;
  };

  const getContent = () => {
    return <div>Are you sure you want to refresh algorithm for all users?</div>;
  };

  return (
    <DashboardLayout>
      <MuiDialog
        isShowDialog={isShowDialog}
        dialogTitle={getTitle()}
        dialogContent={getContent()}
        cancelTitle={"No"}
        onCancel={() => {
          setIsShowDialog(false);
        }}
        confirmTitle={"Yes"}
        onConfirm={() => {
          setIsShowDialog(false);
          refreshTestUsers();
        }}
      />
      <DashboardNavbar
        getSearchItem={(value) => {
          setSearchItem(value);
        }}
      />

      <div>
        <Modal style={{ marginTop: "10%" }} show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Direct Message</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Label>Type your direct message here</Form.Label>
                <Form.Control
                  onChange={(e) => {
                    setDirectMessage(e.target.value);
                  }}
                  as="textarea"
                  rows={3}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                directMessageUser();
                handleClose();
              }}
            >
              Send Direct Message
            </Button>
          </Modal.Footer>
        </Modal>
        <MDBox
          mx={0}
          mt={3}
          py={3}
          px={2}
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
        >
          <MDTypography variant="h3" color="white">
            Test User - Interactions
          </MDTypography>
          <div style={{ display: "flex", alignItems: "center" }}>
            <MDButton
              onClick={() => setIsShowDialog(true)}
              style={{ marginRight: 5, color: "white", width: "180px", height: 46 }}
              variant="gradient"
              color="error"
            >
              Refresh Algorithm
            </MDButton>
            {isRefreshingAlgo ? (
              <span style={{ width: "150px" }}>
                <div
                  style={{ color: "white", marginLeft: 60 }}
                  className="spinner-border spinner-border-sm ml-1"
                  role="status"
                  aria-hidden="true"
                ></div>
              </span>
            ) : selectedUserId ? (
              <MDButton
                onClick={refreshUserData}
                style={{ marginRight: 5, color: "white", width: "150px", height: 46 }}
                variant="gradient"
                color="secondary"
              >
                Refresh Algo
              </MDButton>
            ) : null}
            <BootstrapSelect
              isLoading={isLoadingNames}
              item="User"
              itemsList={testUserNames}
              style={{ width: 300 }}
              onReturn={(selectedItem) => {
                setSelectedUserId(selectedItem._id);
                setSelectedUserName(selectedItem.option);
              }}
              value={selectedUserId ? selectedUserId : null}
            />

            <MDButton
              onClick={getAllTestUserNames}
              style={{ marginLeft: 10, color: "white", width: "20px", height: 46 }}
              variant="gradient"
              color="secondary"
              disabled={isLoadingNames}
            >
              <RefreshIcon />
            </MDButton>
          </div>
        </MDBox>

        <MDSnackbar
          color={getNotificationColor()}
          icon="check"
          title={getNotificationTitle()}
          content={error.priorityLike || error.directMessage}
          dateTime="now"
          open={isShowPopup}
          onClose={() => setIsShowPopup(false)}
          close={() => setIsShowPopup(false)}
          bgWhite
        />
      </div>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <Accordion activeKey={isOpenWanderersListAccordion}>
                <Accordion.Item style={{ borderRadius: 15 }} eventKey="wl-accordion">
                  <Accordion.Header
                    onClick={() =>
                      setIsOpenWanderersListAccordion(
                        isOpenWanderersListAccordion === "none" ? "wl-accordion" : "none"
                      )
                    }
                  >
                    Wanderers List{" "}
                    {isLoadingWanderers ? (
                      <span
                        style={{ marginLeft: 5 }}
                        className="spinner-border spinner-border-sm ml-1"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      `(${wanderers.length})`
                    )}{" "}
                  </Accordion.Header>
                  <Accordion.Body>
                    <MDBox pt={3}>
                      <DataTable
                        table={{
                          columns: wanderersListColumns,
                          rows: wanderers,
                        }}
                        isSorted={false}
                        entriesPerPage={false}
                        showTotalEntries={false}
                        noEndBorder
                      />
                    </MDBox>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <Accordion>
                <Accordion.Item style={{ borderRadius: 15 }} eventKey="ir-accordion">
                  <Accordion.Header>
                    Interaction Requests{" "}
                    {isLoadingInteractionRequests ? (
                      <span
                        style={{ marginLeft: 5 }}
                        className="spinner-border spinner-border-sm ml-1"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      `(${interactionRequestsTotalCount})`
                    )}
                  </Accordion.Header>
                  <Accordion.Body>
                    <MDBox pt={3}>
                      <DataTable
                        table={{
                          columns: InteractionRequestsColumns,
                          rows: interactionRequests,
                        }}
                        isSorted={false}
                        entriesPerPage={false}
                        showTotalEntries={false}
                        noEndBorder
                      />
                    </MDBox>
                    {interactionRequests.length === 0 ? null : (
                      <MDBox
                        mx={2}
                        py={3}
                        px={2}
                        variant="gradient"
                        borderRadius="lg"
                        align={"center"}
                      >
                        {page === 1 ? null : (
                          <MDButton
                            onClick={() => {
                              setPage(pageRef.current - 1);
                              getInteractionRequests();
                            }}
                            variant="outlined"
                            color="secondary"
                          >
                            Previous Page
                          </MDButton>
                        )}
                        &nbsp; &nbsp;
                        {interactionRequestsCount < 10 ? null : (
                          <MDButton
                            onClick={() => {
                              setPage(pageRef.current + 1);
                              getInteractionRequests();
                            }}
                            variant="outlined"
                            color="secondary"
                          >
                            Next Page
                          </MDButton>
                        )}
                      </MDBox>
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Tables;
