export const LANGUAGE_STATUS = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
};

export const CHAT_STATUS = {
  ACTIVE: "ACTIVE",
  SNOOZED: "SNOOZED",
  BLOCKED: "BLOCKED",
  DELETED: "DELETED",
  UNMATCHED: "UNMATCHED",
  DIRECT_MESSAGE: "DIRECT_MESSAGE",
};

export const LANGUAGE_SCREEN_DIALOG_MODES = {
  LANGUAGE: "LANGUAGE",
  DELETE_LANGUAGE: "DELETE_LANGUAGE",
  LANGUAGE_MAPPING: "LANGUAGE_MAPPING",
  NEW_KEYWORD: "NEW_KEYWORD",
  DELETE_KEYWORD: "DELETE_KEYWORD",
};

export const HOTSPOT_SCREEN_DIALOG_MODES = {
  CREATE_HOTSPOT: "CREATE_HOTSPOT",
  UPDATE_HOTSPOT: "UPDATE_HOTSPOT",
  ACTIVATE_HOTSPOT: "ACTIVATE_HOTSPOT",
  ARCHIVE_HOTSPOT: "ARCHIVE_HOTSPOT",
};

export const HOTSPOT_STATUS = {
  ACTIVE: "ACTIVE",
  ARCHIVED: "ARCHIVED",
};