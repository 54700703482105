import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import _ from "lodash";
import addIcon from "../../assets/images/add_icon.png";
import trash_icon from "../../assets/images/trash_icon.png";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { pascalCase } from "pascal-case";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { useNavigate } from "react-router-dom";

import MuiDialog from "components/MuiDialog";
import MDSnackbar from "components/MDSnackbar";

import Services from "../../services";

import { HOTSPOT_SCREEN_DIALOG_MODES, HOTSPOT_STATUS } from "../../constants";
import MDTextArea from "components/MDTextArea";
import MDTextAreaWithCloseButton from "components/MDTextAreaWithCloseButton";

function Hotspots() {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);

  const [searchItem, setSearchItem] = useState("");
  const [isShowModal, setIsShowModal] = useState(false);
  const [focusedItem, setFocusedItem] = useState({});

  const [name, setName] = useState("");
  const [country, setCountry] = useState("");
  const [interestingFacts, setInterestingFacts] = useState("");
  const [timezone, setTimezone] = useState("");

  const [modalMode, setModalMode] = useState("");
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [isSuccess, setIsSuccess] = useState(true);
  const [successMessage, setSuccessMessage] = useState("");
  const [isFailure, setIsFailure] = useState(false);
  const [failureMessage, setFailureMessage] = useState("");

  const [columns] = useState([
    { Header: "#", accessor: "serialNo", width: "5%", align: "left" },
    { Header: "Name", accessor: "name", width: "30%", align: "center" },
    { Header: "Image", accessor: "headerImage", width: "30%", align: "center" },
    {
      Header: "Weather Last Updated At",
      accessor: "weatherLastUpdatedAt",
      width: "30%",
      align: "center",
    },
    { Header: "Actions", accessor: "actions", width: "15%", align: "center" },
  ]);

  const { CREATE_HOTSPOT, UPDATE_HOTSPOT, ACTIVATE_HOTSPOT, ARCHIVE_HOTSPOT } =
    HOTSPOT_SCREEN_DIALOG_MODES;

  const getNotificationColor = () => {
    let message = "";

    if (isSuccess) {
      message = "success";
    } else if (isFailure) {
      message = "error";
    }

    return message;
  };

  const getNotificationTitle = () => {
    let message = "";

    if (isSuccess) {
      message = "Success";
    } else if (isFailure) {
      message = "Error";
    }

    return message;
  };

  const validateToken = () => {
    if (!localStorage.getItem("token")) {
      navigate("/authentication/sign-in", { replace: true });
    }
  };

  const getHotspots = async () => {
    setIsLoading(true);

    try {
      const {
        data: { data: hotspots },
      } = await Services.getHotspots();

      setRows(
        hotspots.map((item, index) => ({
          serialNo: index + 1,
          name: item.name,
          headerImage: <img alt="dp" width={50} src={item.headerImage} />,
          weatherLastUpdatedAt: item.weatherLastUpdatedAt,
          actions: (
            <div
              style={{
                width: 250,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <MDButton
                onClick={() => {
                  setModalMode(HOTSPOT_SCREEN_DIALOG_MODES.UPDATE_HOTSPOT);

                  setFocusedItem(item);

                  setIsShowModal(true);
                }}
                variant="outlined"
                color="info"
              >
                View/Edit
              </MDButton>

              <MDButton
                onClick={() => {
                  setModalMode(
                    item.status === HOTSPOT_STATUS.ACTIVE ? ARCHIVE_HOTSPOT : ACTIVATE_HOTSPOT
                  );
                  setIsShowModal(true);
                  setFocusedItem(item);
                }}
                variant="outlined"
                color={item.status === HOTSPOT_STATUS.ACTIVE ? "error" : "success"}
              >
                {item.status === HOTSPOT_STATUS.ACTIVE ? "Archive" : "Activate"}
              </MDButton>
            </div>
          ),
        }))
      );
    } catch (err) {
      setIsSuccess(false);
      setIsFailure(true);
      setFailureMessage(err.message);
      setIsShowPopup(true);
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  };

  const getSearchedItems = (query) => {
    query = query.toLowerCase();

    const languageMappings = rowsBackup;

    setRows(
      languageMappings
        .filter((item) => {
          const valuesArray = Object.values(item.value);

          const getSearchedValue = (query) => {
            let result = false;

            valuesArray.forEach((item) => {
              if (item.toLowerCase().includes(query)) {
                result = true;
              }
            });

            return result;
          };

          return getSearchedValue(query) || item.key.toLowerCase().includes(query);
        })
        .map((item) => {
          return {
            ...item,
            key: <div style={{ width: 300 }}>{item.key}</div>,
            engValue: <div style={{ width: 300 }}>{item.engValue}</div>,
          };
        })
    );
  };

  useEffect(() => {
    validateToken();
  });

  useEffect(() => {
    getHotspots();
  }, []);

  // useEffect(() => {
  //   getSearchedItems(searchItem);
  // }, [searchItem]);

  const handleCancel = () => {
    setIsShowModal(false);
    setEditDialogTitle("");
  };

  const handleCreateHotspot = async () => {
    try {
      const {
        data: { message },
      } = await Services.createHotspot({
        body: focusedItem,
      });

      setSuccessMessage(message);

      setIsShowModal(false);

      getHotspots();
    } catch (err) {
      setIsSuccess(false);
      setIsFailure(true);
      setFailureMessage(err.message);
    } finally {
      setIsShowPopup(true);

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  };

  const handleUpdateHotspot = async () => {
    const { _id: hotspotId } = focusedItem;

    try {
      const {
        data: { message },
      } = await Services.updateHotspot({
        params: { hotspotId },
        body: focusedItem,
      });

      setSuccessMessage(message);

      setIsShowModal(false);

      getHotspots();
    } catch (err) {
      setIsSuccess(false);
      setIsFailure(true);
      setFailureMessage(err.message);
    } finally {
      setIsShowPopup(true);

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  };

  const handleActiveOrArchiveHotspot = async () => {
    const { _id: hotspotId } = focusedItem;

    try {
      const {
        data: { message },
      } = await Services.updateHotspot({
        params: { hotspotId },
        body: {
          status:
            focusedItem.status === HOTSPOT_STATUS.ACTIVE
              ? HOTSPOT_STATUS.ARCHIVED
              : HOTSPOT_STATUS.ACTIVE,
        },
      });

      setSuccessMessage(message);

      getHotspots();
    } catch (err) {
      setIsSuccess(false);
      setIsFailure(true);
      setFailureMessage(err.message);
    } finally {
      setIsShowModal(false);
      setIsShowPopup(true);

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  };

  const handleInputChange = (index, event) => {
    const values = [...focusedItem.interestingFacts];

    values[index] = event.target.value;

    setFocusedItem((prevState) => {
      const updatedState = { ...prevState, interestingFacts: values };

      return updatedState;
    });
  };

  const handleRemoveInterestingFact = (fieldIndex) => {
    let interestingFacts = [...focusedItem.interestingFacts];

    interestingFacts = interestingFacts.filter((item, index) => index !== fieldIndex);

    setFocusedItem((prevState) => {
      const updatedState = { ...prevState, interestingFacts };

      return updatedState;
    });
  };

  const getTitle = () => {
    switch (modalMode) {
      case CREATE_HOTSPOT:
        return "New Hotspot";

      case UPDATE_HOTSPOT:
        return "Edit Hotspot";

      case ACTIVATE_HOTSPOT:
      case ARCHIVE_HOTSPOT:
        return focusedItem.status === HOTSPOT_STATUS.ACTIVE
          ? "Archive Hotspot"
          : "Activate Hotspot";
    }
  };

  const getContent = () => {
    switch (modalMode) {
      case CREATE_HOTSPOT:

      case UPDATE_HOTSPOT:
        return (
          <div>
            <Grid
              style={{ display: "flex", justifyContent: "space-between" }}
              container
              spacing={2}
            >
              <Grid item xs={6}>
                <h6 style={{ margin: 0 }}>Name</h6>
              </Grid>
              <Grid item xs={6}>
                <MDInput
                  style={{ width: 250 }}
                  size="small"
                  onChange={(e) => {
                    setFocusedItem((prevState) => {
                      const updatedState = {
                        ...prevState,
                        name: e.target.value,
                      };

                      return updatedState;
                    });
                  }}
                  value={focusedItem.name}
                />
              </Grid>
              <Grid item xs={6}>
                <h6 style={{ margin: 0 }}>Country</h6>
              </Grid>
              <Grid item xs={6}>
                <MDInput
                  style={{ width: 250 }}
                  size="small"
                  onChange={(e) => {
                    setFocusedItem((prevState) => {
                      const updatedState = {
                        ...prevState,
                        country: e.target.value,
                      };

                      return updatedState;
                    });
                  }}
                  value={focusedItem.country}
                />
              </Grid>
              <Grid item xs={6}>
                <h6 style={{ margin: 0 }}>Coordinates</h6>
              </Grid>
              <Grid item xs={6}>
                <MDTextArea
                  style={{ width: 250 }}
                  value={
                    focusedItem.polygon.coordinates
                      ? JSON.stringify(focusedItem.polygon.coordinates)
                      : ""
                  }
                  onChange={(e) => {
                    // debugger;
                    setFocusedItem((prevState) => {
                      const updatedState = {
                        ...prevState,
                        polygon: { type: "Polygon", coordinates: JSON.parse(e.target.value) },
                      };
                      // debugger;
                      return updatedState;
                    });
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <h6 style={{ margin: 0 }}>Description</h6>
              </Grid>
              <Grid item xs={6}>
                <MDTextArea
                  onChange={(e) => {
                    setFocusedItem((prevState) => {
                      const updatedState = {
                        ...prevState,
                        description: e.target.value,
                      };

                      return updatedState;
                    });
                  }}
                  value={focusedItem.description}
                  style={{ width: 250 }}
                />
              </Grid>
              <Grid item xs={6}>
                <h6 style={{ margin: 0 }}>Image URL</h6>
              </Grid>
              <Grid item xs={6}>
                <MDTextArea
                  onChange={(e) => {
                    setFocusedItem((prevState) => {
                      const updatedState = {
                        ...prevState,
                        headerImage: e.target.value,
                      };

                      return updatedState;
                    });
                  }}
                  value={focusedItem.headerImage}
                  style={{ width: 250 }}
                />
              </Grid>
              <Grid item xs={6}>
                <h6 style={{ margin: 0 }}>Interesting Facts</h6>
              </Grid>
              <Grid item xs={6}>
                <div>
                  {focusedItem.interestingFacts.map((item, index) => (
                    <div key={index}>
                      <MDTextAreaWithCloseButton
                        fieldIndex={index}
                        onChange={(e) => handleInputChange(index, e)}
                        onRemove={handleRemoveInterestingFact}
                        value={item}
                        style={{ width: 250 }}
                      />
                    </div>
                  ))}

                  <img
                    onClick={handleAddInterestingFactsField}
                    style={{ padding: 0, margin: 0, marginLeft: 5, cursor: "pointer" }}
                    width={20}
                    height={20}
                    src={addIcon}
                    alt=""
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <h6 style={{ margin: 0 }}>Timezone</h6>
              </Grid>
              <Grid item xs={6}>
                <MDInput
                  style={{ width: 250 }}
                  size="small"
                  onChange={(e) => {
                    setFocusedItem((prevState) => {
                      const updatedState = {
                        ...prevState,
                        timezone: e.target.value,
                      };

                      return updatedState;
                    });
                  }}
                  value={focusedItem.timezone}
                />
              </Grid>
            </Grid>
          </div>
        );

      case ACTIVATE_HOTSPOT:
      case ARCHIVE_HOTSPOT:
        return (
          <div>
            Are you sure? You want to{" "}
            {focusedItem.status === HOTSPOT_STATUS.ACTIVE ? "archive" : "activate"} this hotspot?
          </div>
        );
    }
  };

  const getConfirmTitle = () => {
    switch (modalMode) {
      case CREATE_HOTSPOT:
        return "Create";

      case UPDATE_HOTSPOT:
        return "Update";

      case ACTIVATE_HOTSPOT:
      case ARCHIVE_HOTSPOT:
        return focusedItem.status === HOTSPOT_STATUS.ACTIVE ? "Archive" : "Activate";
    }
  };

  const getCancelTitle = () => {
    return "Cancel";
  };

  const onConfirm = () => {
    switch (modalMode) {
      case CREATE_HOTSPOT:
        return handleCreateHotspot();
      case UPDATE_HOTSPOT:
        return handleUpdateHotspot();
      case ACTIVATE_HOTSPOT:
      case ARCHIVE_HOTSPOT:
        return handleActiveOrArchiveHotspot();
    }
  };

  const handleAddInterestingFactsField = () => {
    setFocusedItem((prevState) => {
      const updatedInterestingFacts = [...prevState.interestingFacts, ""];

      const updatedState = { ...prevState, interestingFacts: updatedInterestingFacts };

      return updatedState;
    });
  };

  return (
    <DashboardLayout>
      <MuiDialog
        isShowDialog={isShowModal}
        dialogTitle={getTitle()}
        dialogContent={getContent()}
        confirmTitle={getConfirmTitle()}
        cancelTitle={getCancelTitle()}
        onCancel={handleCancel}
        onConfirm={onConfirm}
        isDoubleAction={true}
      />

      <MDSnackbar
        color={getNotificationColor()}
        icon="check"
        title={getNotificationTitle()}
        content={isSuccess ? successMessage : failureMessage}
        dateTime="now"
        open={isShowPopup}
        onClose={() => setIsShowPopup(false)}
        close={() => setIsShowPopup(false)}
        bgWhite
      />

      <DashboardNavbar
        value={searchItem}
        getSearchItem={(value) => {
          setSearchItem(value);
        }}
      />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <div className="d-flex flex-row justify-content-between">
                  <MDTypography variant="h6" color="white">
                    Hotspots
                    {isLoading ? (
                      <span
                        style={{ marginLeft: 5 }}
                        className="spinner-border spinner-border-sm ml-1"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : null}
                  </MDTypography>
                  <MDButton
                    onClick={() => {
                      setModalMode(HOTSPOT_SCREEN_DIALOG_MODES.CREATE_HOTSPOT);

                      setFocusedItem({
                        name: "",
                        country: "",
                        polygon: { type: "Polygon", coordinates: "" },
                        description: "",
                        headerImage: "",
                        interestingFacts: [],
                        timezone: "",
                      });

                      setIsShowModal(true);
                    }}
                    variant="contained"
                    color="danger"
                  >
                    Create
                  </MDButton>
                </div>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{
                    columns,
                    rows,
                  }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Hotspots;
