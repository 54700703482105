import React from "react";
import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import BootstrapSelect from "components/BootstrapSelect";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import Services from "../../services";
import { DoneOutlined, Clear } from "@mui/icons-material";
import { async } from "@firebase/util";
import moment from "moment";
import MDInput from "../../components/MDInput";
import { Col, Row } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Switch from "@mui/material/Switch";

function Campaigns() {
  const navigate = useNavigate();
  const [selectedUserId, setSelectedUserId] = useState("");
  const [selectedUser, setSelectedUser] = useState();

  const [isLoading, setIsLoading] = useState(false);
  const [wanderers, setWanderers] = useState([]);
  const [directMessages, setDirectMessages] = useState([]);
  const [chats, setChats] = useState([]);
  const [page, setPage] = useState(1);
  const [searchItem, setSearchItem] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [CampaignsListColumns] = useState([
    { Header: "#", accessor: "serialNo", width: "2%", align: "left" },
    { Header: "Title", accessor: "title", width: "10%", align: "center" },
    { Header: "Kind", accessor: "kind", width: "10%", align: "center" },
    { Header: "Trial Days", accessor: "trialDays", width: "10%", align: "center" },
    { Header: "Start Date", accessor: "startDate", width: "10%", align: "center" },
    { Header: "End Date", accessor: "endDate", width: "10%", align: "center" },
    { Header: "Active", accessor: "status", width: "10%", align: "center" },
    { Header: "Action", accessor: "action", width: "10%", align: "center" },
  ]);

  const [campaignsList, setCampaignsList] = useState([]);

  useEffect(() => {
    getCampaign(startDate, endDate);
  }, []);

  const changeStatus = async (data, id) => {
    const res = await Services.editCampaignStatus(id, data);

    if (res.status == 200) {
      getCampaign(startDate, endDate);
    }
  };

  const deleteCampaign = async (id) => {
    const msg = `Are you sure you want to delete?`;

    const confirmed = window.confirm(msg);
    if (confirmed) {
      const res = await Services.deleteCampaignStatus(id);

      if (res.status == 200) {
        getCampaign(startDate, endDate);
      }
    }
  };

  const getCampaign = async (fromDate, toDate) => {
    try {
      const res = await Services.getCampaign(fromDate, toDate);

      if (res.status == 200) {
        let { data } = res;

        const list = data.map((item, index) => ({
          serialNo: index + 1,
          title: item.title,
          kind: item.kind,
          trialDays: item.trialDays,
          startDate: moment(item.campaignStartDate).format("DD-MM-YYYY"),
          endDate: moment(item.campaignEndDate).format("DD-MM-YYYY"),
          status: (
            <Switch
              color="secondary"
              onClick={() =>
                changeStatus(
                  {
                    title: item.title,
                    status: item.status == "ACTIVE" ? "INACTIVE" : "ACTIVE",
                    kind: item.kind,
                    trialDays: item.trialDays,
                  },
                  item._id
                )
              }
              defaultChecked={item.status == "ACTIVE"}
            />
          ),

          action: (
            <Dropdown>
              <Dropdown.Toggle
                as={React.forwardRef(({ children, onClick }, ref) => (
                  <a
                    href=""
                    ref={ref}
                    onClick={(e) => {
                      e.preventDefault();
                      onClick(e);
                    }}
                  >
                    <MoreVertIcon />
                  </a>
                ))}
                id="dropdown-basic"
              >
                Dropdown Button
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    navigate("/campaigns/detail", { state: { campaign: item } });
                  }}
                >
                  View
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    deleteCampaign(item?._id);
                  }}
                >
                  Delete
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          ),
        }));
        setCampaignsList(list);
      }
    } catch (error) {
      console.log("🚀 ~ file: index.js:90 ~  ~ error:", error);
    }
  };

  const validateToken = () => {
    if (!localStorage.getItem("token")) {
      navigate("/authentication/sign-in", { replace: true });
    }
  };

  useEffect(() => {
    validateToken();
  });

  return (
    <DashboardLayout>
      <DashboardNavbar
        getSearchItem={(value) => {
          setSearchItem(value);
        }}
      />

      <div>
        <MDBox
          mx={0}
          mt={3}
          py={3}
          px={2}
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
        >
          <MDTypography variant="h3" color="white">
            Campaigns
          </MDTypography>
          <Row className="align-items-center">
            {/* <Col>
              <MDInput
                onChange={(e) => {
                  setStartDate(moment(e.target.value, "YYYY-MM-dd").format("DD-MM-YY"));
                }}
                label="Start Date"
                type="datetime"
                variant="outlined"
                onFocus={(e) => (e.target.type = "date")}
                onBlur={(e) => (e.target.type = "text")}
              />
            </Col>
            <Col>
              <MDInput
                onChange={(e) => {
                  setEndDate(moment(e.target.value, "YYYY-MM-dd").format("DD-MM-YY"));
                }}
                label="End Date"
                type="datetime"
                variant="outlined"
                onFocus={(e) => (e.target.type = "date")}
                onBlur={(e) => (e.target.type = "text")}
              />
            </Col>
            <Col className="text-end col-auto">
              <MDButton
                onClick={() => {
                  getCampaign(startDate, endDate);
                }}
                variant="contained"
                color="info"
              >
                Search
              </MDButton>
            </Col> */}
          </Row>
        </MDBox>
      </div>

      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3}>
                <DataTable
                  table={{
                    columns: CampaignsListColumns,
                    rows: campaignsList,
                  }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Campaigns;
