import React, { useContext, useEffect, useRef, useState } from "react";
import Receiver from "./components/Receiver";
import Sender from "./components/Sender";
import SendMessage from "./components/SendMessage";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import { useLocation } from "react-router-dom";
import Services from "../../services";
import { SocketContext } from "services/SocketProvider";

const SupportChat = () => {
  const location = useLocation();
  const socket = useContext(SocketContext);

  const chatData = location.state.chatData;
  const supportUserId = process.env.REACT_APP_SUPPORT_ID;

  const user = location.state.user;
  console.log("chatData", chatData);

  const [chatList, setChatList] = useState([]);
  const [filteredChatList, setFilteredChatList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [unreadOnly, setUnreadOnly] = useState(false);

  const [beforeTime, setBeforeTime] = useState();

  if (!chatData || !user) {
    console.error("Chat data or user data is missing");
    return null; // or render some error message or fallback UI
  }

  useEffect(() => {
    messagesList();
  }, []);

  const mapMessage = (msg) => {
    let createdBy = {};
    if (msg.createdBy) {
      createdBy = msg.createdBy;
    } else {
      createdBy = {
        profilePicture: chatData?.profilePictureToShow,
        preferredName: chatData?.chatNameToShow,
      };
    }

    msg = { ...msg, createdBy };
    return msg;
  };

  useEffect(() => {
    if (!socket) {
      console.error("Socket is not defined");
      return;
    }

    socket.on("support-message", (message) => {
      console.log("support-message", message);

      if (message?.chat === chatData?._id) {
        setChatList((prevChatList) => [...prevChatList, mapMessage(message)]);
        scrollToBottom();
      }
    });

    return () => {
      if (socket) {
        socket.off("support-message");
      }
    };
  }, [socket]);

  const messagesList = async (before = "") => {
    setLoading(true); // Start loading
    if (before) {
      before = `?before=${before}`;
    }
    let oldestLoadedMessageTS = 2147483647000;
    if (chatList && chatList.length) {
      oldestLoadedMessageTS = chatList[0].createdAtTimestamp; // Changed to get the oldest message
    }
    const res = await Services.getSupportChatMessages(
      { chatId: chatData._id },
      { before: oldestLoadedMessageTS }
    );

    if (res.status === 200) {
      let list = res.data.data.reverse();

      if (before) {
        list = [...list, ...chatList];
      }

      setChatList(list);
      setFilteredChatList(list);

      if (!before) {
        scrollToBottom();
      }
    }
    setLoading(false); // End loading
  };


  useEffect(() => {
    if (unreadOnly) {
      const filtered = chatList.filter(chat => !chat.isReadByMe);  // Filter unread chats
      setFilteredChatList(filtered);
    } else {
      setFilteredChatList(chatList);  // Show all chats
    }
  }, [unreadOnly, chatList]);

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const chatContainerRef = useRef(null);

  useEffect(() => {
    const chatContainer = chatContainerRef.current;

    const handleScroll = () => {
      if (chatContainer.scrollTop === 0 && !loading) {
        console.log("User has scrolled to the top of the chat container");
        messagesList(chatList[0]?.createdAtTimestamp);
        setBeforeTime(chatList[0]?.createdAtTimestamp);
      }
    };

    chatContainer.addEventListener("scroll", handleScroll);

    return () => {
      chatContainer.removeEventListener("scroll", handleScroll);
    };
  }, [chatList, loading]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div className="d-flex align-items-center justify-content-between pt-2 pb-4 mx-3">
        <div className="d-flex">
          <img
            style={{ borderRadius: 70 }}
            height={55}
            width={55}
            className="me-2"
            src={chatData.profilePictureToShow}
            alt=""
          />
          <p className="mb-0 me-3 fs-2 fw-bold">{`${chatData.chatNameToShow}`}</p>
        </div>
        <div className="d-flex">
          <p className="mb-0 me-3 fs-2 fw-bold">{`${user.firstName} ${user.lastName}`}</p>
          <img
            style={{ borderRadius: 70 }}
            height={55}
            width={55}
            className="me-2"
            src={user.profilePicture}
            alt=""
          />
        </div>
      </div>
      <div
        ref={chatContainerRef}
        style={{ height: "80vh", overflow: "auto", paddingBottom: '100px' }}
        className="p-4"
        id="child-component"
      >
        {loading && (
          <div style={{ textAlign: "center", marginBottom: "10px" }}>
            <span>Loading earlier messages...</span>
          </div>
        )}
        {chatList.map((i, e) => {
          return i?.createdBy?._id === supportUserId ? (
            <Sender data={i} key={e} />
          ) : (
            <Receiver data={i} key={e} />
          );
        })}
        <div ref={messagesEndRef} />
      </div>
      <div style={{ position: "absolute", bottom: 0, width: "120%" }} className="row mt-auto">
        <SendMessage scrollToBottom={scrollToBottom} chatId={chatData._id} userId={user._id} />
      </div>
    </DashboardLayout>
  );
};

export default SupportChat;
