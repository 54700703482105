import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import { Typography } from "@mui/material";
import { ClipLoader } from "react-spinners";

import Services from "../../services";

function Dashboard() {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [dashboardData, setDashboardData] = useState();

  const validateToken = () => {
    if (!localStorage.getItem("token")) {
      navigate("/authentication/sign-in", { replace: true });
    }
  };

  useEffect(() => {
    validateToken();
  });

  useEffect(() => {
    get();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      getLiveUsersCount();
    }, 5000);
  }, [dashboardData?.users?.liveUsersCount]);

  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "blue",
  };

  const get = async () => {
    const res = await Services.getDashboardStats();

    if (res.status == 200) {
      const {
        totalUsers,
        activeUsers,
        suspendedUsers,
        deletedUsers,
        paidPremiumUsers,
        freemiumUsers,
        trialPremiumUsers,
      } = res.data.users;

      const newUserObj = {
        liveUsersCount: {
          // real: 0,
          real: "-",
          test: undefined,
        },
        totalUsers,
        activeUsers,
        suspendedUsers,
        deletedUsers,
        paidPremiumUsers,
        freemiumUsers,
        trialPremiumUsers,
      };

      res.data.users = newUserObj;

      setDashboardData(res.data);
    }

    console.log("🚀 ~ file: index.js:37 ~ get ~ res:", res.data);
  };

  const convertToHeading = (input) =>
    input.charAt(0).toUpperCase() + input.slice(1, input.length).replace(/([A-Z])/g, " $1");

  const getLiveUsersCount = async () => {
    try {
      const { data } = await Services.getServerSettingsByKey({ key: "liveUsersCount" });

      setDashboardData((prevState) => {
        const updatedState = { ...prevState };

        if (Object.keys(updatedState).length) {
          updatedState.users.liveUsersCount = { real: data };
        }

        return updatedState;
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        {dashboardData ? (
          <>
            {Object.keys(dashboardData)?.map((value, key) => (
              <>
                {value == "users" ? (
                  <>
                    <Grid key={key} container spacing={3}>
                      {Object.keys(dashboardData[value])?.map((key, i) => (
                        <Grid key={key} item xs={12} md={6} lg={3}>
                          <MDBox mb={1.5}>
                            <ComplexStatisticsCard
                              color={
                                i == 0
                                  ? "primary"
                                  : i == 1
                                  ? "success"
                                  : i == 2
                                  ? undefined
                                  : i == 3
                                  ? "error"
                                  : "dark"
                              }
                              icon={
                                i == 0
                                  ? "store"
                                  : i == 1
                                  ? "add"
                                  : i == 2
                                  ? "leaderboard"
                                  : i == 3
                                  ? "unarchive"
                                  : "weekend"
                              }
                              title={convertToHeading(key)}
                              count={
                                key === "liveUsersCount"
                                  ? dashboardData[value][key].real
                                  : `Real : ${dashboardData[value][key].real}`
                              }
                              testCount={
                                key === "liveUsersCount"
                                  ? dashboardData[value][key].test
                                  : `Test : ${dashboardData[value][key].test}`
                              }
                              paidPremiumAndroidUsers={
                                dashboardData[value][key].paidPremiumRealAndroidUsers
                              }
                              paidPremiumIosUsers={
                                dashboardData[value][key].paidPremiumRealIosUsers
                              }
                            />
                          </MDBox>
                        </Grid>
                      ))}
                    </Grid>
                  </>
                ) : (
                  <>
                    <Typography className="mb-4 fw-bold fs-2">{value.toUpperCase()}</Typography>
                    <Grid container spacing={3}>
                      {Object.keys(dashboardData[value])?.map((key, i) => (
                        <Grid item xs={12} md={6} lg={3}>
                          <MDBox mb={1.5}>
                            <ComplexStatisticsCard
                              color={
                                i == 0
                                  ? "primary"
                                  : i == 1
                                  ? "success"
                                  : i == 2
                                  ? undefined
                                  : i == 3
                                  ? "error"
                                  : "dark"
                              }
                              icon={
                                i == 0
                                  ? "store"
                                  : i == 1
                                  ? "add"
                                  : i == 2
                                  ? "leaderboard"
                                  : i == 3
                                  ? "unarchive"
                                  : "weekend"
                              }
                              title={convertToHeading(key)}
                              count={dashboardData[value][key]}
                              percentage={{}}
                            />
                          </MDBox>
                        </Grid>
                      ))}
                    </Grid>
                    <hr />
                  </>
                )}
              </>
            ))}
          </>
        ) : (
          <div style={{ marginTop: "25vh" }}>
            <ClipLoader
              color={"#574599"}
              loading={true}
              cssOverride={override}
              size={100}
              aria-label="Loading Spinner"
              data-testid="loader"
            ></ClipLoader>
          </div>
        )}
      </MDBox>
      <div className="d-flex align-items-end" style={{ height: "60vh" }}>
        <Footer />
      </div>
    </DashboardLayout>
  );
}

export default Dashboard;
